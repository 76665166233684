<div class="example-container">
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav
      opened
      #snav
      mode="side"
      class="left-sidebar"
      [ngClass]="{ mobileSidenav: mobileQuery.matches, isOpened: isOpened }"
      (keydown.escape)="toggleSidenavSize()"
      disableClose
    >
      <div
        class="sidenav-header"
        [class.is-sidenav-header-loading]="isLoading"
        style="justify-content: center"
      >
        <!-- [ngStyle]="{
          'justify-content': seoOnlyLinks ? 'center' : 'flex-start'
        }" -->
        <ng-container *ngIf="!isLoading">
          <ng-container *ngIf="seoOnlyLinks">
            <img
              src="../../assets/img/organic_icon_2.svg"
              style="width: 26px; height: 26px; border-radius: 6px"
              class="sidenav-main-image"
            />
          </ng-container>
          <ng-container *ngIf="!seoOnlyLinks">
            <span
              class="sidenav-header-black_square"
              *ngIf="!mobileQuery.matches || isOpened"
            ></span>
            <img
              src="../../assets/img/login_logo_v2.svg"
              style="width: 22px; height: 22px; border-radius: 6px"
              class="sidenav-main-image"
            />
          </ng-container>
          <h3>{{ seoOnlyLinks ? "Organic" : "Behamics" }}</h3>
        </ng-container>
      </div>
      <div class="sidenav-header_hr"></div>
      <!-- <div class="sidenav-header" *ngIf="isSmall">
        <img
          class="sidenav-small-img"
          src="../../assets/img/favicon.png"
          alt=""
        />
      </div>
      <div *ngIf="isSmall" class="sidenav-header_hr"></div> -->

      <div class="master-admin-nav" style="display: flex; align-items: stretch">
        <mat-nav-list
          style="padding: 0; flex: 1; min-height: 300px; overflow: auto"
        >
          <ng-container *ngIf="!seoOnlyLinks">
            <ng-container *ngFor="let item of navigationLinksFiltered">
              <a
                mat-list-item
                routerLinkActive="active-route"
                [routerLink]="item.routerLink"
                (click)="navigationLinksCallback(item.title)"
                class="nav-item"
                [ngClass]="{ disabled: item?.disabled }"
              >
                <div class="nav-item-icon_container">
                  <img
                    [src]="item?.icon?.src"
                    [style]="item?.icon?.inlineStyle"
                  />
                </div>
                <span>
                  {{
                    this.getTranslatedText(item.title, "sidenav_links") | async
                  }}
                </span>
              </a>
              <ng-container *ngIf="item?.children.length > 0">
                <div
                  class="sub_navmenu"
                  [ngClass]="{
                    'sub-menu-active':
                      (item?.active && !mobileQuery.matches) ||
                      (item?.active && mobileQuery.matches && isOpened)
                  }"
                >
                  <mat-nav-list>
                    <a
                      *ngFor="let child of item.children"
                      mat-list-item
                      routerLinkActive="active-route"
                      [class.disabled]="seoLinkLoadingChecker(item, child)"
                      [routerLink]="
                        !seoLinkLoadingChecker(item, child)
                          ? child.routerLink
                          : null
                      "
                    >
                      <span>
                        {{
                          this.getTranslatedText(child.title, "sidenav_links")
                            | async
                        }}
                      </span>
                    </a>
                  </mat-nav-list>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="seoOnlyLinks">
            <ng-container *ngFor="let item of navigationLinksFiltered">
              <a
                mat-list-item
                routerLinkActive="active-route"
                class="nav-item"
                [ngClass]="{
                  disabled: seoLinkLoadingChecker(item, null)
                }"
                [routerLink]="
                  !seoLinkLoadingChecker(item, null) ? item.routerLink : null
                "
              >
                <div style="display: flex; margin-right: 10px">
                  <ng-container *ngIf="item.materialIcon">
                    <mat-icon
                      class="list-icon material-icons-outlined"
                      style="font-size: 24px; height: 24px; width: 24px"
                    >
                      {{ item.materialIcon }}
                    </mat-icon>
                  </ng-container>
                  <ng-container *ngIf="item.svgIcon">
                    <ng-container *ngIf="item.svgIcon == 'link_svg_icon'">
                      <svg
                        viewBox="0 0 640 512"
                        style="
                          min-width: 18px;
                          width: 18px;
                          margin-left: 2px;
                          margin-right: 3px;
                          fill: var(--navmenu-icon-svg-color);
                        "
                      >
                        <path
                          d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"
                        />
                      </svg>
                    </ng-container>
                  </ng-container>
                </div>
                <span>
                  {{
                    this.getTranslatedText(item.title, "sidenav_links") | async
                  }}
                </span>
              </a>
              <div
                class="sidenav-header_hr sidenav-header_hr-my-20"
                *ngIf="item.title == 'Keywords'"
              ></div>
            </ng-container>
          </ng-container>

          <div
            class="ga4-status-menu"
            *ngIf="gaStatusLoaded && checkRoleGuard('googleAnalyticsOption')"
          >
            <p>Settings</p>
            <mat-nav-list>
              <a
                style="font-size: 12px !important"
                mat-list-item
                class="nav-item active-route"
                matTooltip="Connect to Google Analytics to receive the Behamics Events such as visits, orders and the group as seen here in the dashboard. This way the data can be validated between the dashboard and the Google Analytics."
                (click)="changeGa4Status()"
              >
                <div class="nav-item-icon_container">
                  <img src="./../../assets/img/ga4.svg" />
                </div>
                <span *ngIf="!gaStatus">Connect to Google Analytics </span>
                <span *ngIf="gaStatus">Disconnect Google Analytics </span>
              </a>
            </mat-nav-list>
          </div>
        </mat-nav-list>
        <mat-nav-list class="sidenav_links_secondary">
          <div class="sidenav-header_hr"></div>
          <a
            *ngIf="checkRoleGuard('backendMetrics')"
            mat-list-item
            routerLinkActive="active-route"
            routerLink="/metrics/backend"
            (click)="closeParentTrigger()"
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon">query_stats</mat-icon>
            </div>
            <span>Backend Metrics</span>
          </a>
          <a
            mat-list-item
            class="parent-incentive"
            *ngIf="
              checkRoleGuard('modelAutomationModels') ||
              checkRoleGuard('modelAutomationLibrary')
            "
            (click)="parentModelsClick()"
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon">settings</mat-icon>
            </div>
            <span>Model Automation</span>
          </a>
          <div
            class="sub_navmenu"
            [ngClass]="{ 'sub-menu-active': subTriggerModelAutomation }"
          >
            <mat-nav-list>
              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/model-automation/models"
                *ngIf="checkRoleGuard('modelAutomationModels')"
              >
                <mat-icon class="list-icon">list_alt</mat-icon>
                <span>Models</span>
              </a>

              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/model-automation/library"
                *ngIf="checkRoleGuard('modelAutomationLibrary')"
              >
                <mat-icon class="list-icon">settings_suggest</mat-icon>
                <span>Library</span>
              </a>
            </mat-nav-list>
          </div>
          <a
            mat-list-item
            class="parent-incentive"
            (click)="parentClientClick()"
            *ngIf="
              checkRoleGuard('masterInvoices') ||
              checkRoleGuard('masterInvoicesConfiguration')
            "
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon material-icons-outlined">
                description
              </mat-icon>
            </div>
            <span>Invoices</span>
          </a>
          <div
            class="sub_navmenu"
            [ngClass]="{ 'sub-menu-active': subTriggerInvoices }"
          >
            <mat-nav-list>
              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/invoices/list"
                *ngIf="checkRoleGuard('masterInvoices')"
              >
                <mat-icon class="list-icon">list_alt</mat-icon>
                <span>List of Invoices</span>
              </a>

              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/invoices/configuration"
                *ngIf="checkRoleGuard('masterInvoicesConfiguration')"
              >
                <mat-icon class="list-icon">settings_suggest</mat-icon>
                <span>Configuration</span>
              </a>
            </mat-nav-list>
          </div>
          <!-- <a
            mat-list-item
            class="parent-incentive"
            (click)="parentLeadsClick()"
            *ngIf="checkRoleGuard('leads')"
          >
            <mat-icon
              class="list-icon submenu-tr-icon submenu-tr-right"
              [ngClass]="{ 'icon-tr-hide': !subTriggerLeads }"
            >
              arrow_right</mat-icon
            >
            <mat-icon
              class="list-icon submenu-tr-icon submenu-tr-down"
              [ngClass]="{ 'icon-tr-hide': subTriggerLeads }"
            >
              arrow_drop_down</mat-icon
            >
            <mat-icon class="list-icon material-icons-outlined"
              >supervised_user_circle</mat-icon
            >
            <span>Leads</span>
          </a>

          <div
            class="sub_navmenu"
            [ngClass]="{ 'sub-menu-active': subTriggerLeads }"
          >
            <mat-nav-list>
              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/master-leads"
                *ngIf="checkRoleGuard('leads')"
              >
                <mat-icon class="list-icon">supervised_user_circle</mat-icon>
                <span>Leads</span>
              </a>

              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/master-campaigns"
                *ngIf="checkRoleGuard('leads')"
              >
                <mat-icon class="list-icon">settings_suggest</mat-icon>
                <span>Campaigns</span>
              </a>
              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/master-opportunities"
                *ngIf="checkRoleGuard('leads')"
              >
                <mat-icon class="list-icon">how_to_reg</mat-icon>
                <span>Opportunities</span>
              </a>
              <a
                mat-list-item
                routerLinkActive="active-route"
                routerLink="/master-leads-overview"
                *ngIf="checkRoleGuard('leads')"
              >
                <mat-icon class="list-icon">pageview</mat-icon>
                <span>Overview</span>
              </a>
            </mat-nav-list>
          </div> -->

          <a
            mat-list-item
            routerLinkActive="active-route"
            routerLink="/seo/billing"
            *ngIf="checkRoleGuard('seo_billing')"
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon">credit_card</mat-icon>
            </div>
            <span>Billing</span>
          </a>
          <a
            mat-list-item
            routerLinkActive="active-route"
            routerLink="/user-management"
            *ngIf="checkRoleGuard('masterUserManagement')"
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon">person</mat-icon>
            </div>
            <span>User Management</span>
          </a>

          <a
            mat-list-item
            routerLinkActive="active-route"
            routerLink="/user-permissions"
            *ngIf="checkRoleGuard('userPermissions')"
          >
            <div class="nav-item-icon_container">
              <mat-icon class="list-icon">person</mat-icon>
            </div>
            <span>
              {{
                this.getTranslatedText("Invite Users", "sidenav_links") | async
              }}
            </span>
          </a>
        </mat-nav-list>

        <button
          mat-mini-fab
          style="color: #3a405b"
          id="side-trigger"
          (click)="toggleSidenavSize()"
          *ngIf="mobileQuery.matches"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div
        class="custom_sidenav_background"
        (click)="toggleSidenavSize()"
      ></div>
      <div class="mat-sidenav-content_container">
        <mat-toolbar class="toolbar">
          <div
            class="date-controller-container"
            [ngClass]="{
              'controller-aligner':
                router.url == '/user-permissions' ||
                router.url == '/user-management' ||
                router.url == '/model-automation/library',
              'compare-active': compareActive
            }"
            [ngStyle]="{
              padding: seoOnly ? '0px' : '0px 12px'
            }"
            *ngIf="
              router.url !== '/statistics-configurations' &&
              router.url !== '/predictions/returnrate' &&
              router.url !== '/atc-alert-system' &&
              router.url !== '/configuration' &&
              router.url !== '/nudges/preview' &&
              router.url !== '/invoices/list' &&
              router.url !== '/invoices/configuration' &&
              !router.url.includes('/model-automation/models') &&
              router.url !== '/master-ai-experiments' &&
              router.url !== '/client-create-variant' &&
              router.url !== '/script-automation/configuration' &&
              router.url !== '/script-automation/debug' &&
              router.url !== '/management/bug-tracking' &&
              !router.url.includes('/recording/')
            "
          >
            <section class="configurator-theme-mode">
              <!-- <div
                *ngIf="
                  router.url.includes('/seo/') &&
                  !router.url.includes('/seo/billing')
                "
                style="z-index: 0"
                class="search-console-button-container"
              >
                <button
                  class="btn_withoutIcon custom-button search-console-button"
                  mat-raised-button
                  type="button"
                  (click)="redirectToIntegrations()"
                  *ngIf="!router.url.includes('/seo/integrations')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                  <span> Connect Search Console </span>
                </button>
              </div> -->

              <p>
                {{
                  router.url.includes("/seo/") ||
                  router.url.includes("/user-permissions")
                    ? ("words.dark_mode" | translate)
                    : "Dark Mode"
                }}
              </p>
              <mat-slide-toggle
                class="example-margin"
                [checked]="this.darkMode"
                (change)="switchThemeMode($event)"
              >
              </mat-slide-toggle>

              <mat-form-field
                class="bhm-option-container-1"
                [ngStyle]="{
                  '--form-field-infix-width': '220px',
                  '--label-name': '\'Timezone:\''
                }"
                *ngIf="!hideTimezoneSelection && !router.url.includes('/seo/')"
              >
                <mat-select
                  [(ngModel)]="selectedTimezone"
                  (selectionChange)="timezoneChanged()"
                >
                  <mat-select-trigger>
                    {{ selectedTimezone }}
                  </mat-select-trigger>
                  <mat-option
                    class="bhm-option-select-option-1 bhm-option-select-option-height-40"
                    *ngFor="let timezone of timezoneList"
                    [value]="timezone.name"
                  >
                    {{ timezone.name }}
                    <ng-container *ngIf="timezone.local_timezone"
                      >- Local
                    </ng-container>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div
                *ngIf="
                  router.url.includes('/seo/') &&
                  !router.url.includes('/seo/billing')
                "
                style="z-index: 0"
                class="product-tour-button-container"
              >
                <button
                  class="btn_withoutIcon custom-button product-tour-button"
                  mat-raised-button
                  type="button"
                  (click)="triggerProductTour()"
                  [disabled]="
                    (productTourLoading || seoStillLoadingBlock) &&
                    !noProjectSelected
                  "
                >
                  <span> {{ "words.product_tour" | translate }} </span>
                </button>
              </div>

              <mat-form-field
                *ngIf="
                  !router.url.includes('seo/billing') &&
                  (router.url.includes('/seo/') ||
                    router.url.includes('/user-permissions'))
                "
                class="bhm-option-container-1"
                [ngStyle]="{
                  '--form-field-infix-width': '140px',
                  '--label-name': '\'' + ('words.language' | translate) + ':\''
                }"
              >
                <mat-select
                  [(ngModel)]="savedLang"
                  (selectionChange)="changeLanguage($event.value)"
                >
                  <mat-option class="bhm-option-select-option-1" value="en">
                    English
                  </mat-option>
                  <mat-option class="bhm-option-select-option-1" value="de">
                    German
                  </mat-option>
                  <mat-option class="bhm-option-select-option-1" value="ja">
                    Japanese
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <div
              class="dtholder range-sel sel1"
              *ngIf="
                router.url !== '/user-permissions' &&
                router.url !== '/user-management' &&
                router.url !== '/nudges/preview' &&
                router.url !== '/configuration' &&
                !router.url.includes('/model-automation/') &&
                router.url !== '/invoices/list' &&
                router.url !== '/invoices/configuration' &&
                router.url !== '/client-create-variant' &&
                router.url !== '/metrics/backend' &&
                !router.url.includes('/seo/')
              "
            >
              <mat-form-field class="form-field first-picker">
                <p>Range:</p>
                <mat-select
                  [(ngModel)]="selectedRangeValue"
                  name="range"
                  (selectionChange)="rangeChanged($event)"
                >
                  <mat-option
                    *ngFor="let range of ranges"
                    [value]="range.value"
                  >
                    {{ range.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <span class="range-sel-border"></span>
            <div
              class="dtholder range-sel sel2"
              *ngIf="
                router.url !== '/user-permissions' &&
                router.url !== '/user-management' &&
                router.url !== '/nudges/preview' &&
                router.url !== '/configuration' &&
                !router.url.includes('/model-automation/') &&
                router.url !== '/invoices/list' &&
                router.url !== '/invoices/configuration' &&
                router.url !== '/client-create-variant' &&
                router.url !== '/metrics/backend' &&
                !router.url.includes('/seo/')
              "
            >
              <mat-form-field class="form-field">
                <p><span>Start Date:</span></p>
                <input
                  matInput
                  [matDatepicker]="startDatePicker"
                  (dateChange)="getStartDate($event)"
                  [(ngModel)]="startDate"
                  [max]="maxDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <span class="range-sel-border"></span>
            <div
              class="dtholder range-sel sel3"
              *ngIf="
                router.url !== '/user-permissions' &&
                router.url !== '/user-management' &&
                router.url !== '/nudges/preview' &&
                router.url !== '/configuration' &&
                !router.url.includes('/model-automation/') &&
                router.url !== '/invoices/list' &&
                router.url !== '/invoices/configuration' &&
                router.url !== '/client-create-variant' &&
                router.url !== '/metrics/backend' &&
                !router.url.includes('/seo/')
              "
            >
              <mat-form-field class="form-field sidenav-picker">
                <p><span>End Date:</span></p>
                <input
                  matInput
                  (dateChange)="getEndDate($event)"
                  [matDatepicker]="endDatePicker"
                  [(ngModel)]="endDate"
                  [max]="endMaxDate"
                  [min]="minDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <span class="range-sel-border"></span>
            <!-- Master Admin -->
            <div
              class="dtholder master-selector range-sel sel4"
              *ngIf="
                currentUserRole == 'master' &&
                router.url !== '/statistics-configurations' &&
                router.url !== '/predictions/returnrate' &&
                router.url !== '/invoices/list' &&
                router.url !== '/model-automation/models' &&
                router.url !== '/master-experiment-view' &&
                router.url !== '/master-new-experiment' &&
                router.url !== '/detailed/trace-error' &&
                router.url !== '/client-view-experiment' &&
                router.url !== '/client-create-variant' &&
                router.url !== '/metrics/backend' &&
                router.url !== '/client-results/overall'
              "
            >
              <!-- <mat-form-field class="client_selector">
                <p>Client:</p>
                <mat-select
                  [(value)]="this.selectedStore"
                  (selectionChange)="targetChange()"
                  id="targetSelector"
                >
                  <mat-option
                    *ngFor="let liveClient of liveClientList"
                    [value]="liveClient.description"
                    class="target-selector"
                  >
                    {{ liveClient.description }}
                  </mat-option>
                  <mat-option
                    *ngFor="let notLiveClient of notLiveClientList"
                    [value]="notLiveClient.description"
                    class="target-selector notlive-target"
                  >
                    {{ notLiveClient.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="client_selector">
                <p>Client:</p>
                <mat-select
                  [(value)]="selectedStore"
                  (selectionChange)="onSelectionChange($event)"
                  id="targetSelector"
                  #clientSelect="matSelect"
                >
                  <!-- Live clients -->
                  <mat-option
                    *ngFor="let liveClient of liveClientList"
                    [value]="liveClient.description"
                  >
                    {{ liveClient.description }}
                  </mat-option>

                  <!-- Non-Live Data header -->
                  <div (click)="toggleNonLive()">
                    <div class="nonLive">
                      <p class="non-live-header">Offline Targets</p>
                      <mat-icon
                        class="expand-icon"
                        *ngIf="!showNonLive"
                        fontIcon="arrow_drop_down"
                      ></mat-icon>
                      <mat-icon
                        class="expand-icon"
                        *ngIf="showNonLive"
                        fontIcon="arrow_drop_up"
                      ></mat-icon>
                    </div>
                  </div>

                  <!-- Expandable non-live items -->
                  <ng-container>
                    <mat-option
                      [ngClass]="
                        showNonLive
                          ? 'target-selector notlive-target'
                          : 'hiddeNonLive'
                      "
                      *ngFor="let notLiveClient of notLiveClientList"
                      [value]="notLiveClient.description"
                    >
                      {{ notLiveClient.description }}
                    </mat-option>
                  </ng-container>

                  <div
                    class="sidenav-search-input"
                    *ngIf="clientList?.length > 7"
                  >
                    <input
                      placeholder="Search client"
                      (keyup)="searchTargets($event)"
                    />
                  </div>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Other Combined Users -->
            <div
              class="dtholder master-selector range-sel sel4"
              *ngIf="
                currentUserRole == 'sub-master' &&
                router.url !== '/statistics-configurations' &&
                router.url !== '/predictions/returnrate' &&
                router.url !== '/atc-alert-system' &&
                router.url !== '/detailed/trace-error' &&
                router.url !== '/client-results/overall' &&
                clientList.length > 1
              "
            >
              <mat-form-field class="client_selector">
                <mat-select
                  [(value)]="selectedStore"
                  (selectionChange)="targetChange()"
                  id="targetSelector"
                  placeholder="Store:"
                >
                  <mat-option
                    *ngFor="let client of clientList"
                    [value]="client.description"
                    class="target-selector"
                  >
                    {{ client.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <button
            class="user-button"
            style="position: absolute; right: 20px; top: 21px"
            mat-icon-button
            [matMenuTriggerFor]="menu"
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>account_circle</mat-icon>
            </div>
          </button>
        </mat-toolbar>

        <div class="sidenav-main_container">
          <div
            class="sidenav-tabs_container"
            [ngClass]="{
              'sidenav-tabs_container-no_tabs': !showNavWithTabs,
              has_own_tabs: hasOwnTabs
            }"
          >
            <div class="sidenav-tabs_container-title-container">
              <h3>{{ header | async }}</h3>
              <div
                class="seo-automations_script_status"
                *ngIf="router.url == '/seo/automations'"
                style="margin-left: auto; margin-right: 20px"
                (click)="redirectToScripts()"
              ></div>
              <div
                class="sidenav-seo-tabs-container"
                style="display: flex; align-items: center; gap: 5px"
              >
                <div
                  class="dtpickerall"
                  style="display: flex; align-items: center; gap: 5px"
                  *ngIf="
                    router.url.includes('/seo/ai-analysis') &&
                    !router.url.includes('/seo/ai-analysis/issue')
                  "
                >
                  <div
                    class="custom_label_box"
                    *ngIf="!seoStillLoadingBlock && allKeywords?.total_keywords"
                  >
                    <p
                      routerLink="/seo/keywords"
                      style="cursor: pointer; text-transform: lowercase"
                    >
                      {{ formatComma(allKeywords?.total_keywords) }}
                      {{ "words.keywords" | translate }}
                    </p>
                  </div>
                  <div
                    class="custom_label_box"
                    *ngIf="!seoStillLoadingBlock && selectedKeyWordData?.date"
                  >
                    <p [matTooltip]="selectedKeyWordData?.date">
                      {{ "words.analysis_date" | translate }}:
                      {{
                        selectedKeyWordData?.date | date : "EEE, dd MMM yyyy"
                      }}
                    </p>
                  </div>

                  <mat-form-field
                    class="bhm-option-container-1"
                    [ngStyle]="{
                      '--form-field-infix-width': '180px',
                      '--label-name':
                        '\'' + ('words.competitor' | translate) + ':\''
                    }"
                    *ngIf="
                      !seoStillLoadingBlock &&
                      this.seoSubscription == 'Premium' &&
                      keyWordData?.length > 0
                    "
                  >
                    <mat-select
                      [(ngModel)]="selectedKeyWordData"
                      (selectionChange)="
                        onSelectKeywordData(selectedKeyWordData)
                      "
                    >
                      <mat-option
                        class="bhm-option-select-option-1"
                        *ngFor="let data of keyWordData"
                        [value]="data"
                      >
                        {{ data.domain }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  class="tab_option_picker"
                  *ngIf="
                    router.url.includes('/seo/') &&
                    !router.url.includes('/seo/onboarding') &&
                    !router.url.includes('/seo/billing') &&
                    !router.url.includes('/seo/projects') &&
                    !router.url.includes('/seo/ai-analysis/issue')
                  "
                >
                  <mat-form-field
                    class="bhm-option-container-1"
                    [ngStyle]="{
                      '--form-field-infix-width': '180px',
                      '--label-name':
                        '\'' + ('words.project' | translate) + ':\''
                    }"
                  >
                    <mat-select
                      [(ngModel)]="selectedSeoProjectURL"
                      (selectionChange)="seoProjectChanged()"
                    >
                      <mat-option
                        class="bhm-option-select-option-1 bhm-option-select-option-height-40"
                        *ngFor="let project of seoProjectsFiltered"
                        [value]="project.url"
                      >
                        {{ project.domain ? project.domain : project.url }}
                      </mat-option>
                      <div
                        class="sidenav-search-input"
                        *ngIf="seoProjects && seoProjects?.length > 7"
                      >
                        <input
                          placeholder="{{
                            'words.search_projects' | translate
                          }}"
                          (keyup)="searchProjects($event)"
                        />
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <!-- <div class="custom_option_picker">
                   
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu
  class="user-menu logout-menu"
  x-position="before"
  y-position="below"
  #menu="matMenu"
>
  <button (click)="logout()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
