// here we will add all the navigation links that exists and we will need an extra flag
// 'hideFromView: true' to check if we need to display on sidenav
// depeding on the privilege

export const navigationLinks = [
  {
    title: 'Dashboard',
    icon: {
      src: '../../assets/img/dashboard_icon.svg',
      inlineStyle: 'width:22px;height:22px;position:relative;left:-2px',
    },
    active: false,
    routerLink: '/dashboard',
    privilege: 'masterDashboard',
    children: [],
    hideIfNoPrivileges: true,
    // callback: () => {
    //   this.closeParentTrigger();
    // },
  },
  {
    title: 'Nudge',
    icon: {
      src: '../../assets/img/nudge_icon.svg',
      inlineStyle: 'width:18px;height:18px',
    },
    active: false,
    routerLink: '/master-preview',
    privilege: 'masterPreview',
    children: [],
    // callback: () => {
    //   this.closeParentTrigger();
    // },
  },
  {
    title: 'Diagnostic',
    icon: {
      src: '../../assets/img/diagnostic_icon.svg',
      inlineStyle: 'width:18px;height:18px',
    },
    active: false,
    routerLink: '/diagnostic',
    privilege: 'bugTrackingOverall',
    children: [],
    // callback: () => {
    //   this.closeParentTrigger();
    // },
  },
  {
    title: 'Recording',
    icon: {
      src: '../../assets/img/recorder_icon.svg',
      inlineStyle: 'width:20px;height:20px',
    },
    active: false,
    routerLink: '/recording',
    privilege: 'recording_overall',
    children: [],
    // callback: () => {
    //   this.closeParentTrigger();
    // },
  },
  {
    title: 'Organic',
    icon: {
      src: '../../assets/img/organic_icon.svg',
      inlineStyle: 'width:19px;height:19px',
    },
    active: false,
    privilege: '',
    children: [
      {
        title: 'Getting Started',
        routerLink: '/seo/getting-started',
        privilege: 'seo_getting_started',
        materialIcon: 'dashboard',
      },
      {
        title: 'AI Audit',
        routerLink: '/seo/ai-analysis',
        privilege: 'seo_ai-analysis',
        materialIcon: 'query_stats',
        disable_on_zero_projects: true,
      },
      {
        title: 'AI Audit Issue',
        routerLink: '/seo/ai-analysis/issue',
        privilege: 'seo_ai-analysis',
        materialIcon: 'query_stats',
        disable_on_zero_projects: true,
        hideFromView: true,
      },
      {
        title: 'Content',
        routerLink: '/seo/content',
        privilege: 'seo_content',
        materialIcon: 'text_snippet',
        disable_on_zero_projects: true,
      },
      {
        title: 'Rank Tracker',
        routerLink: '/seo/positions',
        privilege: 'seo_position-tracking',
        materialIcon: 'show_chart',
        disable_on_zero_projects: true,
      },
      {
        title: 'Backlink Prediction',
        routerLink: '/seo/backlink-prediction',
        privilege: 'seo_backlinks',
        svgIcon: 'link_svg_icon',
        disable_on_zero_projects: true,
      },
      // {
      //   title: 'Backlinks',
      //   routerLink: '/seo/backlinks',
      //   privilege: 'seo_backlinks',
      //   svgIcon: 'link_svg_icon',
      //   disable_on_zero_projects: true,
      // },
      {
        title: 'Keywords',
        routerLink: '/seo/keywords',
        privilege: 'seo_projects',
        materialIcon: 'leaderboard',
        disable_on_zero_projects: true,
      },
      // {
      //   title: 'Relevance',
      //   routerLink: '/seo/relevance',
      //   privilege: 'seo_backlinks_measure-relevancy',
      // },
      // {
      //   title: 'Automations',
      //   routerLink: '/seo/automations',
      //   privilege: 'seo_automations',
      // },
      // {
      //   title: 'Scripts',
      //   routerLink: '/seo/scripts',
      //   privilege: 'seo_scripts',
      // },
      // {
      //   title: 'Technicals',
      //   routerLink: '/seo/technicals',
      //   privilege: 'seo_technicals',
      // },
      // {
      //   title: 'On Page',
      //   routerLink: '/seo/on-page',
      //   privilege: 'seo_on-page',
      // },
      {
        title: 'Pages',
        routerLink: '/seo/pages',
        privilege: 'seo_pages',
        hideFromView: true,
        disable_on_zero_projects: true,
      },
      {
        title: 'Competitors',
        routerLink: '/seo/competitors',
        privilege: 'seo_projects',
        materialIcon: 'groups',
      },
      {
        title: 'Projects',
        routerLink: '/seo/projects',
        privilege: 'seo_projects',
        materialIcon: 'format_list_bulleted',
      },
      // {
      //   title: 'Integrations',
      //   routerLink: '/seo/integrations',
      //   privilege: 'seo_projects',
      //   materialIcon: 'integration_instructions',
      // },
    ],
    // callback: () => {
    //   this.parentSEOClick();
    // },
  },
  {
    title: 'Merchandise',
    icon: {
      src: '../../assets/img/merchandising_icon.svg',
      inlineStyle: 'width:16px;height:16px;margin-left:2px',
    },
    active: false,
    routerLink: '/merchandising',
    privilege: 'merchandising',
    children: [],
    // callback: () => {
    //   this.closeParentTrigger();
    // },
  },
  {
    title: 'Automations',
    icon: {
      src: '../../assets/img/automations_icon_1.svg',
      inlineStyle: 'width:20px;height:16px',
    },
    active: false,
    routerLink: '/automations',
    privilege: 'automationsOverview',
    children: [],
  },
  // {
  //   title: 'Copilot Ads',
  //   icon: {
  //     src: '../../assets/img/automations_icon_1.svg',
  //     inlineStyle: 'width:20px;height:16px',
  //   },
  //   active: false,
  //   routerLink: '/ads/getting-started',
  //   privilege: 'copilot_ads',
  //   children: [],
  // },
  // {
  //   title: 'Ads Setup',
  //   icon: {
  //     src: '',
  //     inlineStyle: '',
  //   },
  //   active: false,
  //   routerLink: '/ads-setup',
  //   privilege: 'copilot_ads',
  //   children: [],
  //   hideFromView: true,
  // },
  {
    title: 'Organic Billing',
    icon: {
      src: '',
      inlineStyle: '',
    },
    active: false,
    routerLink: '/seo/billing',
    privilege: 'seo_billing',
    children: [],
    hideFromView: true,
  },
  {
    title: 'Trace Error',
    icon: {
      src: '',
      inlineStyle: '',
    },
    active: false,
    routerLink: '/diagnostic/trace-error',
    privilege: 'traceError',
    children: [],
    hideFromView: true,
  },
  {
    title: 'User Permissions',
    icon: {
      src: '',
      inlineStyle: '',
    },
    active: false,
    routerLink: '/user-permissions',
    privilege: 'userPermissions',
    children: [],
    hideFromView: true,
  },
  {
    title: 'User Management',
    icon: {
      src: '',
      inlineStyle: '',
    },
    active: false,
    routerLink: '/user-management',
    privilege: 'masterUserManagement',
    children: [],
    hideFromView: true,
  },
  // {
  //   title: 'Maintenance',
  //   icon: {
  //     src: '',
  //     inlineStyle: '',
  //   },
  //   active: false,
  //   routerLink: '/organic-maintenance',
  //   privilege: '',
  //   children: [],
  //   hideFromView: true,
  // },
];
