import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  constructor(private http: HttpClient) {}

  getBarChartCr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/cr' + params);
  }
  getBarChartAtc(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/atc' + params);
  }
  getBarChartRpv(data: any): Observable<any> {
    if (data.noOutliers) {
      if (data.moreThanThreeMonths) {
        var params =
          '?startDate=' +
          data.startDate +
          '&endDate=' +
          data.endDate +
          '&target=' +
          data.target +
          '&device=' +
          data.device +
          '&visitType=' +
          data.visitType +
          '&noOutliers=' +
          data.noOutliers +
          '&moreThanThreeMonths=' +
          data.moreThanThreeMonths +
          '&sequences=' +
          data.sequences;
      } else {
        var params =
          '?startDate=' +
          data.startDate +
          '&endDate=' +
          data.endDate +
          '&target=' +
          data.target +
          '&device=' +
          data.device +
          '&visitType=' +
          data.visitType +
          '&noOutliers=' +
          data.noOutliers;
      }
    } else {
      if (data.moreThanThreeMonths) {
        var params =
          '?startDate=' +
          data.startDate +
          '&endDate=' +
          data.endDate +
          '&target=' +
          data.target +
          '&device=' +
          data.device +
          '&visitType=' +
          data.visitType +
          '&moreThanThreeMonths=' +
          data.moreThanThreeMonths +
          '&sequences=' +
          data.sequences;
      } else {
        var params =
          '?startDate=' +
          data.startDate +
          '&endDate=' +
          data.endDate +
          '&target=' +
          data.target +
          '&device=' +
          data.device +
          '&visitType=' +
          data.visitType;
      }
    }
    return this.http.get<any>('/rpv' + params);
  }
  getBarChartRr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/rr' + params);
  }
  getBarChartAov(data: any): Observable<any> {
    if (data.noOutliers) {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType +
        '&noOutliers=' +
        data.noOutliers;
    } else {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType;
    }
    return this.http.get<any>('/aov' + params);
  }
  getBarChartNsv(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/nr' + params);
  }
  // Detailed
  getBarChartDetailedCr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&target=' +
      data.target;
    return this.http.get<any>('/detailedcr' + params);
  }
  getBarChartDetailedRpv(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&target=' +
      data.target;
    return this.http.get<any>('/detailedrpv' + params);
  }
  getBarChartDetailedAov(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&target=' +
      data.target;
    return this.http.get<any>('/detailedaov' + params);
  }
  getBarChartDetailedRr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&target=' +
      data.target;
    return this.http.get<any>('/detailedrr' + params);
  }

  getBarChartDetailedNrv(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&target=' +
      data.target;
    return this.http.get<any>('/detailednr' + params);
  }

  getAllIncentivesTarget(target: any): Observable<any[]> {
    if (typeof target == 'undefined') {
      var params = '';
    } else {
      var params = '?target=' + target;
    }
    return this.http.get<any[]>('/variants' + params);
  }

  downloadOverallReport(data: any) {
    if (data.noOutliers) {
      var params =
        '?target=' +
        data.target +
        '&startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType +
        '&noOutliers=' +
        data.noOutliers +
        '&reportType=' +
        data.reportType;
    } else {
      var params =
        '?target=' +
        data.target +
        '&startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType +
        '&reportType=' +
        data.reportType;
    }

    return this.http.get('/downloadOverallReport' + params, {
      responseType: 'blob',
      observe: 'response',
    });
    //arraybuffer
  }

  downloadDetailedReport(data: any): Observable<any> {
    var params =
      '?target=' +
      data.target +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&incentiveSector=' +
      data.incentiveSector +
      '&siteID=' +
      data.siteID +
      '&ruleID=' +
      data.ruleID +
      '&incentiveTypes=' +
      data.incentiveTypes +
      '&incentives=' +
      data.incentives +
      '&reportType=' +
      data.reportType;
    return this.http.get('/downloadDetailedReport' + params, {
      responseType: 'blob',
      observe: 'response',
    });
    //arraybuffer
  }

  getIncentivePerformance(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target;
    return this.http.get<any>('/allIncentivePerformance' + params);
  }

  getPerformanceOverview(data: any): Observable<any> {
    var siteID = '';
    if (data.siteID != 'Show All') {
      siteID = data.siteID;
    }

    var active = '';
    if (data.active) {
      active = 'active';
    } else {
      active = 'unactive';
    }

    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&device=' +
      data.device +
      '&target=' +
      data.target +
      '&siteID=' +
      siteID +
      '&active=' +
      active;

    return this.http.get<any>('/performanceOverview' + params);
  }

  downloadRawData(params: any): Observable<any> {
    return this.http.get('/downloadRawData?' + params);
  }

  downloadRawDataDetailed(params: any): Observable<any> {
    return this.http.get(`/performanceOverview/download?${params}`);
  }

  getVolvoTestDrives(data: any): Observable<any> {
    var params =
      '?target=' +
      data.target +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate;

    return this.http.get<any>('/testDriveCr' + params);
  }
}
