import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { TimezoneService } from '../services/timezone.service';

@Injectable({
  providedIn: 'root',
})
export class DiagnosticsService {
  constructor(
    private _http: HttpClient,
    private timezoneService: TimezoneService
  ) {}

  isMasterDashboard() {
    if (
      localStorage.getItem('role') == 'master' ||
      localStorage.getItem('role') == 'schulte' ||
      localStorage.getItem('role') == 'ochsnersport' ||
      localStorage.getItem('master') == 'true'
    ) {
      return true;
    }
    return false;
  }

  behamicsSample: boolean = false;
  getTargetFromLocalStorage() {
    let target = localStorage.getItem('selectedTarget');
    // if(this.isMasterDashboard()) {
    //   target = window.localStorage.getItem('selectedTarget');
    // } else {
    //   target = window.localStorage.getItem('target');
    // }

    if (target == 'behamicsSample') {
      target = 'gonser';
      this.behamicsSample = true;
    } else {
      this.behamicsSample = false;
    }

    return target;
  }

  getInfoBugData(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}`;

    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/?${params}`);
  }

  bugDetails(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = '';

    if (data?.endDate) {
      params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}`;

      if (this.behamicsSample) {
        params += '&behamicsSample=true';
      }

      return this._http.get(`/bugs/bugDetails?${params}`);
    } else {
      params = `target=${target}&startDate=${data.startDate}&rate=${data.rate}`;

      if (this.behamicsSample) {
        params += '&behamicsSample=true';
      }

      return this._http.get(`/bugs/bugDetails?${params}`);
    }
  }

  getTimeRangeBugData(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = '';
    if (data.page && data.page != 'Show_All') {
      params = '&page=' + data.page;
    }
    if (data.category && data.category != 'Show_All') {
      params = params + '&bug_category=' + data.category;
    }
    if (
      data.device &&
      data.device != 'overall' &&
      ['desktop', 'mobile', 'tablet'].includes(data.device)
    ) {
      params += '&device=' + data.device;
    }

    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(
      `/bugs/revenue-loss/?target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}` +
        params
    );
  }

  getBugFrequency(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}&bug=${data.bugName}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/bug-frequency/?${params}`);
  }

  getTotalBugFrequency(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/total-errors?${params}`);
  }

  getRPVImpact(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=${data.rate}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }
    return this._http.get(`/bugs/getRPVImpact/?${params}`);
  }

  generateShareLink(data: any): Observable<any> {
    data['target'] = this.getTargetFromLocalStorage();

    let params = '';
    if (data.target) {
      params = `target=${data.target}&bug=${data.name}&rate=${data.rate}&startDate=${data.startDate}&endDate=${data.endDate}`;
    } else {
      params = `bug=${data.name}&rate=${data.rate}&startDate=${data.startDate}&endDate=${data.endDate}`;
    }

    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.post('/bugs/links?' + params, {});
  }

  getDailyImpacts(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=day`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/headlines?${params}`);
  }

  getDailyCriticalEvents(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/critical_events?${params}`);
  }

  getRelativeValues(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&variable=${data.variable}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }
    return this._http.get(`/bugs/relative_values?${params}`);
  }

  getBehavioralVariables(data: any): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let tmpParams = '';

    if (data?.rate == 'hourly' && data?.date) {
      tmpParams = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}&rate=hourly&date=${data.date}`;
    } else {
      tmpParams = `target=${target}&startDate=${data.startDate}&endDate=${data.endDate}`;
    }

    if (this.behamicsSample) {
      tmpParams += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/behavioral_variables?${tmpParams}`);
  }

  getHistogramData(): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/histogram?${params}`);
  }

  predictVariables(data: any): Observable<any> {
    return this._http.post(`/predict_behavioral`, data);
  }

  getErrorVisits(data: any): Observable<any> {
    if (data['target']) {
      data['target'] = this.getTargetFromLocalStorage();
    }

    if (this.behamicsSample) {
      data['behamicsSample'] = 'true';
    }

    return this._http.post(`/diagnostics/getErrorVisits`, data);
  }

  getVisitDetails(data: any): Observable<any> {
    if (localStorage.getItem('selectedTarget') == 'behamicsSample') {
      data['target'] = 'gonser';
      data['behamicsSample'] = 'true';
    }

    return this._http.post(`/diagnostics/getVisitDetails`, data);
  }

  getBugDetails(data: any): Observable<any> {
    if (localStorage.getItem('selectedTarget') == 'behamicsSample') {
      data['target'] = 'gonser';
      data['behamicsSample'] = 'true';
    }
    return this._http.post(`/diagnostics/getBugDetails`, data);
  }

  getDailyRevenueLoss(): Observable<any> {
    let target: any = this.getTargetFromLocalStorage();

    let params = `target=${target}`;
    if (this.behamicsSample) {
      params += '&behamicsSample=true';
    }

    return this._http.get(`/bugs/daily-revenue-loss/?${params}`);
  }

  getSimulationLineChart(data: any): Observable<any> {
    if (localStorage.getItem('selectedTarget') === 'behamicsSample') {
      data['target'] = 'gonser';
      data['behamicsSample'] = 'true';
    }
    return this._http.post(`/simulations`, data);
  }

  getImagesForOptimization(params: any): Observable<any> {
    return this._http.get(`/pixel/images?${params}`);
  }

  optimizeImages(data: any): Observable<any> {
    return this._http.post(`/pixel/optimize`, { images: data });
  }

  getOptimizeImages(zipID: any): Observable<any> {
    return this._http.get(`/pixel/optimize?type=download&zipId=${zipID}`);
  }

  downloadOptimizeImages(data: any): Observable<any> {
    return this._http.post(`/pixel/download`, { images: data });
  }

  getGoLiveTargets(): Observable<any> {
    let timezone = this.timezoneService.getTimezone();
    return this._http.get(`/go-live/targets?timezone=${timezone}`);
  }

  deleteGoLiveTarget(target: string): Observable<any> {
    return this._http.delete(`/go-live/target?target=${target}`);
  }

  getAvailableTargets(): Observable<any> {
    return this._http.get(`/go-live/availableTargets`);
  }

  newGoLiveTargets(target: string): Observable<any> {
    return this._http.post(`/go-live/newTarget`, { target: target });
  }

  getAIGeneratedMessage(bug: string, target: string): Observable<any> {
    let params = `bug=${bug}&target=${target}`;
    if (this.behamicsSample) {
      params += `&behamicsSample=true`;
    }

    return this._http.get(`/diagnostics/getAIGeneratedMessage?${params}`);
  }
  getAIGeneratedMessageIssueType(data: any): Observable<any> {
    if (localStorage.getItem('selectedTarget') === 'behamicsSample') {
      data['target'] = 'gonser';
      data['behamicsSample'] = 'true';
    }

    return this._http.post(`/diagnostics/getAIGeneratedIssueType`, data);
  }

  getBrowserOSImpact(params: string): Observable<any> {
    if (params.includes('behamicsSample')) {
      params = params.replace('behamicsSample', 'gonser');
      params += '&behamicsSample=true';
    }
    return this._http.get(`/diagnostics/getBrowserOSImpact?${params}`);
  }
}
