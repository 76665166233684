import { Component, OnInit } from '@angular/core';
import { SharedIssueService } from './shared-issue.service';
import { GoogleChartInterface } from 'ng2-google-charts';
import { linechartColors } from 'src/app/utils/variables';
import { TimezoneService } from 'src/app/services/timezone.service';

@Component({
  selector: 'app-shared-issue',
  templateUrl: './shared-issue.component.html',
  styleUrls: [
    './shared-issue.component.css',
    '../trace-errors/trace-errors.component.css',
  ],
})
export class SharedIssueComponent {
  constructor(
    private sharedIssueService: SharedIssueService,
    private timezoneService: TimezoneService
  ) {}

  firstLoad: boolean = false;
  hasContent: boolean = false;

  token: any;
  clientName: string = '';
  bugObject: any = {};

  minStartDate: any;
  minDate: any;
  maxDate: any;
  endMaxDate: any;
  frequencyStartDate: any;
  frequencyStartDateISO: any;
  frequencyEndDate: any;
  frequencyEndDateISO: any;

  loadChart: boolean = false;

  hidePieCharts: boolean = false;
  browserImpactPieChartLegend: any = [];
  osImpactPieChartLegend: any = [];
  osImpactTotal: any = 0;
  loadOSImpactChart: boolean = false;
  browserImpactTotal: any = 0;
  loadBrowserImpactChart: boolean = false;
  show_full_bug_message: string;

  // ====
  isDayDisabled = false;
  isMonthDisabled = true;

  selectedRangeValue: any = 'today';
  startDate: any;
  endDate: any;
  rate: string = 'hourly';
  target: string = '';

  isDarkMode: boolean = false;
  isLoading: boolean = false;
  demoClient: boolean = false;

  bugSocketID = 0;
  readyToPlay: boolean = false;
  sessionReplay: any;
  userAgentObject: any;
  enableCopy: boolean = false;

  errorsVisitData: any = [];
  errorVisitPageIndex: number = 1;
  bugDetailsPageIndex: number = 1;
  showFrequencyProducts: boolean = false;
  errorVisitsImageUrl: string = '';
  connectionType: string = '';
  rightSizeLoading: boolean = false;
  hideBottomDesc: boolean = false;
  visitDetailsEvents: any = [];
  visitDetailsEventsLength: number = 0;
  clearVisitDetailsEvents: boolean = false;

  savebugObject: any = {};

  errorVisitObject: any = {};

  dayDiffrence: any;
  dayDiffrence2: any;

  displayShowMore: boolean = true;
  showMoreLoader: boolean = false;
  errorVisitsTopUrls: any = [];
  ngOnInit() {
    this.endMaxDate = new Date();

    const params = new URLSearchParams(window.location.search);
    this.token = params.get('token');

    this.getBugSharedData();
  }

  public bugLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      legend: {
        position: 'none',
        // position: 'top',
        // maxLines: 2,
        // alignment: 'center',
        // textStyle: {
        //   fontSize: 11,
        //   fontName: 'Roboto, sans-serif',
        //   color: '#666',
        // },
      },
      pointSize: 0,
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      series: {
        0: {
          targetAxisIndex: 0,
          areaOpacity: 0.1,
          type: 'area',
        },
      },
      forceIFrame: false,
      focusTarget: 'category',
      colors: linechartColors,
      height: 200,
      vAxis: {
        minValue: 0,
        baseline: 0,
        viewWindow: {
          min: 0,
        },
        baselineColor: '#c0c0c0',
        gridlineColor: 'transparent',
        textStyle: {
          fontSize: 13,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 12,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0,
        },
      },
      vAxes: {
        0: {
          format: '',
          baseline: 0,
          viewWindow: {
            // min: 0,
          },
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 60,
        right: 20,
        bottom: 55,
        width: '100%',
      },
    },
  };
  public browserImpactPieChart: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [],
    options: {
      pieSliceText: 'none',
      pieHole: 0.6,
      sliceVisibilityThreshold: 0,
      //focusTarget: 'category',
      colors: [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
      ],
      legend: {
        position: 'none',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      // height: 120,
      // width: 120,
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      animation: {
        startup: true,
        duration: 600,
        easing: 'out',
      },
      chartArea: {
        height: '90%',
        top: 10,
        left: 10,
        right: 10,
        bottom: 15,
        width: '95%',
      },
    },
  };
  public osImpactPieChart: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [],
    options: {
      pieSliceText: 'none',
      pieHole: 0.6,
      sliceVisibilityThreshold: 0,
      //focusTarget: 'category',
      colors: [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
      ],
      legend: {
        position: 'none',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      // height: 120,
      // width: 120,
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      animation: {
        startup: true,
        duration: 600,
        easing: 'out',
      },
      chartArea: {
        height: '90%',
        top: 10,
        left: 10,
        right: 10,
        bottom: 15,
        width: '95%',
      },
    },
  };

  isCurrencyRightAligned: boolean = false;
  displayCurrency() {
    var currency: any = window.localStorage.getItem('currency');

    if (
      window.localStorage.getItem('selectedTarget') == 'corona' ||
      window.localStorage.getItem('selectedTarget') == 'cassel' ||
      window.localStorage.getItem('selectedTarget') == 'copeval'
    ) {
      currency = 'USD';
    }

    switch (currency) {
      case 'EUR':
        this.isCurrencyRightAligned = true;
        return '€';
      case 'USD':
        this.isCurrencyRightAligned = false;
        return '$';
      case 'AUD':
        this.isCurrencyRightAligned = false;
        return 'A$';
      case 'CHF':
        this.isCurrencyRightAligned = false;
        return 'CHF ';
      case 'GBP':
        this.isCurrencyRightAligned = false;
        return '£';
      default:
        this.isCurrencyRightAligned = false;
        return '$';
    }
  }
  formatCurrencySymbol(value: number, toFixed: boolean) {
    if (value >= -0.01) return this.displayCurrency() + '0';
    function formatNumber(num: any) {
      num = Math.abs(num);
      if (toFixed) {
        num = Math.ceil(num);
      }
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    this.displayCurrency();

    if (this.isCurrencyRightAligned) {
      return value < 0
        ? '-' + formatNumber(value) + this.displayCurrency()
        : formatNumber(value) + ' ' + this.displayCurrency();
    } else {
      return value < 0
        ? '-' + this.displayCurrency() + formatNumber(value)
        : this.displayCurrency() + formatNumber(value);
    }
  }

  contructData(data: any) {
    function formatNumber(num: any) {
      num = Math.abs(num);
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    this.clientName = data?.query?.targetDescription;

    Object.keys(data.revenueLoss).forEach((key) => {
      this.bugObject = {
        name: key.replace(/_/g, ' ').split(' on ')[0],
        page: data.revenueLoss[key].page,
        absolute_value: data.revenueLoss[key].absolute_value,
        bug_category: data.revenueLoss[key].bug_category,
        full_bug_message: data.revenueLoss[key].full_bug_message,
        revenue_loss: this.formatCurrencySymbol(
          data.revenueLoss[key].revenue_loss,
          true
        ),
        firstDateOfBug: new Date(
          data?.firstDate?.date.split(' ')[0]
        ).toLocaleString('en-US', {
          hour12: false,
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
      };
      this.minStartDate = new Date(data?.firstDate?.date.split(' ')[0]);
      this.show_full_bug_message = data.revenueLoss[key].full_bug_message;
      if (this.bugObject.name == 'img size mean') {
        this.bugObject.name = 'Image Size';
      }
      if (data.revenueLoss[key]['top_urls']) {
        this.bugObject['top_urls'] = [];
        data.revenueLoss[key]['top_urls'].forEach((url: any) => {
          this.bugObject['top_urls'].push({ url: url });
        });
      } else {
        this.bugObject['top_urls'] = data.revenueLoss[key]['top_url'];
      }
    });
    this.bugObject['totalRevenueLoss'] =
      '-$' + formatNumber(data?.frequency?.totalRevenueLoss);

    if (this.bugObject?.name?.length > 40) {
      this.bugObject.showName = this.bugObject.name.slice(0, 40) + '...';
    } else {
      this.bugObject.showName = this.bugObject.name;
    }

    this.updateBugLineChart(data?.frequency?.returnBugList);

    this.minDate = data?.query?.startDate.split('T')[0];

    // add 1 day to start date since the frequency api returns data from the before day at 23:00PM
    this.frequencyStartDate = new Date(data?.query?.startDate.split('T')[0]);
    this.frequencyStartDate.setDate(this.frequencyStartDate.getDate() + 1);
    this.frequencyStartDate = this.frequencyStartDate
      .toISOString()
      .split('T')[0];

    this.maxDate = data?.query?.endDate.split('T')[0];
    this.frequencyEndDate = new Date(data?.query?.endDate.split('T')[0]);
  }

  getFrequencyStartDate(value: any) {
    this.frequencyStartDate = new Date(value.value);
    this.minDate = this.frequencyStartDate;

    this.startDate =
      this.frequencyStartDate.toISOString() +
      '@' +
      this.timezoneService.getTimezone();
    this.checkDayDifference();
    this.getBugSharedData();
  }
  getFrequencyEndDate(value: any) {
    let nowDate = new Date();
    let date = new Date(value.value);
    this.maxDate = date;
    if (
      date.getFullYear() == nowDate.getFullYear() &&
      date.getMonth() == nowDate.getMonth() &&
      date.getDate() == nowDate.getDate()
    ) {
      date.setHours(nowDate.getHours(), 59, 59);
    } else {
      date.setHours(23, 59, 59);
    }
    this.frequencyEndDate = date;

    this.endDate =
      this.frequencyEndDate.toISOString() +
      '@' +
      this.timezoneService.getTimezone();

    this.checkDayDifference();
    this.getBugSharedData();
  }
  checkDayDifference() {
    const getDayDifference = (date1: any, date2: any) => {
      const timestamp1 = date1.getTime();
      const timestamp2 = date2.getTime();

      const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);

      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.floor(differenceInDays);
    };

    let date1 = new Date(this.startDate.split('T')[0]);
    let date2 = new Date(this.endDate.split('T')[0]);

    let dayDifference = getDayDifference(date1, date2);

    // If component has KPI with rate filter
    if (dayDifference > 60) {
      this.rate = 'month';
      this.isDayDisabled = false;
      this.isMonthDisabled = false;
    } else if (dayDifference > 2) {
      this.rate = 'day';
      this.isDayDisabled = false;
      this.isMonthDisabled = true;
    } else {
      this.rate = 'hourly';
      this.isDayDisabled = true;
      this.isMonthDisabled = true;
    }
  }
  updateBugLineChart(bugData: any) {
    let tmpBugName = this.bugObject.name;
    function formatNumber(num: any) {
      if (tmpBugName == 'img size mean' || tmpBugName == 'Image Size') {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      } else {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    }

    function formatDateFull(date: any) {
      if (date) {
        if (date.length > 11) {
          if (date.split(' ')[1].length == 1) {
            date = date.split(' ')[0] + ' 0' + date.split(' ')[1];
          }
          var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
          var MM = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          var xx = x.replace(
            /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
            function ($0, $1, $2, $3, $4, $5) {
              var ampm12 = $4 >= 12 ? 'PM' : 'AM';
              $4 = $4 % 12;
              $4 = $4 ? $4 : 12;
              return MM[$2 - 1] + ' ' + $3 + ', ' + $1 + ' - ' + $4 + ampm12;
            }
          );
          return xx;
        } else if (date.length > 8) {
          var xd = date;
          var MMd = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          var xxd = xd.replace(
            /(\d{4})-(\d{2})-(\d{2})/,
            function ($0: any, $1: any, $2: any, $3: any) {
              return MMd[$2 - 1] + ' ' + $3 + ', ' + $1;
            }
          );
          return xxd;
        } else {
          var xm = date;
          var MMm = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          var xxm = xm.replace(
            /(\d{4})-(\d{2})/,
            function ($0: any, $1: any, $2: any, $3: any) {
              return MMm[$2 - 1] + ', ' + $1;
            }
          );
          return xxm;
        }
      }
    }

    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        return xx;
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    let incLineData = [
      ['Time', 'Total Errors', { role: 'tooltip', p: { html: true } }],
    ];

    if (tmpBugName == 'img size mean' || tmpBugName == 'Image Size') {
      incLineData[0][1] = 'Mean Size of Images';
      this.bugLineChart.options['vAxes'][0].format = "#'KB'";
    }

    for (let i = 0; i < bugData?.length; i++) {
      let tmpStr =
        "<p class='tooltip-text'><b>Total Errors: </b>" +
        formatNumber(bugData[i].bug) +
        '</p>';
      if (tmpBugName == 'img size mean' || tmpBugName == 'Image Size') {
        tmpStr =
          "<p class='tooltip-text'><b>Mean Size of Images: </b>" +
          formatNumber(bugData[i].bug) +
          ' KB</p>';
      }
      incLineData.push([
        {
          v: bugData[i].date,
          f: formatAMPM(bugData[i].date),
        },
        bugData[i].bug,
        "<p class='tooltip-text'><b>" +
          formatDateFull(bugData[i].date) +
          '</b></p>' +
          tmpStr,
      ]);
    }

    //Check max value, and if the val is 0 then update max val of chart to fixed
    let maxData: any = [];
    for (const val of incLineData) {
      if (val[1] !== 'Total Errors') {
        maxData.push(val[1]);
      }
    }
    let maxVal = Math.max(...maxData);
    if (maxVal == 0) {
      this.bugLineChart.options['vAxis'].viewWindow.max = 10;
    } else {
      this.bugLineChart.options['vAxis'].viewWindow.max = maxVal + maxVal * 0.2;
    }
    if (maxVal > 1000000) {
      this.bugLineChart.options['vAxis'].format = 'short';
    } else {
      this.bugLineChart.options['vAxis'].format = 'decimal';
    }

    // let pointLength = bugData?.length;
    // if (pointLength >= 45) {
    // } else {
    //   this.bugLineChart.options['pointSize'] = 4;
    // }
    this.bugLineChart.options['pointSize'] = 0;

    this.bugLineChart.dataTable = incLineData;

    this.bugLineChart.options['tooltip'].trigger = 'hover';

    if (bugData?.length == 0) {
      this.bugLineChart.dataTable = [
        ['Time', 'Total Errors'],
        ['', 0],
        ['', 0],
      ];
      this.bugLineChart.options['pointSize'] = 0;
      this.bugLineChart.options['vAxis'].title = '';
      this.bugLineChart.options['hAxis'].title = '';
      this.bugLineChart.options['tooltip'].trigger = 'none';
    }

    this.bugLineChart.component?.draw(this.bugLineChart);

    setTimeout(() => {
      this.loadChart = true;
    }, 30);
  }
  saveBugName: string = '';
  getBugSharedData() {
    let tmpParams = 'token=' + this.token + '&sharable=true';
    if (this.frequencyStartDate) {
      if (typeof this.frequencyStartDate == 'string') {
        this.frequencyStartDate = new Date(this.frequencyStartDate);
      }

      tmpParams +=
        '&startDate=' +
        this.frequencyStartDate.toISOString() +
        '@' +
        this.timezoneService.getTimezone();
    }
    if (this.frequencyEndDate) {
      if (typeof this.frequencyEndDate == 'string') {
        this.frequencyEndDate = new Date(this.frequencyEndDate);
      }

      tmpParams +=
        '&endDate=' +
        this.frequencyEndDate.toISOString() +
        '@' +
        this.timezoneService.getTimezone();
    }

    this.isLoading = true;
    this.sharedIssueService.getSharedData(tmpParams).subscribe(
      (data) => {
        if (
          data?.browserAndOSImpact?.Browser?.length == 0 &&
          data?.browserAndOSImpact?.OperatingSystem?.length == 0
        ) {
          this.hidePieCharts = true;
        } else {
          this.hidePieCharts = false;
          this.updateBrowserImpactPieChart(data?.browserAndOSImpact.Browser);
          this.updateOSImpactPieChart(data?.browserAndOSImpact.OperatingSystem);
        }

        this.contructData(data);

        if (data && data['query']) {
          let bugObj = data.revenueLoss[Object.keys(data.revenueLoss)[0]];
          let query = data['query'];
          this.saveBugName = query.bug;
          this.target = query.target;

          if (!this.firstLoad) {
            this.startDate = query.startDate;
            this.endDate = query.endDate;

            this.savebugObject = {
              pageType: bugObj.page,
              category: bugObj.bug_category,
              issue: query.bug.split('_on_')[0],
            };
            this.errorVisitObject = {
              target: this.target,
              bugKey: Object.keys(data.revenueLoss)[0],
              startDate: this.startDate,
              endDate: this.endDate,
            };
          }
          this.checkDayDifference();
          this.getErrorVisits(0);
        } else {
          this.isLoading = false;
        }
        if (this.firstLoad) {
          this.traceErrorInfo();
        }

        this.hasContent = true;
        this.firstLoad = true;
      },
      (error) => {
        this.isLoading = false;
        this.firstLoad = true;
        this.hasContent = false;
        this.hidePieCharts = true;
      }
    );
  }
  checkValidJSON(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  getErrorVisits(number: number) {
    this.errorVisitPageIndex += number;
    this.errorVisitObject['page'] = this.errorVisitPageIndex;

    this.showMoreLoader = true;
    this.isLoading = true;
    this.sharedIssueService
      .getErrorVisits(
        this.returnOneDayDiffence(
          this.errorVisitObject,
          this.errorVisitPageIndex,
          'getErrorVisits'
        ),
        this.token
      )
      .subscribe({
        next: (data) => {
          if (this.clearVisitDetailsEvents) {
            this.errorsVisitData = [];
          }
          if (typeof data !== 'string' && data?.tempSessionIDs?.length > 0) {
            this.displayShowMore = true;
            try {
              this.errorsVisitData = this.errorsVisitData.concat(
                data.tempSessionIDs
              );
              let tmpArr: any = [];
              this.errorsVisitData.forEach((element: any) => {
                // if (typeof element == 'string') {
                tmpArr.push({
                  tempSessionID: element.tempSessionID,
                  readyToPlay: element.readyToPlay,
                  cssActive: false,
                });
                // } else {
                //   element['cssActive'] = false;
                //   tmpArr.push(element);
                // }
              });
              this.errorsVisitData = tmpArr;
              this.errorsVisitData.forEach((element: any) => {
                if (typeof element == 'object') element['cssActive'] = false;
              });
              if (
                this.errorsVisitData &&
                this.errorsVisitData.length > 0 &&
                this.errorVisitPageIndex - 1 == 0
              ) {
                this.getVisitDetails(this.errorsVisitData[0]);
                if (this.errorsVisitData[0]?.image_url) {
                  this.errorVisitsImageUrl = this.errorsVisitData[0]?.image_url;
                } else if (this.errorsVisitData[0]?.url) {
                  this.errorVisitsImageUrl = this.errorsVisitData[0]?.url;
                } else {
                  this.errorVisitsImageUrl = '';
                }
              }

              this.clearVisitDetailsEvents = false;
            } catch (e) {
              console.log('Error: ', e);
            }
          } else if (this.dayDiffrence > 0 || this.errorVisitPageIndex > 1) {
            if (
              this.errorsVisitData?.length > 0 &&
              (!this.dayDiffrence || this.dayDiffrence == 0)
            ) {
              this.displayShowMore = false;
            } else {
              this.errorVisitPageIndex = 1;
              this.getErrorVisits(0);
            }
          } else if (this.errorsVisitData?.length > 0) {
            this.displayShowMore = false;
          } else {
            this.clearVisitDetailsEvents = true;
            this.visitDetailsEvents = [];
            this.displayShowMore = false;
            this.errorsVisitData = [
              {
                tempSessionID: 'No data available',
                socketID: 'No data available',
                cssActive: false,
              },
              {
                tempSessionID: 'No data available',
                socketID: 'No data available',
                cssActive: false,
              },
              {
                tempSessionID: 'No data available',
                socketID: 'No data available',
                cssActive: false,
              },
            ];
            this.getVisitDetails(null);
          }

          this.errorVisitsTopUrls = data?.top_urls;

          this.showMoreLoader = false;
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.errorVisitsTopUrls = [];
          this.clearVisitDetailsEvents = true;
          this.visitDetailsEvents = [];
          this.showMoreLoader = false;
          this.displayShowMore = false;
          this.errorsVisitData = [
            {
              tempSessionID: 'No data available',
              socketID: 'No data available',
              cssActive: false,
            },
            {
              tempSessionID: 'No data available',
              socketID: 'No data available',
              cssActive: false,
            },
            {
              tempSessionID: 'No data available',
              socketID: 'No data available',
              cssActive: false,
            },
          ];
          this.getVisitDetails(null);
        },
      });
  }
  getVisitDetails(data: any) {
    try {
      // this.rightSizeLoading = true;
      // if (data == null) {
      //   this.visitDetailsEvents = [
      //     {
      //       noData: 'No data available',
      //     },
      //     {
      //       noData: 'No data available',
      //     },
      //     {
      //       noData: 'No data available',
      //     },
      //   ];
      //   // if (this.isLoading) this.combinedLoading(1);
      //   setTimeout(() => {
      //     this.rightSizeLoading = false;
      //   }, 300);
      //   return;
      // }
      this.errorsVisitData.forEach((element: any) => {
        if (typeof element == 'object') element['cssActive'] = false;
      });
      if (typeof data == 'object') data['cssActive'] = true;

      let tmpData = {
        target: this.target,
        tempSessionID: data.tempSessionID,
        pageType: this.savebugObject?.pageType,
        category: this.savebugObject?.category,
        startDate: this.startDate,
        endDate: this.endDate,
        issue: this.savebugObject?.issue,
      };

      this.sharedIssueService.getVisitDetails(tmpData, this.token).subscribe(
        (data) => {
          try {
            this.bugSocketID = data?.data?.socketID;
            if (this.bugSocketID >= 0) {
              this.bugSocketID -= 1;
            }

            this.readyToPlay = data?.['sessionReplay']?.readyToPlay;
            this.sessionReplay = data?.['sessionReplay'];

            this.userAgentObject = data?.userAgent;
            this.visitDetailsEvents = [];
            if (data && data.events && data.events.length > 0) {
              let tmpArray = data.events;
              tmpArray.forEach((element: any) => {
                element['type'] = element?.events[0]?.eventType;
                element['time'] = new Date(
                  element?.events[0]?.date
                ).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
                  day: 'numeric',
                  month: 'short',
                });
                let tmpObj: any = {
                  request_error: element?.events[0]?.eventType,
                  time: element['time'],
                  location: element?.location,
                  page: element?.page,
                  url: element?.location,
                  productID: element?.events[0]?.data?.productID,
                  events: [],
                  sockedID: String(element?.socketID),
                };
                let tmpScrollAdded = false;
                if (element?.events && element?.events?.length > 0) {
                  for (let i = 0; i < element?.events?.length; i++) {
                    if (
                      element?.events[i]?.eventType == 'scroll' &&
                      !tmpScrollAdded
                    ) {
                      tmpScrollAdded = true;
                      tmpObj.events.push({
                        name: element?.events[i]?.eventType,
                        status: '',
                        class: element?.events[i]?.eventType,
                      });
                    } else if (element?.events[i]?.eventType != 'scroll') {
                      let tmpObj2 = {
                        name: element?.events[i]?.eventType,
                        status: '',
                        class: element?.events[i]?.eventType,
                      };
                      if (element?.events[i]?.eventType == 'click') {
                        tmpObj2.status = element?.events[i]?.action
                          ? element?.events[i]?.action?.replace(/:/, ' ')
                          : element?.events[i]?.eventSource?.replace(/:/, ' ');
                      }
                      tmpObj.events.push(tmpObj2);
                    }
                  }
                }
                this.connectionType = element?.events[0]?.connectionType;
                this.visitDetailsEvents.push(tmpObj);
              });
              this.hideBottomDesc = false;

              if (data?.data[0]) {
                this.errorVisitsImageUrl = data?.data[0]['image_url'];
              } else {
                this.errorVisitsImageUrl = data?.data['image_url'];
              }

              let tmpDataObj = data.data;
              if (this.errorVisitsImageUrl) {
                let tmpName = 'product detail image';
                if (this.bugObject.name.includes('recommendation')) {
                  tmpName = 'recommendation engine image';
                } else if (
                  this.bugObject.name.includes('img size mean') ||
                  this.bugObject.name.includes('Image Size')
                ) {
                  tmpName = 'image';
                } else if (this.bugObject.page != 'Product Page') {
                  tmpName = 'product image';
                }

                this.visitDetailsEvents[this.bugSocketID].events.push({
                  name: tmpName,
                  status: '',
                  class: 'error',
                  link: this.errorVisitsImageUrl,
                });
              }

              let showStatus = '';

              if (
                tmpDataObj['response'] &&
                Object.keys(tmpDataObj['response']).length
              ) {
                if (
                  this.checkValidJSON(tmpDataObj['response']) &&
                  tmpDataObj['response']?.length < 5000
                ) {
                  this.enableCopy = false;
                  showStatus = JSON.stringify(
                    JSON.parse(tmpDataObj['response']),
                    undefined,
                    4
                  );
                } else {
                  if (tmpDataObj['response']?.length > 1000) {
                    this.enableCopy = true;
                    showStatus = tmpDataObj['response']?.slice(0, 1000) + '...';
                  } else {
                    this.enableCopy = false;
                    showStatus = tmpDataObj['response'];
                  }
                }
              } else {
                if (
                  this.checkValidJSON(tmpDataObj['response_data']) &&
                  tmpDataObj['response_data']?.length < 5000
                ) {
                  if (tmpDataObj['response_data']?.length > 500) {
                    this.enableCopy = true;
                    showStatus =
                      JSON.stringify(
                        JSON.parse(tmpDataObj['response_data']?.slice(0, 500)),
                        undefined,
                        4
                      ) + '...';
                  } else {
                    this.enableCopy = false;
                    showStatus = JSON.stringify(
                      JSON.parse(tmpDataObj['response_data']),
                      undefined,
                      4
                    );
                  }
                } else {
                  if (tmpDataObj['response_data']?.length > 1000) {
                    this.enableCopy = true;
                    showStatus =
                      tmpDataObj['response_data']?.slice(0, 1000) + '...';
                  } else {
                    this.enableCopy = false;
                    showStatus = tmpDataObj['response_data'];
                  }
                }
              }

              if (tmpDataObj['response_data'] || tmpDataObj['response']) {
                this.hideBottomDesc = true;
              }
              if (tmpDataObj['request_type']) {
                this.visitDetailsEvents[this.bugSocketID].events.push({
                  name: tmpDataObj['request_type'],
                  status: tmpDataObj['response_data']
                    ? tmpDataObj['response_data']
                    : tmpDataObj['response'],
                  class: 'error',
                  link: '',
                  method: tmpDataObj['request_method'],
                  resStatus: tmpDataObj['response_status'],
                  showStatus: showStatus,
                  resUrl: this.bugObject.full_bug_message,
                  sockedID: tmpDataObj?.socketID,
                });
              }
              // }
              this.visitDetailsEventsLength =
                this.visitDetailsEvents.length - 1;
              this.visitDetailsEvents.push({
                request_error: '',
                time: '',
                location: '',
                page: 'Exit',
                url: '',
                productID: '',
                events: [],
              });
            } else if (data && typeof data == 'object') {
              let tmpData = data;
              if (data['data']) {
                tmpData = data['data'];
              }
              let tmpTime = new Date(tmpData?.date).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                day: 'numeric',
                month: 'short',
              });
              let tmpObj: any = {
                name: tmpData.request_type
                  ? tmpData.request_type
                  : tmpData.type,
                status: '',
                class: 'error',
                link: tmpData?.image_url,
              };
              if (
                tmpObj.name == 'image_error' ||
                this.bugObject['name'] == 'recommendation image error'
              ) {
                tmpObj.name = 'product detail image';
                if (this.bugObject.name.includes('recommendation')) {
                  tmpObj.name = 'recommendation engine image';
                } else if (
                  this.bugObject.name.includes('img size mean') ||
                  this.bugObject.name.includes('Image Size')
                ) {
                  tmpObj.name = 'image';
                } else if (this.bugObject.page != 'Product Page') {
                  tmpObj.name = 'product image';
                }
              }
              tmpObj['method'] = tmpData['request_method']
                ? tmpData['request_method']
                : 'GET';
              tmpObj['resStatus'] = tmpData['response_status'];
              tmpObj['resUrl'] = this.bugObject.full_bug_message;
              tmpObj['sockedID'] = String(tmpData?.socketID);

              if (tmpObj['resStatus']) {
                this.hideBottomDesc = true;
              } else {
                this.hideBottomDesc = false;
              }
              this.visitDetailsEvents = [
                {
                  request_error: tmpData?.request_error,
                  time: tmpTime,
                  location: tmpData?.location,
                  page: tmpData?.page ? tmpData?.page : this.bugObject.page,
                  url: tmpData?.url,
                  sockedID: tmpData?.socketID,
                  events: [
                    {
                      name: 'Load',
                      status: '',
                      class: '',
                    },
                  ],
                },
              ];
              this.visitDetailsEvents[0].events.push(tmpObj);
              this.visitDetailsEventsLength =
                this.visitDetailsEvents.length - 1;
              this.visitDetailsEvents.push({
                request_error: '',
                time: '',
                location: '',
                page: 'Exit',
                url: '',
                productID: '',
                events: [],
              });
            } else {
              this.visitDetailsEvents = [
                {
                  noData: 'No data available',
                },
                {
                  noData: 'No data available',
                },
                {
                  noData: 'No data available',
                },
              ];
            }

            // if (this.isLoading) this.combinedLoading(1);

            setTimeout(() => {
              this.rightSizeLoading = false;
            }, 300);

            // this.domFocusError();
          } catch (e) {
            console.log('Error: ', e);
            setTimeout(() => {
              this.rightSizeLoading = false;
            }, 300);
          }
        },
        (error) => {
          // if (this.isLoading) this.combinedLoading(1);
          setTimeout(() => {
            this.rightSizeLoading = false;
            this.visitDetailsEvents = [
              {
                noData: 'No data available',
              },
              {
                noData: 'No data available',
              },
              {
                noData: 'No data available',
              },
            ];
          }, 300);
        }
      );
    } catch (e) {
      console.log('Error: ', e);
    }
  }
  returnOneDayDiffence(obj: any, pageIndex: number, functionName: string) {
    // return obj;
    if (pageIndex != 1) return obj;

    let tmpStartDate = new Date(obj?.startDate?.split('@')[0]);
    let tmpEndDate = new Date(obj?.endDate?.split('@')[0]);
    let tmpDiffrence = tmpEndDate.getTime() - tmpStartDate.getTime();
    let tmpDay = Math.floor(tmpDiffrence / (1000 * 60 * 60 * 24));

    tmpStartDate.setDate(tmpEndDate.getDate());
    tmpStartDate.setMonth(tmpEndDate.getMonth());

    let tmpDayDiffrence = 0;
    if (functionName == 'getErrorVisits') {
      tmpDayDiffrence = this.dayDiffrence--;
    } else if (functionName == 'getBugDetails') {
      tmpDayDiffrence = this.dayDiffrence2--;
    }

    try {
      if (tmpDayDiffrence >= 0) {
        if (tmpDayDiffrence == 0) return obj;
        let tmpIndex = tmpDay - --tmpDayDiffrence;

        tmpStartDate.setDate(tmpStartDate.getDate() - tmpIndex);
        obj['startDate'] =
          tmpStartDate.toISOString() + '@' + this.timezoneService.getTimezone();

        tmpEndDate.setDate(tmpEndDate.getDate() - tmpIndex);
        tmpEndDate.setHours(23, 59, 59);
        obj['endDate'] =
          tmpEndDate.toISOString() + '@' + this.timezoneService.getTimezone();
      } else if (tmpDay > 0) {
        if (functionName == 'getErrorVisits') {
          this.dayDiffrence = tmpDay;
        } else if (functionName == 'getBugDetails') {
          this.dayDiffrence2 = tmpDay;
        }

        tmpStartDate.setDate(tmpStartDate.getDate());
        obj['startDate'] =
          tmpStartDate.toISOString() + '@' + this.timezoneService.getTimezone();

        tmpEndDate.setDate(tmpEndDate.getDate());
        obj['endDate'] =
          tmpEndDate.toISOString() + '@' + this.timezoneService.getTimezone();
      }

      return obj;
    } catch (e) {
      console.log('Error: ', e);
      return obj;
    }
  }
  updateBrowserImpactPieChart(data: any[]) {
    function formatNumber(num: any) {
      num = Math.abs(num);
      num = Math.ceil(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    try {
      let pieChartData = [];
      this.browserImpactTotal = 0;
      // populate pieChartData
      for (let i = 0; i < data.length; i++) {
        pieChartData.push([
          data[i]._id,
          data[i].count,
          "<p class='tooltip-text bugpiechart-tooltip'><b>" +
            data[i]._id +
            ': </b>' +
            data[i].count +
            '</p>',
        ]);
        this.browserImpactTotal += data[i].count;
      }

      this.browserImpactTotal = formatNumber(this.browserImpactTotal);

      // sort from lowest to highest
      pieChartData.sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        } else if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      });

      //  add dummy data if there is no data so that the pie chart will still show
      if (pieChartData.length == 0) {
        let dummyData = [
          { name: 'No data available', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
        ];
        pieChartData = [];

        for (let i = 0; i < dummyData.length; i++) {
          pieChartData.push([dummyData[i].name, 1, '']);
        }

        // add gray color
        this.browserImpactPieChart.options['colors'] = [
          '#A8A8A8',
          '#A9A9A9',
          '#B0B0B0',
          '#B8B8B8',
          '#BEBEBE',
          '#C0C0C0',
          '#C8C8C8',
          '#D0D0D0',
          '#D3D3D3',
        ];
      } else {
        // reset the colors
        this.browserImpactPieChart.options['colors'] = [
          '#CAECFF',
          '#5E4FFF',
          '#E1FFCA',
          '#C8C3FF',
          '#4DA18C',
          '#0099c6',
          '#dd4477',
          '#66aa00',
          '#b82e2e',
        ];
      }

      // add first element
      pieChartData.unshift([
        'Types',
        'Value',
        { role: 'tooltip', p: { html: true } },
      ]);
      this.browserImpactPieChart.dataTable = pieChartData;
      this.browserImpactPieChart.component?.draw(this.browserImpactPieChart);
      this.updateBrowserPieChartLegend();
      this.loadBrowserImpactChart = true;
    } catch (e) {
      this.loadBrowserImpactChart = true;
      let dummyData = [
        { name: 'No data available', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
      ];
      let pieChartData = [];

      for (let i = 0; i < dummyData.length; i++) {
        pieChartData.push([dummyData[i].name, 1, '']);
      }
      pieChartData.unshift([
        'Types',
        'Value',
        { role: 'tooltip', p: { html: true } },
      ]);

      // add gray color
      this.browserImpactPieChart.options['colors'] = [
        '#A8A8A8',
        '#A9A9A9',
        '#B0B0B0',
        '#B8B8B8',
        '#BEBEBE',
        '#C0C0C0',
        '#C8C8C8',
        '#D0D0D0',
        '#D3D3D3',
      ];
      this.browserImpactPieChart.dataTable = pieChartData;
      this.browserImpactPieChart.component?.draw(this.browserImpactPieChart);

      console.log('Error: ', e);
    }
  }
  updateBrowserPieChartLegend() {
    function formatNumber(num: any) {
      if (typeof num == 'string') return;

      num = Math.abs(num);
      num = Math.ceil(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    this.browserImpactPieChartLegend = [];

    let tmpRelativeValue = '';
    for (let i = 1; i < this.browserImpactPieChart.dataTable.length; i++) {
      if (
        this.browserImpactPieChart.dataTable[i][0] == 'No data available' ||
        this.browserImpactPieChart.dataTable[i][0] == '-'
      ) {
        tmpRelativeValue = '-';
      } else {
        tmpRelativeValue = this.browserImpactPieChart.dataTable[i][1];
        this.browserImpactPieChartLegend.push({
          name: this.browserImpactPieChart.dataTable[i][0],
          relative_value: formatNumber(tmpRelativeValue),
          color: this.browserImpactPieChart.options['colors'][i - 1],
        });
      }
    }
  }
  updateOSImpactPieChart(data: any[]) {
    function formatNumber(num: any) {
      num = Math.abs(num);
      num = Math.ceil(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    try {
      let pieChartData = [];
      this.osImpactTotal = 0;
      // populate pieChartData
      for (let i = 0; i < data.length; i++) {
        pieChartData.push([
          data[i]._id,
          data[i].count,
          "<p class='tooltip-text bugpiechart-tooltip'><b>" +
            data[i]._id +
            ': </b>' +
            data[i].count +
            '</p>',
        ]);
        this.osImpactTotal += data[i].count;
      }
      this.osImpactTotal = formatNumber(this.osImpactTotal);

      // sort from lowest to highest
      pieChartData.sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        } else if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      });

      //  add dummy data if there is no data so that the pie chart will still show
      if (pieChartData.length == 0) {
        let dummyData = [
          { name: 'No data available', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
          { name: '-', relative_value: 1 },
        ];
        pieChartData = [];

        for (let i = 0; i < dummyData.length; i++) {
          pieChartData.push([dummyData[i].name, 1, '']);
        }

        // add gray color
        this.osImpactPieChart.options['colors'] = [
          '#A8A8A8',
          '#A9A9A9',
          '#B0B0B0',
          '#B8B8B8',
          '#BEBEBE',
          '#C0C0C0',
          '#C8C8C8',
          '#D0D0D0',
          '#D3D3D3',
        ];

        this.osImpactPieChart.options['tooltip'].trigger = 'none';
      } else {
        // reset the colors
        this.osImpactPieChart.options['colors'] = [
          '#CAECFF',
          '#5E4FFF',
          '#E1FFCA',
          '#C8C3FF',
          '#4DA18C',
          '#0099c6',
          '#dd4477',
          '#66aa00',
          '#b82e2e',
        ];
        this.osImpactPieChart.options['tooltip'].trigger = 'hover';
      }

      // add first element
      pieChartData.unshift([
        'Types',
        'Value',
        { role: 'tooltip', p: { html: true } },
      ]);
      this.osImpactPieChart.dataTable = pieChartData;
      this.osImpactPieChart.component?.draw(this.osImpactPieChart);
      this.updateOSPieChartLegend();
      this.loadOSImpactChart = true;
    } catch (e) {
      this.loadOSImpactChart = true;
      let dummyData = [
        { name: 'No data available', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
        { name: '-', relative_value: 1 },
      ];
      let pieChartData = [];

      for (let i = 0; i < dummyData.length; i++) {
        pieChartData.push([dummyData[i].name, 1, '']);
      }
      pieChartData.unshift([
        'Types',
        'Value',
        { role: 'tooltip', p: { html: true } },
      ]);
      this.osImpactPieChart.dataTable = pieChartData;
      this.osImpactPieChart.component?.draw(this.osImpactPieChart);

      // add gray color
      this.osImpactPieChart.options['colors'] = [
        '#A8A8A8',
        '#A9A9A9',
        '#B0B0B0',
        '#B8B8B8',
        '#BEBEBE',
        '#C0C0C0',
        '#C8C8C8',
        '#D0D0D0',
        '#D3D3D3',
      ];

      this.osImpactPieChart.options['tooltip'].trigger = 'none';
      console.log('Error: ', e);
    }
  }
  updateOSPieChartLegend() {
    function formatNumber(num: any) {
      if (typeof num == 'string') return;

      num = Math.abs(num);
      num = Math.ceil(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    this.osImpactPieChartLegend = [];

    let tmpRelativeValue = '';
    for (let i = 1; i < this.osImpactPieChart.dataTable.length; i++) {
      if (
        this.osImpactPieChart.dataTable[i][0] == 'No data available' ||
        this.osImpactPieChart.dataTable[i][0] == '-'
      ) {
        tmpRelativeValue = '-';
      } else {
        tmpRelativeValue = this.osImpactPieChart.dataTable[i][1];
        this.osImpactPieChartLegend.push({
          name: this.osImpactPieChart.dataTable[i][0],
          relative_value: formatNumber(tmpRelativeValue),
          color: this.osImpactPieChart.options['colors'][i - 1],
        });
      }
    }
  }
  onRateChange(value: any) {
    this.rate = value;
    this.traceErrorInfo();
  }
  traceErrorInfo() {
    try {
      let tmpParams = `target=${this.target}&bug=${this.saveBugName}&rate=${this.rate}`;

      if (this.frequencyStartDate) {
        tmpParams += '&startDate=' + this.startDate;
      }
      if (this.frequencyEndDate) {
        tmpParams += '&endDate=' + this.endDate;
      }

      this.isLoading = true;
      this.sharedIssueService.traceErrorInfo(tmpParams).subscribe({
        next: (data: any) => {
          try {
            this.updateBugLineChart(data?.frequency?.returnBugList);
            this.isLoading = false;
          } catch (e) {
            console.log('Error: ', e);
          }
        },
        error: (error: any) => {
          this.isLoading = false;
        },
      });
    } catch (e) {
      console.log('Error: ', e);
    }
  }
  copyToClipboard(text: string) {
    if (!this.enableCopy) return;

    navigator.clipboard.writeText(text);

    // this.openSnackBar('Text copied!', 'x');
  }
  onResize(event: any) {
    // Rebuild charts so they are responsive
    this.bugLineChart.component?.draw(this.bugLineChart);
  }
}
