import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { environmentUS } from '../../environments/environment';
import { TimezoneService } from '../services/timezone.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private timezoneService: TimezoneService
  ) {}

  shiftDates(urlString: string, reqBody: any): string {
    const updateUrlParams = (
      url: string,
      newStartDate: Date,
      newEndDate: Date
    ) => {
      const timeZone = this.timezoneService.getTimezone();
      const urlObj = new URL(url, window.location.origin); // Ensure proper parsing
      const params = new URLSearchParams(urlObj.search);

      // Update startDate and endDate
      params.set('startDate', newStartDate.toISOString() + '@' + timeZone);
      params.set('endDate', newEndDate.toISOString() + '@' + timeZone);

      // Construct the updated URL
      return urlObj.pathname + '?' + params.toString();
    };
    function areDatesEqualIgnoringTime(date1: Date, date2: Date): boolean {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    }

    const maxDate = new Date(
      new Date('2025-02-12').setHours(new Date().getHours(), 59, 59)
    );

    const userSelectedStartDate = new Date(
      localStorage.getItem('startDate')?.split('@')[0] ?? 0
    );

    const userSelectedEndDate = new Date(
      localStorage.getItem('endDate')?.split('@')[0] ?? 0
    );

    if (
      userSelectedStartDate.getFullYear() > 2000 &&
      userSelectedEndDate.getFullYear() > 2000
    ) {
      let todayEndDate = new Date();
      todayEndDate.setHours(userSelectedEndDate.getHours(), 59, 59);

      let todayStartDate = new Date();
      todayStartDate.setHours(0, 0, 0);

      const dateDifference = Math.floor(
        (userSelectedEndDate.getTime() - userSelectedStartDate.getTime()) /
          (1000 * 60 * 60 * 24)
      );

      // Determine storedEndDate
      let storedEndDate = new Date(maxDate);

      if (!areDatesEqualIgnoringTime(userSelectedEndDate, todayEndDate)) {
        const endDateDiff = Math.floor(
          (todayEndDate.getTime() - userSelectedEndDate.getTime()) /
            (1000 * 60 * 60 * 24)
        );
        storedEndDate.setDate(maxDate.getDate() - endDateDiff);
        storedEndDate.setHours(23, 59, 59);
      }

      // Determine storedStartDate
      let storedStartDate = new Date(storedEndDate);
      storedStartDate.setDate(storedEndDate.getDate() - dateDifference);
      storedStartDate.setHours(0, 0, 0);

      if (reqBody) {
        if (reqBody['startDate']) {
          reqBody['startDate'] = storedStartDate;
        }
        if (reqBody['endDate']) {
          reqBody['endDate'] = storedEndDate;
        }
      }

      return updateUrlParams(urlString, storedStartDate, storedEndDate);
    } else {
      return urlString;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const region = localStorage.getItem('region');
    const selectedEnvironment =
      region === 'central-us' ? environmentUS : environment;

    let requestURL = req.url;

    if (
      localStorage.getItem('selectedTarget') === 'behamicsSample' &&
      !requestURL.includes('/recorder/')
    ) {
      requestURL = this.shiftDates(requestURL, req.body);
    }

    if (
      this.router.url == '/statistics-configurations' ||
      this.router.url == '/atc-alert-system' ||
      requestURL.includes('/statistics/')
    ) {
      const baseUrl = selectedEnvironment.statistics;

      if (requestURL == '/targets') {
        const masterUrl = selectedEnvironment.address;
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${masterUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      }
      if (req.responseType != 'json') {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          url: `${baseUrl}${requestURL}`,
          responseType: 'blob',
        });

        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${baseUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      }
    } else if (this.router.url == '/predictions/returnrate') {
      const baseUrl = selectedEnvironment.statistics;

      if (requestURL == '/targets') {
        const masterUrl = selectedEnvironment.address;
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${masterUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      }

      if (req.responseType != 'json') {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${baseUrl}${requestURL}`,
          responseType: 'text',
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${baseUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      }
    } else if (this.router.url.includes('/model-automation')) {
      const baseUrl = selectedEnvironment.modelautomation;
      if (
        requestURL ==
          '/privileges/userPrivileges/user?email=master@behamics.com' ||
        requestURL == '/targets'
      ) {
        const masterUrl = selectedEnvironment.address;
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${masterUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `${baseUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      }
    } else {
      const baseUrl = selectedEnvironment.address;
      if (
        requestURL.includes('/diagnostics/') ||
        requestURL.includes('/simulations') ||
        requestURL.includes('/pixel/images') ||
        requestURL.includes('/go-live/targets') ||
        requestURL.includes('/go-live/target') ||
        requestURL.includes('/go-live/newTarget') ||
        requestURL.includes('/go-live/availableTargets')
      ) {
        let token = 'token:' + localStorage.getItem('auth_key');
        let reqUrl = requestURL;
        if (requestURL.includes('sharableBug/')) {
          token =
            'bugShareableToken:' + requestURL?.split('token=')[1].split('&')[0];

          reqUrl = requestURL.replace('sharableBug', '').split('?token')[0];
        }

        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token,
            Accept: 'application/json',
          }),
          url: `${selectedEnvironment.bugsProject}${reqUrl}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else if (
        requestURL.includes('/pixel/optimize') ||
        requestURL.includes('/pixel/download')
      ) {
        let request: any;
        let tmpUrl = requestURL.replace('/pixel', '');
        if (requestURL.includes('/pixel/download')) {
          request = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'token:' + localStorage.getItem('auth_key'),
            }),
            responseType: 'arraybuffer',
            url: `https://bugs.behamics.com/pixel/download`,
          });
        } else if (req.method == 'POST') {
          request = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'token:' + localStorage.getItem('auth_key'),
            }),
            url: `${selectedEnvironment.imageOptimization}${tmpUrl}`,
          });
        } else {
          request = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/gzip',
              'Content-Disposition': 'attachment; filename=images.gz',
              Authorization: 'token:' + localStorage.getItem('auth_key'),
            }),
            responseType: 'arraybuffer',
            url: `${selectedEnvironment.imageOptimization}${tmpUrl}`,
          });
        }
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else if (requestURL.includes('/seo/')) {
        if (
          requestURL.includes('add-existing-url`') ||
          requestURL.includes('keywords/upload-csv') ||
          requestURL.includes('backlinks-upload-csv')
        ) {
          let request = req.clone({
            headers: new HttpHeaders({
              Authorization: 'token:' + localStorage.getItem('auth_key'),
              Accept: 'application/json',
            }),
            setParams: {
              language: localStorage.getItem('language') || 'en',
              shopID: localStorage.getItem('selectedTarget') || '',
            },
            url: `${selectedEnvironment.seo}${requestURL.replace('/seo/', '')}`,
          });
          return next.handle(request).pipe(
            tap(
              (data) => {},
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error.status === 401) {
                    this.router.navigate(['login']);
                  }
                }
              }
            )
          );
        } else if (
          requestURL.includes('export-keyword-research') ||
          requestURL.includes('export-backlink-predictions')
        ) {
          let request = req.clone({
            headers: new HttpHeaders({
              Authorization: 'token:' + localStorage.getItem('auth_key'),
              Accept: 'text/csv',
            }),
            setParams: {
              language: localStorage.getItem('language') || 'en',
              shopID: localStorage.getItem('selectedTarget') || '',
            },
            url: `${selectedEnvironment.seo}${requestURL.replace('/seo/', '')}`,
            responseType: 'blob',
          });
          return next.handle(request).pipe(
            tap(
              (data) => {},
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error.status === 401) {
                    this.router.navigate(['login']);
                  }
                }
              }
            )
          );
        } else {
          let request = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'token:' + localStorage.getItem('auth_key'),
              Accept: 'application/json',
            }),
            setParams: {
              language: localStorage.getItem('language') || 'en',
              shopID: localStorage.getItem('selectedTarget') || '',
            },
            url: `${selectedEnvironment.seo}${requestURL.replace('/seo/', '')}`,
          });
          return next.handle(request).pipe(
            tap(
              (data) => {},
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error.status === 401) {
                    this.router.navigate(['login']);
                  }
                }
              }
            )
          );
        }
      } else if (requestURL.includes('/automations/create/')) {
        let request = req.clone({
          headers: new HttpHeaders({
            Authorization: 'token:' + localStorage.getItem('auth_key'),
          }),
          url: `${baseUrl}${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else if (
        requestURL.includes('/get_target_connections') ||
        requestURL.includes('/golive_process')
      ) {
        let request = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'token:' + localStorage.getItem('auth_key'),
            Accept: 'application/json',
          }),
          url: `https://golive.behamics.com${requestURL}`,
        });
        return next.handle(request).pipe(
          tap(
            (data) => {},
            (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          )
        );
      } else {
        if (req.responseType != 'json') {
          let request = req.clone({
            headers: new HttpHeaders({
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              Authorization: 'token:' + localStorage.getItem('auth_key'),
              Accept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            url: `${baseUrl}${requestURL}`,
            responseType: 'blob',
          });

          return next.handle(request).pipe(
            tap(
              (data) => {},
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error.status === 401) {
                    this.router.navigate(['login']);
                  }
                }
              }
            )
          );
        } else {
          // Email Server Configurations
          if (requestURL.includes('emails')) {
            const baseUrl = selectedEnvironment.emailserver;
          } else {
            if (requestURL.includes('/screenshots/upload')) {
              let request = req.clone({
                headers: new HttpHeaders({
                  Authorization: 'token:' + localStorage.getItem('auth_key'),
                }),
                url: `${baseUrl}${requestURL}`,
              });
              return next.handle(request).pipe(
                tap(
                  (data) => {},
                  (error) => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 401) {
                        this.router.navigate(['login']);
                      }
                    }
                  }
                )
              );
            } else if (requestURL.includes('/uploadCsvLeads')) {
              let request = req.clone({
                headers: new HttpHeaders({
                  Authorization: 'token:' + localStorage.getItem('auth_key'),
                  Accept: '*/*',
                }),

                url: `${baseUrl}${requestURL}`,
              });
              return next.handle(request).pipe(
                tap(
                  (data) => {},
                  (error) => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 401) {
                        this.router.navigate(['login']);
                      }
                    }
                  }
                )
              );
            } else if (requestURL.includes('/scriptAutomation')) {
              let tmpUrl = requestURL.replace('/scriptAutomation', '');
              let request = req.clone({
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'token:' + localStorage.getItem('auth_key'),
                  Accept: 'application/json',
                }),
                url: `${selectedEnvironment.autoscript}${tmpUrl}`,
              });
              return next.handle(request).pipe(
                tap(
                  (data) => {},
                  (error) => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 401) {
                        this.router.navigate(['login']);
                      }
                    }
                  }
                )
              );
            } else if (requestURL.includes('assets/i18n')) {
              let request = req.clone({
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'token:' + localStorage.getItem('auth_key'),
                  Accept: 'application/json',
                }),
                url: `${requestURL}`,
              });
              return next.handle(request).pipe(
                tap(
                  (data) => {},
                  (error) => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 401) {
                        this.router.navigate(['login']);
                      }
                    }
                  }
                )
              );
            } else {
              let request = req.clone({
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'token:' + localStorage.getItem('auth_key'),
                  Accept: 'application/json',
                }),
                url: `${baseUrl}${requestURL}`,
              });
              return next.handle(request).pipe(
                tap(
                  (data) => {},
                  (error) => {
                    if (error instanceof HttpErrorResponse) {
                      if (error.status === 401) {
                        this.router.navigate(['login']);
                      }
                    }
                  }
                )
              );
            }
          }
        }
      }
    }
    return next.handle(req);
  }
}
