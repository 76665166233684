import { Component, OnDestroy } from '@angular/core';
import { PerformanceService } from './performance.service';
import { HttpResponse } from '@angular/common/http';
import { SelectionService } from 'src/app/services/selection.service';
import { linechartColors } from '../../../utils/variables';
import { MixpanelService } from 'src/app/utils/mixpanel.service';
import { CurrencyFormatterPipe } from 'src/app/pipes/currency-formatter.pipe';
import { TimezoneService } from 'src/app/services/timezone.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css'],
  providers: [CurrencyFormatterPipe],
})
export class PerformanceComponent implements OnDestroy {
  constructor(
    private service: PerformanceService,
    private selectorService: SelectionService,
    private mixpanelService: MixpanelService,
    private currencyPipe: CurrencyFormatterPipe,
    private timezoneService: TimezoneService
  ) {}

  linechartsColors = linechartColors;
  sub: any;
  startDate: any;
  endDate: any;
  revUplift: number = 0;
  userEmail: any;
  dailyInfo: any = {};
  timeRangeSelected: any;
  selectedRangeValueLine: any;
  initialLoader = true;
  nudgeLite = false;

  showATRBarchart_targets: string[] = ['chewy_ca'];
  showATRBarchart: boolean = true;

  ngOnInit() {
    this.mixpanelService.track('Nudge Overview Page Viewed');

    this.previousSelectedStore = window.localStorage.getItem('selectedTarget');
    this.sub = this.selectorService.currentMessage.subscribe((message) => {
      this.selectedStore = message;
      this.startDate = window.localStorage.getItem('startDate');
      this.endDate = window.localStorage.getItem('endDate');
      this.chartLoader = false;
      this.initialLoader = true;
      this.loadNrIndicator = 0;

      this.selectedRangeValueLine = localStorage.getItem('rangeValue');

      // If component has KPI with rate filter
      if (this.selectedRangeValueLine == 'thisyear') {
        this.timeRangeSelected = 'this year';
      } else if (this.selectedRangeValueLine == 'thisweek') {
        this.timeRangeSelected = 'this week';
      } else if (this.selectedRangeValueLine == 'last7days') {
        this.timeRangeSelected = 'last 7 days';
      } else if (this.selectedRangeValueLine == 'last30days') {
        this.timeRangeSelected = 'last 30 days';
      } else if (this.selectedRangeValueLine == 'thismonth') {
        this.timeRangeSelected = 'this month';
      } else if (this.selectedRangeValueLine == 'custom') {
        this.timeRangeSelected =
          'from ' +
          this.formatDateRange(this.startDate) +
          ' - ' +
          this.formatDateRange(this.endDate);
      } else if (this.selectedRangeValueLine == 'today') {
        this.timeRangeSelected = 'today';
      } else if (this.selectedRangeValueLine == 'yestarday') {
        this.timeRangeSelected = 'yesterday';
      }

      function days_between(date1: any, date2: any) {
        var getDate1 =
          date1.split('T')[0].slice(5, 7) +
          '/' +
          date1.split('T')[0].slice(8, 10) +
          '/' +
          date1.split('T')[0].slice(0, 4);
        var getDate2 =
          date2.split('T')[0].slice(5, 7) +
          '/' +
          date2.split('T')[0].slice(8, 10) +
          '/' +
          date2.split('T')[0].slice(0, 4);

        const startDate = new Date(getDate1);
        const endDate = new Date(getDate2);
        const diffTime = Math.abs(endDate.valueOf() - startDate.valueOf());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      this.daysDifference = days_between(this.startDate, this.endDate);
      this.targetName = localStorage.getItem('targetName');

      this.previousSelectedStore =
        window.localStorage.getItem('selectedTarget');

      if (
        this.selectedStore == 'Volvo Cars' ||
        window.localStorage.getItem('selectedTarget') == 'volvocars'
      ) {
        this.volvoTestDrives();
      } else {
        this.isVolvoTestDrive = false;
        this.getChartInfo();
      }

      if (this.showATRBarchart_targets.includes(this.previousSelectedStore)) {
        this.showATRBarchart = true;
      } else {
        this.showATRBarchart = false;
      }
    });

    this.selectedOutlier = 'Default';
    this.incentiveBarChart.selectedPlatform = 'overall';
    this.incentiveBarChart.selectedFilter = 'overall';
    this.incentiveBarChart.selectedRangeValue = 'today';
    this.chartLoader = true;
    this.currentUserRole = window.localStorage.getItem('role');
    this.userEmail = window.localStorage.getItem('email');
  }

  formatDateRange(date: string) {
    const [isoDate] = date.split('@');
    let d = new Date(isoDate);

    const month = d.toLocaleString('en-US', { month: 'short' });
    const day = d.getDate();

    const suffix =
      day % 10 == 1 && day != 11
        ? 'st'
        : day % 10 == 2 && day != 12
        ? 'nd'
        : day % 10 == 3 && day != 13
        ? 'rd'
        : 'th';

    return `${month} ${day}${suffix}`;
  }

  // Update currency symbol
  displayCurrency() {
    var currency = window.localStorage.getItem('currency');

    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'AUD':
        return 'A$';
      case 'CHF':
        return 'CHF';
      case 'GBP':
        return '£';
      case 'CZK':
        return 'kc';
      case 'PLN':
        return 'zl';
      case 'SEK':
        return 'kr';
      case 'JPY':
        return '¥';
      case 'undefined':
        return '€';
    }

    return '$';
  }

  updateCurrencySymbol() {
    var currency = window.localStorage.getItem('currency');

    switch (currency) {
      case 'EUR':
        return "#,###'€'";
      case 'USD':
        return "'$'#,###";
      case 'CHF':
        return "#,###'CHF'";
      case 'GBP':
        return "'£'#,###";
      case 'CZK':
        return "'kc'#,###";
      case 'PLN':
        return "'zl'#,###";
      case 'SEK':
        return "#,###'kr'";
      case 'JPY':
        return "'¥'#,###";
    }

    return "'$'#,###";
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  compareYesterday(today: any, yesterday: any) {
    if (!today || !yesterday) return '';
    let todayPercentage = parseFloat(today.replace(/\s/g, ''));
    let yesterdayPercentage = parseFloat(yesterday.replace(/\s/g, ''));

    let difference = todayPercentage - yesterdayPercentage;
    return `${difference.toFixed()}%`;
  }

  firstLoader = false;
  chartLoader = true;
  loadNrIndicator = 0;

  receivedInfoCr: any;
  receivedInfoAtr: any;
  receivedInfoRpv: any;
  receivedInfoAov: any;
  receivedInfoRr: any;
  receivedInfoNrv: any;

  receivedInfoRrNull = false;
  receivedInfoNrNull = false;
  chartCrData: any;
  chartRpvData: any;
  chartAovData: any;
  chartRrData: any;
  chartNrData: any;
  daysDifference = 0;
  totalInfo = {
    total: {
      totalVisits: 0,
      totalOrders: 0,
      totalRevenue: '0',
    },
    share: {
      revenueShare: 0,
      trafficShare: 0,
      ordersShare: 0,
    },
    difference: {
      ordersIncentive: 0,
      ordersControl: 0,
      revenueControl: 0,
      revenueIncentive: 0,
      visitsIncentive: 0,
      visitsControl: 0,
      v: 0,
      vgc: 0,
      o: 0,
      ogc: 0,
    },
  };

  currentUserRole: any;
  selectedStore: any;
  previousSelectedStore: any;
  targetName: any;
  selected = 'overall';

  isVolvoTestDrive: boolean = false;
  testDriveData: any;

  selectedOutlier = 'Default';
  outliers = ['Default', 'Adjusted'];

  rawDataButtonDisabled: boolean = false;
  rawDataButtonTxt: string = 'Raw Data';

  public chartDataCr: any[] = [
    { data: [], label: 'Nudges Overall' },
    { data: [], label: 'Control' },
  ];

  barChartCr: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartCrAxis: any = [];

  barChartRpv: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartRpvAxis: any = [];

  barChartAov: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartAovAxis: any = [];

  barChartAtr: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartAtrAxis: any = [];

  barChartRR: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartRRAxis: any = [];

  barChartNR: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartNRAxis: any = [];

  incentiveBarChart = {
    ranges: [
      { value: 'custom', viewValue: 'Custom' },
      { value: 'today', viewValue: 'Today' },
      { value: 'yestarday', viewValue: 'Yesterday' },
      { value: 'thisweek', viewValue: 'This Week' },
      { value: 'last7days', viewValue: 'Last 7 days' },
      { value: 'last30days', viewValue: 'Last 30 days' },
      { value: 'thismonth', viewValue: 'This Month' },
      { value: 'thisyear', viewValue: 'This Year' },
    ],
    crorderAmount: {
      incentivesAmount: {
        visits: '',
        orders: '',
      },
      controlAmount: {
        visits: '',
        orders: '',
      },
    },
    platforms: ['All', 'Desktop', 'Tablet', 'Mobile'],
    selectedPlatform: 'overall',
    selectedRangeValue: 'today',
    selectedFilter: 'overall',

    rate: 'hourly',
    onRateChange: function (value: any) {
      if (
        this.selectedRangeValue == 'today' ||
        this.selectedRangeValue == 'yesterday'
      ) {
        this.rate = 'hourly';
      } else {
        this.rate = value.value;
      }
    },
    transactionsListService: null,
  };
  getChartInfo() {
    var device: any;
    if (this.incentiveBarChart.selectedPlatform == 'overall') {
      device = '';
    } else {
      device = this.incentiveBarChart.selectedPlatform;
    }
    var visitType: any;
    if (this.incentiveBarChart.selectedFilter == 'overall') {
      visitType = '';
    } else {
      visitType = this.incentiveBarChart.selectedFilter;
    }

    // Outlier Data
    var outliers: any;
    if (this.selectedOutlier == 'Default') {
      outliers = '';
    } else {
      outliers = true;
    }

    this.totalInfo = {
      total: {
        totalVisits: 0,
        totalOrders: 0,
        totalRevenue: '0',
      },
      share: {
        revenueShare: 0,
        trafficShare: 0,
        ordersShare: 0,
      },
      difference: {
        ordersIncentive: 0,
        ordersControl: 0,
        revenueControl: 0,
        revenueIncentive: 0,
        visitsIncentive: 0,
        visitsControl: 0,
        v: 0,
        vgc: 0,
        o: 0,
        ogc: 0,
      },
    };

    setTimeout(() => {
      this.startDate = window.localStorage.getItem('startDate');
      this.endDate = window.localStorage.getItem('endDate');

      var dateRange = {
        startDate: this.startDate,
        endDate: this.endDate,
        target: window.localStorage.getItem('selectedTarget'),
        device: device,
        visitType: visitType,
      };

      var dateRangeOutliers = {
        startDate: this.startDate,
        endDate: this.endDate,
        target: window.localStorage.getItem('selectedTarget'),
        device: device,
        visitType: visitType,
        noOutliers: outliers,
      };

      // if (localStorage.getItem('nudgeLite')) {
      //   this.nudgeLite = true;
      // } else {
      //   this.nudgeLite = false;
      // }
      if (localStorage.getItem('selectedTarget') === 'douglas') {
        this.nudgeLite = false;
      } else if (localStorage.getItem('nudgeLite')) {
        this.nudgeLite = true;
      } else {
        this.nudgeLite = false;
      }

      if (this.showATRBarchart) {
        this.getChartAtr(dateRange);
      }

      this.getChartCr(dateRange);
      this.getChartRpv(dateRangeOutliers);
      this.getChartRr(dateRange);
      this.getChartAov(dateRangeOutliers);
      this.getChartNr(dateRange);
    }, 400);
  }

  volvoTestDrives() {
    const data = {
      target: 'volvocars',
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.service.getVolvoTestDrives(data).subscribe({
      next: (res) => {
        this.testDriveData = res;
        // this.isVolvoTestDrive = true;
        this.getChartInfo();
      },
      error: (e) => {
        this.getChartInfo();
      },
    });
  }

  formatPercentage(val: any) {
    if (isNaN(val) || val == null || val == undefined) {
      return '0%';
    }
    return (val * 100).toFixed(0).toString() + '%';
  }

  generateEvenPoints(data: any, numPoints: any) {
    const validValues = data
      .map((item: any) => item.value)
      .filter(
        (value: any) => value !== null && value !== undefined && !isNaN(value)
      );

    if (validValues.length === 0) {
      console.warn('No valid values to calculate points.');
      return [];
    }

    const maxValue = Math.max(...validValues);
    const minValue = Math.min(...validValues);

    const points = [];

    if (maxValue <= 0) {
      const roundedMin = Math.floor(minValue / 2) * 2;
      const interval = Math.abs(roundedMin) / (numPoints - 1);

      for (let i = 0; i < numPoints; i++) {
        points.push((0 - interval * i).toFixed(0));
      }
    } else {
      const roundedMax = Math.ceil(maxValue / 2) * 2;
      const interval = roundedMax / (numPoints - 1);

      for (let i = 0; i < numPoints; i++) {
        points.push((interval * i).toFixed(0));
      }
    }

    return points;
  }

  setProportionalHeights(data: any, maxHeightPercentage: any) {
    const validValues = data
      .map((item: any) => item.value)
      .filter(
        (value: any) => value !== null && value !== undefined && !isNaN(value)
      );

    const allNegative = validValues.every((value: any) => value < 0);

    const maxValue = allNegative
      ? Math.min(...validValues)
      : Math.max(...validValues);

    data.forEach((item: any) => {
      if (item.value === 0 || item.value == 'NaN' || isNaN(item.value)) {
        item.height = 3;
        item.value = 0;
      } else {
        item.height = (item.value / maxValue) * maxHeightPercentage;
        item.height = Math.round(item.height * 100) / 100;
      }
    });

    return data;
  }

  getChartCr(dateRange: any) {
    this.service.getBarChartCr(dateRange).subscribe((info) => {
      this.receivedInfoCr = info;
      function formatNumber(num: any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      function formatPvalue(num: any) {
        if (num == null) {
          num = '';
        } else {
          num = num.toFixed(2);
          if (num <= 0.1) {
            num = num + ' (Significant)';
          } else {
            num = num + ' (Not Significant)';
          }
        }
        return num;
      }

      function increaseDecreaseDiff(
        incentive: any,
        control: any,
        noHTML: boolean = false
      ) {
        var diff = incentive / control - 1;
        diff = diff * 100;

        if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
          return '';
        }

        if (diff < 0) {
          if (noHTML) {
            return '' + diff.toFixed(2).replace('-', '- ') + '%';
          }
          return (
            "<span style='color: #458c30;'>" +
            diff.toFixed(2).replace('-', '- ') +
            '%</span>'
          );
        } else {
          if (noHTML) {
            return '+ ' + diff.toFixed(2) + '%';
          }
          return (
            "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
          );
        }
      }

      if (Object.keys(this.receivedInfoCr.difference).length) {
        this.totalInfo.total.totalVisits = formatNumber(
          this.receivedInfoCr.difference['v{}'] +
            this.receivedInfoCr.difference['v{gC=1}']
        );
        this.totalInfo.total.totalOrders = formatNumber(
          this.receivedInfoCr.difference['o{}'] +
            this.receivedInfoCr.difference['o{gC=1}']
        );

        if (
          this.receivedInfoCr.difference[
            `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
          ] != undefined
        ) {
          this.totalInfo.difference['ordersControl'] =
            this.receivedInfoCr.difference[
              `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
            ];
        } else {
          this.totalInfo.difference['ordersControl'] =
            this.receivedInfoCr.difference['o{gC=1,seenI=1}'];
        }

        if (
          this.receivedInfoCr.difference[
            `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
          ] != undefined
        ) {
          this.totalInfo.difference['ordersIncentive'] =
            this.receivedInfoCr.difference[
              `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
            ];
        } else {
          this.totalInfo.difference['ordersIncentive'] =
            this.receivedInfoCr.difference['o{seenI=1}'];
        }

        if (
          this.receivedInfoCr.difference[
            `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
          ] != undefined
        ) {
          this.totalInfo.difference.visitsIncentive =
            this.receivedInfoCr.difference[
              `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
            ];
        } else {
          this.totalInfo.difference.visitsIncentive =
            this.receivedInfoCr.difference['v{seenI=1}'];
        }
        if (
          this.receivedInfoCr.difference[
            `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
          ] != undefined
        ) {
          this.totalInfo.difference.visitsControl =
            this.receivedInfoCr.difference[
              `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
            ];
        } else {
          this.totalInfo.difference.visitsControl =
            this.receivedInfoCr.difference['v{gC=1,seenI=1}'];
        }

        this.totalInfo.difference['v'] = this.receivedInfoCr.difference['v{}'];
        this.totalInfo.difference['vgc'] =
          this.receivedInfoCr.difference['v{gC=1}'];

        this.totalInfo.difference['o'] = this.receivedInfoCr.difference['o{}'];
        this.totalInfo.difference['ogc'] =
          this.receivedInfoCr.difference['o{gC=1}'];
      }

      if (info.incentives == null) {
        info.incentives = 0;
      }
      if (info.control == null) {
        info.control = 0;
      }

      var tmpData = [];

      if (this.isVolvoTestDrive) {
        tmpData.push(
          (this.testDriveData.crIncentive * 100).toFixed(2),
          (this.testDriveData.crControl * 100).toFixed(2)
        );

        this.barChartCr[0].value = (
          this.testDriveData.crIncentive * 100
        ).toFixed(2);
        this.barChartCr[1].value = (this.testDriveData.crControl * 100).toFixed(
          2
        );

        this.barChartCr[0].diff = increaseDecreaseDiff(
          (this.testDriveData.crIncentive * 100).toFixed(2),
          (this.testDriveData.crControl * 100).toFixed(2),
          true
        );
      } else {
        tmpData.push(info.incentives, info.control);

        this.barChartCr[0].value = info.incentives;
        this.barChartCr[1].value = info.control;

        this.barChartCr[0].tooltip = formatPvalue(info.p);

        if (!this.nudgeLite) {
          this.barChartCr[0].diff = increaseDecreaseDiff(
            info.incentives,
            info.control,
            true
          );
        } else {
          if (info.incentives < 0) {
            this.barChartCr[0].diff = '- ' + info.incentives + '%';
          } else {
            this.barChartCr[0].diff = info.incentives + '%';
          }
        }
      }

      this.barChartCrAxis = this.generateEvenPoints(
        this.barChartCr,
        5
      ).reverse();

      this.barChartCr = this.setProportionalHeights(this.barChartCr, 90);

      if (this.isVolvoTestDrive) {
        this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
          formatNumber(this.testDriveData.matchedIncentive);

        this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
          formatNumber(this.testDriveData.bookedIncentive);

        this.incentiveBarChart.crorderAmount.controlAmount.visits =
          formatNumber(this.testDriveData.matchedControl);

        this.incentiveBarChart.crorderAmount.controlAmount.orders =
          formatNumber(this.testDriveData.bookedControl);
      } else {
        if (info.incentivesInfo.visits != null) {
          this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
            formatNumber(info.incentivesInfo.visits);
        } else {
          this.incentiveBarChart.crorderAmount.incentivesAmount.visits = '0';
        }
        if (info.incentivesInfo.orders != null) {
          this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
            formatNumber(info.incentivesInfo.orders);
        } else {
          this.incentiveBarChart.crorderAmount.incentivesAmount.orders = '0';
        }
        if (info.controlInfo.visits != null) {
          this.incentiveBarChart.crorderAmount.controlAmount.visits =
            formatNumber(info.controlInfo.visits);
        } else {
          this.incentiveBarChart.crorderAmount.controlAmount.visits = '0';
        }
        if (info.controlInfo.orders != null) {
          this.incentiveBarChart.crorderAmount.controlAmount.orders =
            formatNumber(info.controlInfo.orders);
        } else {
          this.incentiveBarChart.crorderAmount.controlAmount.orders = '0';
        }
      }

      this.combinedLoader(1);
    });
  }

  getChartAtr(dateRange: any) {
    this.service.getBarChartAtc(dateRange).subscribe((info) => {
      this.receivedInfoAtr = info;
      function formatNumber(num: any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      function formatPvalue(num: any) {
        if (num == null) {
          num = '';
        } else {
          num = num.toFixed(2);
          if (num <= 0.1) {
            num = num + ' (Significant)';
          } else {
            num = num + ' (Not Significant)';
          }
        }
        return num;
      }

      function increaseDecreaseDiff(
        incentive: any,
        control: any,
        noHTML: boolean = false
      ) {
        var diff = incentive / control - 1;
        diff = diff * 100;

        if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
          return '';
        }

        if (diff < 0) {
          if (noHTML) {
            return '' + diff.toFixed(2).replace('-', '- ') + '%';
          }
          return (
            "<span style='color: #458c30;'>" +
            diff.toFixed(2).replace('-', '- ') +
            '%</span>'
          );
        } else {
          if (noHTML) {
            return '+ ' + diff.toFixed(2) + '%';
          }
          return (
            "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
          );
        }
      }

      // if (Object.keys(this.receivedInfoAtr.difference).length) {
      //   this.totalInfo.total.totalVisits = formatNumber(
      //     this.receivedInfoAtr.difference['v{}'] +
      //       this.receivedInfoAtr.difference['v{gC=1}']
      //   );
      //   this.totalInfo.total.totalOrders = formatNumber(
      //     this.receivedInfoAtr.difference['o{}'] +
      //       this.receivedInfoAtr.difference['o{gC=1}']
      //   );

      //   if (
      //     this.receivedInfoAtr.difference[
      //       `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
      //     ] != undefined
      //   ) {
      //     this.totalInfo.difference['ordersControl'] =
      //       this.receivedInfoAtr.difference[
      //         `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
      //       ];
      //   } else {
      //     this.totalInfo.difference['ordersControl'] =
      //       this.receivedInfoAtr.difference['o{gC=1,seenI=1}'];
      //   }

      //   if (
      //     this.receivedInfoAtr.difference[
      //       `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
      //     ] != undefined
      //   ) {
      //     this.totalInfo.difference['ordersIncentive'] =
      //       this.receivedInfoAtr.difference[
      //         `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
      //       ];
      //   } else {
      //     this.totalInfo.difference['ordersIncentive'] =
      //       this.receivedInfoAtr.difference['o{seenI=1}'];
      //   }

      //   if (
      //     this.receivedInfoAtr.difference[
      //       `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
      //     ] != undefined
      //   ) {
      //     this.totalInfo.difference.visitsIncentive =
      //       this.receivedInfoAtr.difference[
      //         `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
      //       ];
      //   } else {
      //     this.totalInfo.difference.visitsIncentive =
      //       this.receivedInfoAtr.difference['v{seenI=1}'];
      //   }
      //   if (
      //     this.receivedInfoAtr.difference[
      //       `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
      //     ] != undefined
      //   ) {
      //     this.totalInfo.difference.visitsControl =
      //       this.receivedInfoAtr.difference[
      //         `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
      //       ];
      //   } else {
      //     this.totalInfo.difference.visitsControl =
      //       this.receivedInfoAtr.difference['v{gC=1,seenI=1}'];
      //   }

      //   this.totalInfo.difference['v'] = this.receivedInfoAtr.difference['v{}'];
      //   this.totalInfo.difference['vgc'] =
      //     this.receivedInfoAtr.difference['v{gC=1}'];

      //   this.totalInfo.difference['o'] = this.receivedInfoAtr.difference['o{}'];
      //   this.totalInfo.difference['ogc'] =
      //     this.receivedInfoAtr.difference['o{gC=1}'];
      // }

      if (info.incentives == null) {
        info.incentives = 0;
      }
      if (info.control == null) {
        info.control = 0;
      }

      var tmpData = [];

      tmpData.push(info.incentives, info.control);

      this.barChartAtr[0].value = info.incentives;
      this.barChartAtr[1].value = info.control;

      this.barChartAtr[0].tooltip = formatPvalue(info.p);

      if (!this.nudgeLite) {
        this.barChartAtr[0].diff = increaseDecreaseDiff(
          info.incentives,
          info.control,
          true
        );
      } else {
        if (info.incentives < 0) {
          this.barChartAtr[0].diff = '- ' + info.incentives + '%';
        } else {
          this.barChartAtr[0].diff = info.incentives + '%';
        }
      }

      this.barChartAtrAxis = this.generateEvenPoints(
        this.barChartAtr,
        5
      ).reverse();

      this.barChartAtr = this.setProportionalHeights(this.barChartAtr, 90);

      // if (info.incentivesInfo.visits != null) {
      //   this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
      //     formatNumber(info.incentivesInfo.visits);
      // } else {
      //   this.incentiveBarChart.crorderAmount.incentivesAmount.visits = '0';
      // }
      // if (info.incentivesInfo.orders != null) {
      //   this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
      //     formatNumber(info.incentivesInfo.orders);
      // } else {
      //   this.incentiveBarChart.crorderAmount.incentivesAmount.orders = '0';
      // }
      // if (info.controlInfo.visits != null) {
      //   this.incentiveBarChart.crorderAmount.controlAmount.visits =
      //     formatNumber(info.controlInfo.visits);
      // } else {
      //   this.incentiveBarChart.crorderAmount.controlAmount.visits = '0';
      // }
      // if (info.controlInfo.orders != null) {
      //   this.incentiveBarChart.crorderAmount.controlAmount.orders =
      //     formatNumber(info.controlInfo.orders);
      // } else {
      //   this.incentiveBarChart.crorderAmount.controlAmount.orders = '0';
      // }

      // this.combinedLoader(1);
    });
  }

  calcUpliftRaw(inc: any, con: any, visits: any): number {
    var res = (inc - con) * visits;
    return res;
  }

  calcUplift(inc: any, con: any, visits: any) {
    var res = (inc - con) * visits;
    var currencySymbol = this.displayCurrency();

    var symbolAtEnd = ['€', 'CHF', 'kc'];
    var appendSymbol = symbolAtEnd.includes(currencySymbol);
    var formattedNumber;
    if (res < 100) {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    var sign = res < 0 ? '- ' : res > 0 ? '+ ' : '';

    if (appendSymbol) {
      return sign + formattedNumber + ' ' + currencySymbol;
    } else {
      return sign + currencySymbol + formattedNumber;
    }
  }
  formatNumber(num: any) {
    if (!num || num == 0) return 0;

    num = parseFloat(num.toFixed(2));

    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  displayAxisValSymbol(res: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    var currencySymbol = this.displayCurrency();

    var symbolAtEnd = ['€', 'CHF', 'kc'];
    var appendSymbol = symbolAtEnd.includes(currencySymbol);

    if (isNaN(res) || res == 'NaN') res = 0;

    if (appendSymbol) {
      return formatNumber(res) + currencySymbol;
    } else {
      return currencySymbol + formatNumber(res);
    }
  }

  getChartRpv(dateRange: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatPvalue(num: any) {
      if (num == null) {
        num = '';
      } else {
        num = num.toFixed(2);
        if (num <= 0.1) {
          num = num + ' (Significant)';
        } else {
          num = num + ' (Not Significant)';
        }
      }
      return num;
    }

    function increaseDecreaseDiff(
      incentive: any,
      control: any,
      noHTML: boolean = false
    ) {
      var diff = incentive / control - 1;
      diff = diff * 100;

      if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
        return '';
      }

      if (diff < 0) {
        if (noHTML) {
          return '' + diff.toFixed(2).replace('-', '- ') + '%';
        }
        return (
          "<span style='color: #458c30;'>" +
          diff.toFixed(2).replace('-', '- ') +
          '%</span>'
        );
      } else {
        if (noHTML) {
          return '+ ' + diff.toFixed(2) + '%';
        }
        return (
          "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
        );
      }
    }

    function isDifferenceThreeMonthsOrMore(startDateStr: any, endDateStr: any) {
      const startDateFixed = startDateStr.split('@')[0];
      const endDateFixed = endDateStr.split('@')[0];

      const startDate = new Date(startDateFixed);
      const endDate = new Date(endDateFixed);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error('Invalid date format');
      }

      const monthsDifference =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        endDate.getMonth() -
        startDate.getMonth();

      return monthsDifference >= 3;
    }

    if (isDifferenceThreeMonthsOrMore(dateRange.startDate, dateRange.endDate)) {
      dateRange.moreThanThreeMonths = true;

      const startDateString = dateRange.startDate;
      const endDateString = dateRange.endDate;

      const timeZone = this.timezoneService.getTimezone();
      const startDateUTC = new Date(startDateString.split('@')[0]);
      const endDateUTC = new Date(endDateString.split('@')[0]);
      const startDateLocal = convertUTCToLocalDate(startDateUTC, timeZone);
      const endDateLocal = convertUTCToLocalDate(endDateUTC, timeZone);

      let sequences = [];

      let currentDateLocal = new Date(
        startDateLocal.getFullYear(),
        startDateLocal.getMonth(),
        1
      );

      while (currentDateLocal <= endDateLocal) {
        let monthName = currentDateLocal.toLocaleString('default', {
          month: 'long',
        });

        let startOfMonthLocal = new Date(
          currentDateLocal.getFullYear(),
          currentDateLocal.getMonth(),
          1,
          0,
          0
        );
        let endOfMonthLocal = new Date(
          currentDateLocal.getFullYear(),
          currentDateLocal.getMonth() + 1,
          0,
          23,
          59,
          59
        );

        let startDateForMonthLocal =
          currentDateLocal.getMonth() === startDateLocal.getMonth() &&
          currentDateLocal.getFullYear() === startDateLocal.getFullYear()
            ? startDateLocal
            : startOfMonthLocal;

        let endDateForMonthLocal =
          currentDateLocal.getMonth() === endDateLocal.getMonth() &&
          currentDateLocal.getFullYear() === endDateLocal.getFullYear()
            ? endDateLocal
            : endOfMonthLocal;

        let startDateForMonthUTC = convertLocalToUTCDate(
          startDateForMonthLocal,
          timeZone
        );

        let endDateForMonthUTC = convertLocalToUTCDate(
          endDateForMonthLocal,
          timeZone
        );

        const adjustedStartDateForMonthUTC = new Date(
          startDateForMonthUTC.getTime() + 2 * 60 * 60 * 1000
        );
        const adjustedEndDateForMonthUTC = new Date(
          endDateForMonthUTC.getTime() + 2 * 60 * 60 * 1000
        );

        let formattedStartDate =
          adjustedStartDateForMonthUTC.toISOString() + '@' + timeZone;
        let formattedEndDate =
          adjustedEndDateForMonthUTC.toISOString() + '@' + timeZone;

        if (endDateForMonthLocal < startDateLocal) {
          currentDateLocal.setMonth(currentDateLocal.getMonth() + 1);
          continue;
        }

        sequences.push({
          month: monthName,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });

        currentDateLocal.setMonth(currentDateLocal.getMonth() + 1);
      }

      function convertUTCToLocalDate(dateUTC: Date, timeZone: string): Date {
        const dateString = dateUTC.toLocaleString('en-US', { timeZone });
        return new Date(dateString);
      }

      function convertLocalToUTCDate(dateLocal: Date, timeZone: string): Date {
        const dateString = dateLocal.toLocaleString('en-US', {
          timeZone: 'UTC',
        });
        return new Date(dateString);
      }

      dateRange.sequences = JSON.stringify(sequences);
    }

    this.service.getBarChartRpv(dateRange).subscribe((info) => {
      {
        this.receivedInfoRpv = info;

        if (info?.incentives == null && info?.control == null) {
          info.incentives = 0;
          info.control = 0;
        } else {
          info.incentives =
            info.incentivesInfo['revenue'] /
            Number(info.incentivesInfo['visits']);

          info.control =
            info.controlInfo['revenue'] / Number(info.controlInfo['visits']);

          info.incentives = Number(info.incentives.toFixed(2));
          info.control = Number(info.control.toFixed(2));
        }

        var incRev: any;
        var cntRev: any;

        if (this.isVolvoTestDrive) {
          var revenueInc = this.testDriveData.bookedIncentive * 9000;
          var revenueCon = this.testDriveData.bookedControl * 9000;
          incRev = revenueInc / this.testDriveData.matchedIncentive;
          cntRev = revenueCon / this.testDriveData.matchedControl;
          var infVisits = this.testDriveData.matchedIncentive;
        } else {
          incRev = info.incentives;
          cntRev = info.control;
          var infVisits = info.incentivesInfo['visits'];
        }

        if (!this.nudgeLite) {
          this.revUplift = this.calcUpliftRaw(
            isNaN(incRev) ? 0 : incRev,
            isNaN(cntRev) ? 0 : cntRev,
            isNaN(infVisits) ? 0 : infVisits
          );
        } else {
          this.revUplift = info.incentivesInfo['revenue'];
          // var currencySymbol = this.displayCurrency();

          // var res = info.incentivesInfo['revenue'];

          // var symbolAtEnd = ['€', 'CHF', 'kc'];
          // var appendSymbol = symbolAtEnd.includes(currencySymbol);
          // var formattedNumber;
          // if (res < 100) {
          //   formattedNumber = Math.abs(res).toLocaleString('en-US', {
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2,
          //   });
          // } else {
          //   formattedNumber = Math.abs(res).toLocaleString('en-US', {
          //     minimumFractionDigits: 0,
          //     maximumFractionDigits: 0,
          //   });
          // }
          // var sign = res < 0 ? '- ' : res > 0 ? '+ ' : '';

          // if (appendSymbol) {
          //   this.revUplift = sign + formattedNumber + ' ' + currencySymbol;
          // } else {
          //   this.revUplift = sign + currencySymbol + formattedNumber;
          // }
        }

        if (info.sumRevenueUpliftCount) {
          this.revUplift = info.sumRevenueUpliftCount;
          // var res = info.sumRevenueUpliftCount;
          // var currencySymbol = this.displayCurrency();

          // var symbolAtEnd = ['€', 'CHF', 'kc'];
          // var appendSymbol = symbolAtEnd.includes(currencySymbol);
          // var formattedNumber;
          // if (res < 100) {
          //   formattedNumber = Math.abs(res).toLocaleString('en-US', {
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2,
          //   });
          // } else {
          //   formattedNumber = Math.abs(res).toLocaleString('en-US', {
          //     minimumFractionDigits: 0,
          //     maximumFractionDigits: 0,
          //   });
          // }
          // var sign = res < 0 ? '- ' : res > 0 ? '+ ' : '';

          // if (appendSymbol) {
          //   this.revUplift = sign + formattedNumber + ' ' + currencySymbol;
          // } else {
          //   this.revUplift = sign + currencySymbol + formattedNumber;
          // }
        }

        setTimeout(() => {
          if (Object.keys(this.receivedInfoRpv.difference).length) {
            this.totalInfo.total.totalRevenue = formatNumber(
              (
                this.receivedInfoRpv.difference['rev{}'] +
                this.receivedInfoRpv.difference['rev{gC=1}']
              ).toFixed(2)
            );

            this.totalInfo.difference.revenueIncentive =
              this.receivedInfoRpv.difference['rev{}'];

            this.totalInfo.difference.revenueControl =
              this.receivedInfoRpv.difference['rev{gC=1}'];

            this.totalInfo.share.revenueShare =
              this.calcUpliftRaw(incRev, cntRev, infVisits) /
              (this.receivedInfoRpv.difference['rev{}'] +
                this.receivedInfoRpv.difference['rev{gC=1}']);

            this.totalInfo.share.trafficShare =
              (this.totalInfo.difference.visitsIncentive +
                this.totalInfo.difference.visitsControl) /
              (this.totalInfo.difference.v + this.totalInfo.difference.vgc);

            this.totalInfo.share.ordersShare =
              (this.totalInfo.difference.ordersIncentive +
                this.totalInfo.difference.ordersControl) /
              (this.totalInfo.difference.o + this.totalInfo.difference.ogc);
          }
        }, 200);

        var tmpData = [];

        if (this.isVolvoTestDrive) {
          var revenueInc = this.testDriveData.bookedIncentive * 9000;
          var revenueCon = this.testDriveData.bookedControl * 9000;

          tmpData.push(
            (revenueInc / this.testDriveData.matchedIncentive).toFixed(2),
            (revenueCon / this.testDriveData.matchedControl).toFixed(2)
          );

          this.barChartRpv[0].value = (
            revenueInc / this.testDriveData.matchedIncentive
          ).toFixed(2);
          this.barChartRpv[1].value = (
            revenueCon / this.testDriveData.matchedControl
          ).toFixed(2);
          this.barChartRpv[0].diff = increaseDecreaseDiff(
            (revenueInc / this.testDriveData.matchedIncentive).toFixed(2),
            (revenueCon / this.testDriveData.matchedControl).toFixed(2),
            true
          );
        } else {
          tmpData.push(info.incentives, info.control);

          this.barChartRpv[0].value = info.incentives;
          this.barChartRpv[1].value = info.control;

          this.barChartRpv[0].tooltip = formatPvalue(info.p);

          if (!this.nudgeLite) {
            this.barChartRpv[0].diff = increaseDecreaseDiff(
              info.incentives,
              info.control,
              true
            );
          } else {
            // if (info.incentives < 0) {
            //   this.barChartRpv[0].diff =
            //   '- ' + info.incentives + this.displayCurrency();
            // } else {
            //   this.barChartRpv[0].diff =
            //   info.incentives + this.displayCurrency();
            // }
            this.barChartRpv[0].diff = this.formatCurrencyNumber(
              info.incentives
            );
          }
        }

        this.barChartRpvAxis = this.generateEvenPoints(
          this.barChartRpv,
          5
        ).reverse();

        this.barChartRpv = this.setProportionalHeights(this.barChartRpv, 90);
        this.combinedLoader(1);
      }
    });
  }

  checkUpliftColor(data: any) {
    if (data < 0) {
      return false;
    } else if (data > 0) {
      return true;
    } else {
      return 0;
    }
  }

  isNaN(value: any): boolean {
    return isNaN(parseFloat(value));
  }

  safeSum(...values: any[]): number {
    return values.reduce(
      (acc, val) => acc + (this.isNaN(val) ? 0 : parseFloat(val)),
      0
    );
  }

  getFormattedValue(value: any): string {
    return this.isNaN(value) ? '0.00' : parseFloat(value).toFixed(2);
  }

  getTrafficShare(): string {
    const trafficShare = this.totalInfo.share.trafficShare;
    return this.getFormattedValue(trafficShare);
  }

  getOrdersShare(): string {
    const ordersShare = this.totalInfo.share.ordersShare;
    return this.getFormattedValue(ordersShare);
  }

  isValidDiff(diff: any): boolean {
    if (typeof diff === 'string' && diff.includes('NaN')) {
      return false;
    }
    const numericValue = parseFloat(diff);
    return !isNaN(numericValue) && numericValue > 0;
  }

  getChartRr(dateRange: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatPvalue(num: any) {
      if (num == null) {
        num = '';
      } else {
        num = num.toFixed(2);
        if (num <= 0.1) {
          num = num + ' (Significant)';
        } else {
          num = num + ' (Not Significant)';
        }
      }
      return num;
    }

    function increaseDecreaseDiff(
      incentive: any,
      control: any,
      noHTML: boolean = false
    ) {
      var diff = incentive / control - 1;
      diff = diff * 100;

      if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
        return '';
      }

      if (diff < 0) {
        if (noHTML) {
          return '' + diff.toFixed(2).replace('-', '- ') + '%';
        }
        return (
          "<span style='color: #458c30;'>" +
          diff.toFixed(2).replace('-', '- ') +
          '%</span>'
        );
      } else {
        if (noHTML) {
          return '+ ' + diff.toFixed(2) + '%';
        }
        return (
          "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
        );
      }
    }

    this.service.getBarChartRr(dateRange).subscribe((info) => {
      this.receivedInfoRr = info;
      if (!info) {
        this.receivedInfoRrNull = true;
        this.combinedLoader(1);
        return;
      }

      if (info?.incentives == null && info?.control == null) {
        this.receivedInfoRrNull = true;
        info.incentives = 0;
        info.control = 0;
      } else {
        this.receivedInfoRrNull = false;
      }
      if (info.incentives == null) {
        info.incentives = 0;
      }
      if (info.control == null) {
        info.control = 0;
      }
      var tmpData = [];
      tmpData.push(info.incentives, info.control);

      this.barChartRR[0].value = info.incentives;
      this.barChartRR[1].value = info.control;
      this.barChartRR[0].diff = increaseDecreaseDiff(
        info.incentives,
        info.control,
        true
      );
      this.barChartRR[0].tooltip = formatPvalue(info.p);

      this.barChartRRAxis = this.generateEvenPoints(
        this.barChartRR,
        5
      ).reverse();

      this.barChartRR = this.setProportionalHeights(this.barChartRR, 90);

      this.combinedLoader(1);
    });
  }
  getChartAov(dateRange: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatPvalue(num: any) {
      if (num == null) {
        num = '';
      } else {
        num = num.toFixed(2);
        if (num <= 0.1) {
          num = num + ' (Significant)';
        } else {
          num = num + ' (Not Significant)';
        }
      }
      return num;
    }
    function increaseDecreaseDiff(
      incentive: any,
      control: any,
      noHTML: boolean = false
    ) {
      var diff = incentive / control - 1;
      diff = diff * 100;

      if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
        return '';
      }

      if (diff < 0) {
        if (noHTML) {
          return '' + diff.toFixed(2).replace('-', '- ') + '%';
        }
        return (
          "<span style='color: #458c30;'>" +
          diff.toFixed(2).replace('-', '- ') +
          '%</span>'
        );
      } else {
        if (noHTML) {
          return '+ ' + diff.toFixed(2) + '%';
        }
        return (
          "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
        );
      }
    }

    this.service.getBarChartAov(dateRange).subscribe((info) => {
      this.receivedInfoAov = info;
      if (info.incentives == null && info.control == null) {
        info.incentives = 0;
        info.control = 0;
      } else {
        info.incentives =
          info.incentivesInfo['revenue'] /
          Number(info.incentivesInfo['orders']);

        info.control =
          info.controlInfo['revenue'] / Number(info.controlInfo['orders']);

        info.incentives = Number(info.incentives.toFixed(2));
        info.control = Number(info.control.toFixed(2));
      }

      if (isNaN(info.incentives)) info.incentives = 0;
      if (isNaN(info.control)) info.control = 0;

      var tmpData = [];

      var incRev: any;
      var cntRev: any;

      if (this.isVolvoTestDrive) {
        var revenueInc = this.testDriveData.bookedIncentive * 9000;
        var revenueCon = this.testDriveData.bookedControl * 9000;
        incRev = revenueInc / this.testDriveData.bookedIncentive;
        cntRev = revenueCon / this.testDriveData.bookedControl;

        tmpData.push(incRev, cntRev);

        this.barChartAov[0].value = incRev;
        this.barChartAov[1].value = cntRev;
        this.barChartAov[0].diff = increaseDecreaseDiff(incRev, cntRev, true);
      } else {
        tmpData.push(info.incentives, info.control);

        this.barChartAov[0].value = info.incentives;
        this.barChartAov[1].value = info.control;

        this.barChartAov[0].tooltip = formatPvalue(info.p);

        if (!this.nudgeLite) {
          this.barChartAov[0].diff = increaseDecreaseDiff(
            info.incentives,
            info.control,
            true
          );
        } else {
          // if (info.incentives < 0) {
          //   this.barChartAov[0].diff =
          //     '- ' + info.incentives + this.displayCurrency();
          // } else {
          //   this.barChartAov[0].diff = info.incentives + this.displayCurrency();
          // }
          this.barChartAov[0].diff = this.formatCurrencyNumber(info.incentives);
        }
      }

      this.barChartAovAxis = this.generateEvenPoints(
        this.barChartAov,
        5
      ).reverse();

      this.barChartAov = this.setProportionalHeights(this.barChartAov, 90);

      this.combinedLoader(1);
    });
  }
  getChartNr(dateRange: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatPvalue(num: any) {
      if (num == null) {
        num = '';
      } else {
        num = num.toFixed(2);
        if (num <= 0.1) {
          num = num + ' (Significant)';
        } else {
          num = num + ' (Not Significant)';
        }
      }
      return num;
    }
    function increaseDecreaseDiff(
      incentive: any,
      control: any,
      noHTML: boolean = false
    ) {
      var diff = incentive / control - 1;
      diff = diff * 100;
      if (diff != Infinity) {
        if (diff < 0) {
          if (noHTML) {
            return '' + diff.toFixed(2).replace('-', '- ') + '%';
          }
          return (
            "<span style='color: #ff9292ed;'>" +
            diff.toFixed(2).replace('-', '- ') +
            '%</span>'
          );
        } else {
          if (noHTML) {
            return '+ ' + diff.toFixed(2) + '%';
          }
          return (
            "<span style='color: #458c30;'>+ " + diff.toFixed(2) + '%</span>'
          );
        }
      } else {
        return '';
      }
    }
    this.service.getBarChartNsv(dateRange).subscribe((info) => {
      this.receivedInfoNrv = info;

      if (info != null) {
        if (info.incentives == null && info.control == null) {
          this.receivedInfoNrNull = true;
          info.incentives = 0;
          info.control = 0;
        } else {
          this.receivedInfoNrNull = false;
        }

        if (info.incentives == null) {
          info.incentives = 0;
        }
        if (info.control == null) {
          info.control = 0;
        }
        var tmpData = [];
        tmpData.push(info.incentives, info.control);

        this.barChartNR[0].value = info.incentives;
        this.barChartNR[1].value = info.control;
        this.barChartNR[0].diff = increaseDecreaseDiff(
          info.incentives,
          info.control,
          true
        );
        this.barChartNR[0].tooltip = formatPvalue(info.p);

        this.barChartNRAxis = this.generateEvenPoints(
          this.barChartNR,
          5
        ).reverse();

        this.barChartNR = this.setProportionalHeights(this.barChartNR, 90);

        this.combinedLoader(1);
      } else {
        this.receivedInfoNrNull = true;
        this.combinedLoader(1);
      }
    });
  }

  platformChanged(event: any) {
    this.chartLoader = false;
    this.loadNrIndicator = 0;
    this.getChartInfo();
  }

  selectionDeviceChanged(event: any) {
    this.incentiveBarChart.selectedPlatform = event.value;
    this.chartLoader = false;
    this.loadNrIndicator = 0;
    this.getChartInfo();
  }

  filterVisits(event: any) {
    this.incentiveBarChart.selectedFilter = event.value;
    this.chartLoader = false;
    this.loadNrIndicator = 0;
    this.getChartInfo();
  }

  onOutlierChange(value: any) {
    this.chartLoader = false;
    this.loadNrIndicator = 0;
    this.getChartInfo();
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  combinedLoader(num: any) {
    this.loadNrIndicator = this.loadNrIndicator + num;
    if (this.loadNrIndicator == 5) {
      setTimeout(() => {
        this.firstLoader = true;
        this.chartLoader = true;
        this.initialLoader = false;
      }, 400);
    }
  }

  generateReport(type: string) {
    this.chartLoader = false;
    var device;
    if (type == 'report') {
      device = 'all';
    } else {
      if (this.incentiveBarChart.selectedPlatform == 'overall') {
        device = '';
      } else {
        device = this.incentiveBarChart.selectedPlatform;
      }
    }
    var visitType;
    if (this.incentiveBarChart.selectedFilter == 'overall') {
      visitType = '';
    } else {
      visitType = this.incentiveBarChart.selectedFilter;
    }

    // Outlier Data
    var outliers;
    if (this.selectedOutlier == 'Default') {
      outliers = false;
    } else {
      outliers = true;
    }

    var dateRange = {
      startDate: this.startDate,
      endDate: this.endDate,
      target: window.localStorage.getItem('selectedTarget'),
      device: device,
      visitType: visitType,
      noOutliers: outliers,
      reportType: 'Overall',
    };

    this.service
      .downloadOverallReport(dateRange)
      .subscribe((response: HttpResponse<Blob>) => {
        this.chartLoader = true;
        let filename = this.targetName;
        if (type == 'report') {
          filename += ' Overall Report.xlsx';
        } else {
          filename += ' Overall Monthly Report.xlsx';
        }
        let binaryData: any[] = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'blob' })
        );
        downloadLink.setAttribute('download', filename);
        downloadLink.click();
      });
  }

  generateRawData() {
    function extractNumbersFromString(str: any) {
      const numericString = str.match(/[0-9.]+/g)?.join('') || '';
      return parseFloat(numericString) || 0;
    }
    const dowloadFunc = (link: string) => {
      let target = localStorage.getItem('selectedTarget');
      let filename = this.targetName + ' Report';
      let binaryData: any[] = [];
      binaryData.push(link);
      let downloadLink = document.createElement('a');
      downloadLink.href = `https://behamicsfront.blob.core.windows.net/rawdatacsv/${target}/${link}`;
      window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      downloadLink.click();
    };
    let params = `target=${localStorage.getItem('selectedTarget')}`;

    let device = 'all';
    if (this.incentiveBarChart.selectedPlatform !== 'overall') {
      device = this.incentiveBarChart.selectedPlatform;
    }

    let incV = extractNumbersFromString(
      this.incentiveBarChart.crorderAmount.incentivesAmount.visits
    );
    let incG = extractNumbersFromString(
      this.incentiveBarChart.crorderAmount.controlAmount.visits
    );

    params += `&startDate=${this.startDate}&endDate=${this.endDate}&device=${device}&incV=${incV}&incG=${incG}`;

    this.rawDataButtonTxt = 'Processing..';
    this.rawDataButtonDisabled = true;
    this.service.downloadRawData(params).subscribe(
      (response) => {
        setTimeout(() => {
          this.rawDataButtonTxt = 'Raw Data';
          this.rawDataButtonDisabled = false;
        }, 500);

        if (response) {
          dowloadFunc(response.fileName);
        }
      },
      (error) => {
        this.rawDataButtonTxt = 'Raw Data';
        this.rawDataButtonDisabled = false;

        if (error?.error?.text) {
          dowloadFunc(error?.error?.text);
        }
      }
    );
  }
  formatCurrencyNumber(value: number, showPlusSign: boolean = false) {
    return this.currencyPipe.transform(value, showPlusSign);
  }
}
