import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private timezone: string =
    sessionStorage.getItem('selectedTimezone') ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  setTimezone(t: string): void {
    sessionStorage.setItem('selectedTimezone', t);
    this.timezone = t;
  }
  getTimezone(): string {
    return this.timezone;
  }
}
