import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTitleService } from '../services/pagetitle.service';
import { MixpanelService } from '../utils/mixpanel.service';
import { SelectionService } from '../services/selection.service';
import { Subscription } from 'rxjs';
import { PerformanceService } from '../nudge/performance/performance/performance.service';
import { DiagnosticsService } from '../diagnostics/diagnostics.service';
import { listOfNames2 } from '../diagnostics/overall//listOfNames';
import { Router } from '@angular/router';
import { getPagetypeTagsColors } from '../utils/variables';
import { DashboardService } from './dashboard.service';
import { TimezoneService } from '../services/timezone.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    '../nudge/performance/performance/performance.component.css',
    '../diagnostics/overall/overall.component.css',
    '../seo/ai-analysis/custom-barchart/custom-barchart.component.css',
    './dashboard.component.css',
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private dashboardService: DashboardService,
    private pageTitle: PageTitleService,
    private mixpanelService: MixpanelService,
    private selectorService: SelectionService,
    private performanceService: PerformanceService,
    private bugTrackingService: DiagnosticsService,
    private router: Router,
    private timezoneService: TimezoneService
  ) {}
  // This is to show the new dashboard page or the old one
  // no needed when we push the new dashboard page to prod
  showNewDashboardView: boolean = true;

  dashboardSub: Subscription = new Subscription();

  // Privileges
  nudgePrivilege: boolean = false;
  diagnosticsPrivilege: boolean = false;
  merchandisePrivilege: boolean = false;
  organicPrivilege: boolean = false;
  automationsPrivilege: boolean = false;

  nudgeDataFirstLoad: boolean = true;
  nudgeDataLoading: boolean = true;

  diagnosticsDataFirstLoad: boolean = true;
  diagnosticsDataLoading: boolean = true;

  merchandiseDataFirstLoad: boolean = true;
  merchandiseDataLoading: boolean = true;

  organicDataFirstLoad: boolean = true;
  organicDataLoading: boolean = true;

  automationsDataFirstLoad: boolean = true;
  automationsDataLoading: boolean = true;

  rate: any = 'hourly';

  timeRangeSelected: any;
  selectedStore: any;
  selectedTarget: any;
  tmp_selectedTarget: any;
  selectedRangeValueLine: any;
  startDate: any;
  endDate: any;

  nudgeLite: boolean = false;

  receivedInfoCr: any;
  barChartCr: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartCrAxis: any = [];
  totalInfo = {
    total: {
      totalVisits: 0,
      totalOrders: 0,
      totalRevenue: '0',
    },
    share: {
      revenueShare: 0,
      trafficShare: 0,
      ordersShare: 0,
    },
    difference: {
      ordersIncentive: 0,
      ordersControl: 0,
      revenueControl: 0,
      revenueIncentive: 0,
      visitsIncentive: 0,
      visitsControl: 0,
      v: 0,
      vgc: 0,
      o: 0,
      ogc: 0,
    },
  };
  incentiveBarChart = {
    ranges: [
      { value: 'custom', viewValue: 'Custom' },
      { value: 'today', viewValue: 'Today' },
      { value: 'yestarday', viewValue: 'Yesterday' },
      { value: 'thisweek', viewValue: 'This Week' },
      { value: 'last7days', viewValue: 'Last 7 days' },
      { value: 'last30days', viewValue: 'Last 30 days' },
      { value: 'thismonth', viewValue: 'This Month' },
      { value: 'thisyear', viewValue: 'This Year' },
    ],
    crorderAmount: {
      incentivesAmount: {
        visits: '',
        orders: '',
      },
      controlAmount: {
        visits: '',
        orders: '',
      },
    },
    platforms: ['All', 'Desktop', 'Tablet', 'Mobile'],
    selectedPlatform: 'overall',
    selectedRangeValue: 'today',
    selectedFilter: 'overall',

    rate: 'hourly',
    onRateChange: function (value: any) {
      if (
        this.selectedRangeValue == 'today' ||
        this.selectedRangeValue == 'yesterday'
      ) {
        this.rate = 'hourly';
      } else {
        this.rate = value.value;
      }
    },
    transactionsListService: null,
  };
  isVolvoTestDrive: boolean = false;
  testDriveData: any;

  revUplift: number;
  revUplift_color: any;
  receivedInfoRpv: any;
  barChartRpv: any = [
    {
      name: 'Nudges Overall',
      value: 0,
      height: 0,
      diff: 0,
      tooltip: '',
    },
    {
      name: 'Control',
      value: 0,
      height: 0,
      diff: 0,
    },
  ];
  barChartRpvAxis: any = [];

  selectedOutlier = 'Default';
  outliers = ['Default', 'Adjusted'];

  chartDataCr: any[] = [
    { data: [], label: 'Nudges Overall' },
    { data: [], label: 'Control' },
  ];

  allBugData: any;
  barChartData: any = {};
  chartDataBugs: any = [];
  chartDataBugs_blurred: any = [
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -160,
      revenueLossNotFormatted: -160,
      percentage: 100,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -124,
      revenueLossNotFormatted: -124,
      percentage: 80,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -98,
      revenueLossNotFormatted: -98,
      percentage: 74,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -78,
      revenueLossNotFormatted: -78,
      percentage: 53,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -53,
      revenueLossNotFormatted: -53,
      percentage: 43,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
    {
      type: 'Blured Element on Product Page',
      bugName: 'Blured Element',
      pageName: 'Product Page',
      revenueLoss: -38,
      revenueLossNotFormatted: -38,
      percentage: 28,
      neutral: false,
      inlineStyle: '#dddcdc',
      cssClass: 'pagetype_tag',
      bug_category: 'Source Code Error',
    },
  ];
  chartDataBugs_blurred_show: boolean = false;
  showPercentages: boolean = false;
  returnLossTotal: number = 0;
  returnLossTotalNotFormatted: any = 0;
  returnLossTotalCheckout: any = 0;
  returnLossTotalPercentage: any = 0;
  bugTitle: string;
  bugPage: string;
  potentialFix: string;
  revenueLoss: string;
  bugDescription: string;
  bugTime: string;
  bugCategory: string;
  lowestNumber: number = 0;
  // criticalHoverInfo: string =
  //   'The Critical Events Today represent errors that are causing the highest revenue loss today.';
  activeSelectedPage: any = undefined;
  firstLoader: boolean = false;

  unitSold: number = 0;
  merchandiseProducts: any = [];

  merchandiseProducts_blurred: any = [
    {
      truncated_product_id: 'Blured',
      price: 559.9,
      sanitized_url: '../assets/img/merchandise/blurred_shirt.png',
      total_revenue: 2240,
      total_units_sold: 4,
      conversion_rate: 1.4705882352941175,
      total_revenue_diff: 100,
      total_units_sold_diff: 100,
      conversion_rate_diff: 100,
    },
    {
      truncated_product_id: 'Blured',
      price: 299.9,
      sanitized_url: '../assets/img/merchandise/blurred_jacket.webp',
      total_revenue: 1200,
      total_units_sold: 4,
      conversion_rate: 1.3745704467353952,
      total_revenue_diff: 100,
      total_units_sold_diff: 100,
      conversion_rate_diff: 100,
    },
    {
      truncated_product_id: 'Blured',
      price: 264.9,
      sanitized_url: '../assets/img/merchandise/blurred_shoes.png',
      total_revenue: 1060,
      total_units_sold: 4,
      conversion_rate: 7.4074074074074066,
      total_revenue_diff: 100,
      total_units_sold_diff: 100,
      conversion_rate_diff: 100,
    },
  ];
  merchandiseProducts_blurred_show: boolean = false;

  automationsRevenue: number = 0;
  automationsPrivilege_blurred_show: boolean = false;

  organic_role: boolean = false;
  organic_link: string = '';

  organicAllKpis: any;
  organicData: any = [];
  renamed_variables: any;
  variable_labels: any;
  formattedKeywordData: any;
  rankingImpactDirection: any = '-1';

  organicData_blurred: any = [
    {
      name: 'h2_4_c_keyword_sim',
      value: -1.0234005237600565,
      selected: false,
      tmp_renamed_name: 'Keyword → H2 Content (4)',
      variable_labels: '0',
      formatted_value: '-1.02',
    },
    {
      name: 'h2_1_keyword_sim',
      value: -0.7250057643717736,
      selected: false,
      tmp_renamed_name: 'Keyword → H2 (1)',
      variable_labels: '0',
      formatted_value: '-0.73',
    },
    {
      name: 'mbt_lpc_dir_sim',
      value: -0.6129660799216541,
      selected: false,
      tmp_renamed_name: 'Content → Backlink Page Titles (Direct)',
      variable_labels: '0',
      formatted_value: '-0.61',
    },
    {
      name: 'performance_score',
      value: -0.592,
      selected: false,
      tmp_renamed_name: 'Performance Score',
      formatted_value: '-0.59',
    },
    {
      name: 'mbt_t_sim_all',
      value: -0.5065204664380487,
      selected: false,
      tmp_renamed_name: 'Page Title → Backlink Page Titles',
      variable_labels: '0',
      formatted_value: '-0.51',
    },
    {
      name: 'bmt_lpc_LJ_sim',
      value: -0.3419713147580469,
      selected: false,
      tmp_renamed_name: 'Content → Backlink Page Titles (LJ)',
      variable_labels: '0',
      formatted_value: '-0.34',
    },
    {
      name: 'total_direct',
      value: -0.3006406778411866,
      selected: false,
      tmp_renamed_name: 'Total Backlinks (Direct)',
      variable_labels: '0',
      formatted_value: '-0.30',
    },
  ];
  organicData_blurred_show: boolean = false;

  nudge_blurred_show: boolean = false;
  nudge_blurred: any = {
    incentiveBarChart: {
      ranges: [
        {
          value: 'custom',
          viewValue: 'Custom',
        },
        {
          value: 'today',
          viewValue: 'Today',
        },
        {
          value: 'yestarday',
          viewValue: 'Yesterday',
        },
        {
          value: 'thisweek',
          viewValue: 'This Week',
        },
        {
          value: 'last7days',
          viewValue: 'Last 7 days',
        },
        {
          value: 'last30days',
          viewValue: 'Last 30 days',
        },
        {
          value: 'thismonth',
          viewValue: 'This Month',
        },
        {
          value: 'thisyear',
          viewValue: 'This Year',
        },
      ],
      crorderAmount: {
        incentivesAmount: {
          visits: '20,000',
          orders: '20,000',
        },
        controlAmount: {
          visits: '10,000',
          orders: '10,000',
        },
      },
      platforms: ['All', 'Desktop', 'Tablet', 'Mobile'],
      selectedPlatform: 'overall',
      selectedRangeValue: 'today',
      selectedFilter: 'overall',
      rate: 'hourly',
      transactionsListService: null,
    },
    barChartCrAxis: ['6', '5', '3', '2', '0'],
    barChartCr: [
      {
        name: 'Nudges Overall',
        value: 5.41,
        height: 90,
        diff: '+ 10.18%',
        tooltip: '0.00 (Significant)',
      },
      {
        name: 'Control',
        value: 4.91,
        height: 81.68,
        diff: 0,
      },
    ],
    barChartRpvAxis: ['6', '5', '3', '2', '0'],
    barChartRpv: [
      {
        name: 'Nudges Overall',
        value: 4.29,
        height: 90,
        diff: '+ 10.00%',
        tooltip: '0.00 (Significant)',
      },
      {
        name: 'Control',
        value: 3.9,
        height: 81.82,
        diff: 0,
      },
    ],
  };

  ngOnInit(): void {
    if (window.location.pathname.includes('/dashboard')) {
      this.pageTitle.setTitle('Dashboard');
    }
    this.mixpanelService.track('Dashboard Page Viewed');

    this.nudgePrivilege = this.checkRoleGuard('masterPreview');
    this.diagnosticsPrivilege = this.checkRoleGuard('bugTrackingOverall');
    this.merchandisePrivilege = this.checkRoleGuard('merchandising');
    this.organicPrivilege = this.organic_role;
    this.automationsPrivilege = this.checkRoleGuard('automationsOverview');

    this.dashboardSub.add(
      this.selectorService.currentMessage.subscribe((message) => {
        this.selectedStore = message;
        this.selectedTarget = localStorage.getItem('selectedTarget');
        this.startDate = window.localStorage.getItem('startDate');
        this.endDate = window.localStorage.getItem('endDate');
        this.selectedRangeValueLine = localStorage.getItem('rangeValue');

        // if (this.selectedTarget == 'behamicsSample') {
        //   this.showNewDashboardView = true;
        // } else {
        //   this.showNewDashboardView = false;
        // }

        // If component has KPI with rate filter
        if (this.selectedRangeValueLine == 'thisyear') {
          this.timeRangeSelected = 'for this year';
        } else if (this.selectedRangeValueLine == 'thisweek') {
          this.timeRangeSelected = 'for this week';
        } else if (this.selectedRangeValueLine == 'last7days') {
          this.timeRangeSelected = 'last 7 days';
        } else if (this.selectedRangeValueLine == 'last30days') {
          this.timeRangeSelected = 'last 30 days';
        } else if (this.selectedRangeValueLine == 'thismonth') {
          this.timeRangeSelected = 'this month';
        } else if (this.selectedRangeValueLine == 'custom') {
          this.timeRangeSelected =
            'from ' +
            this.formatDateRange(this.startDate) +
            ' - ' +
            this.formatDateRange(this.endDate);
        } else if (this.selectedRangeValueLine == 'today') {
          this.timeRangeSelected = 'for today';
        } else if (this.selectedRangeValueLine == 'yestarday') {
          this.timeRangeSelected = 'for yesterday';
        }

        if (this.showNewDashboardView) {
          this.checkOrganicPrivileges();

          this.nudgePrivilege = this.checkRoleGuard('masterPreview');
          this.diagnosticsPrivilege = this.checkRoleGuard('bugTrackingOverall');
          this.merchandisePrivilege = this.checkRoleGuard('merchandising');
          this.organicPrivilege = this.organic_role;
          this.automationsPrivilege = this.checkRoleGuard(
            'automationsOverview'
          );

          this.cunstructProductLayouts();
          this.fetchDataFromServer();
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.dashboardSub.unsubscribe();
  }
  cunstructProductLayouts() {
    let products_index = 0;
    const products_layout: any = {
      ...(this.nudgePrivilege ? { nudge: products_index++ } : {}),
      ...(this.diagnosticsPrivilege ? { diagnostics: products_index++ } : {}),
      ...(this.merchandisePrivilege ? { merchandise: products_index++ } : {}),
      ...(this.organicPrivilege ? { organic: products_index++ } : {}),
      ...(this.automationsPrivilege ? { automations: products_index++ } : {}),
    };

    requestAnimationFrame(() => {
      // Intro cards
      const introContainer: any = document.querySelector(
        '[products-intro-container]'
      );
      const productsIntro: HTMLElement[] = Array.from(
        document.querySelectorAll('[product-intro]')
      );

      productsIntro.sort((a: any, b: any) => {
        const orderA = products_layout[a.getAttribute('product-intro')] ?? 999;
        const orderB = products_layout[b.getAttribute('product-intro')] ?? 999;
        return orderA - orderB;
      });

      productsIntro.forEach((card) => introContainer.appendChild(card));

      // Product cards
      const container: any = document.querySelector('[products-container]');
      const productsCard: HTMLElement[] = Array.from(
        document.querySelectorAll('[product-card]')
      );

      productsCard.sort((a: any, b: any) => {
        const orderA = products_layout[a.getAttribute('product-card')] ?? 999;
        const orderB = products_layout[b.getAttribute('product-card')] ?? 999;
        return orderA - orderB;
      });

      productsCard.forEach((card) => container.appendChild(card));
    });
  }
  redirectToUrl(url: string, privilege: string) {
    if (privilege == 'organic' && this.organic_role) {
      localStorage.setItem('activeTabAiAnalysis', 'Detailed');
      const navLinks: any = document.querySelectorAll('.nav-item');
      for (let i = 0; i < navLinks.length; i++) {
        if (navLinks[i].innerText == 'Organic') {
          navLinks[i].click();
        }
      }
      this.router.navigate([this.organic_link]);
      return;
    } else if (privilege != 'organic' && !this.checkRoleGuard(privilege)) {
      return;
    }

    this.router.navigate([url]);
  }
  checkOrganicPrivileges() {
    const roles = localStorage.getItem('privileges');

    if (roles?.includes('seo_')) {
      this.organic_role = true;

      if (roles?.includes('seo_getting_started')) {
        this.organic_link = '/seo/ai-analysis';
      } else if (roles?.includes('seo_backlinks')) {
        this.organic_link = '/seo/backlink-prediction';
      }
    }
  }
  fetchDataFromServer(): void {
    let device = '';
    let visitType = '';

    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      target: window.localStorage.getItem('selectedTarget'),
      device: device,
      visitType: visitType,
    };

    setTimeout(() => {
      // if (localStorage.getItem('nudgeLite')) {
      //   this.nudgeLite = true;
      // } else {
      //   this.nudgeLite = false;
      // }
      if (localStorage.getItem('selectedTarget') === 'douglas') {
        this.nudgeLite = false;
      } else if (localStorage.getItem('nudgeLite')) {
        this.nudgeLite = true;
      } else {
        this.nudgeLite = false;
      }

      // Nudge dummy data
      if (!this.nudgePrivilege) {
        this.nudge_blurred_show = true;
        this.incentiveBarChart = this.nudge_blurred.incentiveBarChart;
        this.barChartCrAxis = this.nudge_blurred.barChartCrAxis;
        this.barChartCr = this.nudge_blurred.barChartCr;
        this.barChartRpvAxis = this.nudge_blurred.barChartRpvAxis;
        this.barChartRpv = this.nudge_blurred.barChartRpv;

        this.revUplift = 20000;

        this.nudgeApiCalls();
        this.nudgeApiCalls();
      } else {
        this.nudge_blurred_show = false;
        // Nudge data from api
        this.getChartCr(data);
        this.getChartRpv(data);
      }
    }, 200);

    // Diagnostics data
    this.getInfoBugData({
      startDate: this.startDate,
      endDate: this.endDate,
      rate: this.rate,
      selectedRangeValue: this.selectedRangeValueLine,
      target: this.selectedTarget,
    });
    this.revenueLossApiCall();

    // Merchandise data
    this.getMerchandiseData();

    // Automations data
    this.getAutomationData();

    // Organic data - will be called only on first load
    if (
      this.organicDataFirstLoad ||
      this.tmp_selectedTarget != this.selectedTarget
    ) {
      this.tmp_selectedTarget = this.selectedTarget;
      this.getOrganicData();
    }
  }

  nudgeApiCallsIndex: number = 0;
  nudgeApiTotal: number = 2;
  nudgeApiCalls() {
    this.nudgeApiCallsIndex++;
    if (this.nudgeApiCallsIndex == this.nudgeApiTotal) {
      this.nudgeDataLoading = false;
      this.nudgeApiCallsIndex = 0;

      setTimeout(() => {
        this.nudgeDataFirstLoad = false;
      }, 200);
    }
  }
  diagnosticsApiCallsIndex: number = 0;
  diagnosticsApiTotal: number = 2;
  diagnosticsApiCalls() {
    this.diagnosticsApiCallsIndex++;
    if (this.diagnosticsApiCallsIndex == this.diagnosticsApiTotal) {
      this.diagnosticsDataLoading = false;
      this.diagnosticsApiCallsIndex = 0;

      setTimeout(() => {
        this.diagnosticsDataFirstLoad = false;
      }, 200);
    }
  }
  merchandiseApiCallsIndex: number = 0;
  merchandiseApiTotal: number = 1;
  merchandiseApiCalls() {
    this.merchandiseApiCallsIndex++;
    if (this.merchandiseApiCallsIndex == this.merchandiseApiTotal) {
      this.merchandiseDataLoading = false;
      this.merchandiseApiCallsIndex = 0;

      setTimeout(() => {
        this.merchandiseDataFirstLoad = false;
      }, 200);
    }
  }
  organicApiCallsIndex: number = 0;
  organicApiTotal: number = 1;
  organicApiCalls() {
    this.organicApiCallsIndex++;
    if (this.organicApiCallsIndex == this.organicApiTotal) {
      this.organicDataLoading = false;
      this.organicApiCallsIndex = 0;

      setTimeout(() => {
        this.organicDataFirstLoad = false;
      }, 200);
    }
  }

  automationsApiCallsIndex: number = 0;
  automationsApiTotal: number = 1;
  automationsApiCalls() {
    this.automationsApiCallsIndex++;
    if (this.automationsApiCallsIndex == this.automationsApiTotal) {
      this.automationsDataLoading = false;
      this.automationsApiCallsIndex = 0;

      setTimeout(() => {
        this.automationsDataFirstLoad = false;
      }, 200);
    }
  }

  selectionDeviceChanged(event: any) {
    this.incentiveBarChart.selectedPlatform = event.value;
    // this.chartLoader = false;
    // this.loadNrIndicator = 0;
    this.fetchDataFromServer();
  }
  platformChanged(event: any) {
    // this.chartLoader = false;
    // this.loadNrIndicator = 0;
    // this.fetchDataFromServer();
  }
  filterVisits(event: any) {
    this.incentiveBarChart.selectedFilter = event.value;
    // this.chartLoader = false;
    // this.loadNrIndicator = 0;
    // this.fetchDataFromServer();
  }
  onOutlierChange(value: any) {
    // this.chartLoader = false;
    // this.loadNrIndicator = 0;
    // this.fetchDataFromServer();
  }
  formatDateRange(date: string) {
    const [isoDate] = date.split('@');
    let d = new Date(isoDate);

    const month = d.toLocaleString('en-US', { month: 'short' });
    const day = d.getDate();

    const suffix =
      day % 10 == 1 && day != 11
        ? 'st'
        : day % 10 == 2 && day != 12
        ? 'nd'
        : day % 10 == 3 && day != 13
        ? 'rd'
        : 'th';

    return `${month} ${day}${suffix}`;
  }
  getChartCr(dateRange: any) {
    this.nudgeDataLoading = true;
    this.dashboardSub.add(
      this.performanceService.getBarChartCr(dateRange).subscribe({
        next: (info) => {
          this.nudgeApiCalls();
          this.receivedInfoCr = info;
          function formatNumber(num: any) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
          function formatPvalue(num: any) {
            if (num == null) {
              num = '';
            } else {
              num = num.toFixed(2);
              if (num <= 0.1) {
                num = num + ' (Significant)';
              } else {
                num = num + ' (Not Significant)';
              }
            }
            return num;
          }

          function increaseDecreaseDiff(
            incentive: any,
            control: any,
            noHTML: boolean = false
          ) {
            var diff = incentive / control - 1;
            diff = diff * 100;

            if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
              return '';
            }

            if (diff < 0) {
              if (noHTML) {
                return '' + diff.toFixed(2).replace('-', '- ') + '%';
              }
              return (
                "<span style='color: #458c30;'>" +
                diff.toFixed(2).replace('-', '- ') +
                '%</span>'
              );
            } else {
              if (noHTML) {
                return '+ ' + diff.toFixed(2) + '%';
              }
              return (
                "<span style='color: #ff9292ed;'>+ " +
                diff.toFixed(2) +
                '%</span>'
              );
            }
          }

          if (Object.keys(this.receivedInfoCr.difference).length) {
            this.totalInfo.total.totalVisits = formatNumber(
              this.receivedInfoCr.difference['v{}'] +
                this.receivedInfoCr.difference['v{gC=1}']
            );
            this.totalInfo.total.totalOrders = formatNumber(
              this.receivedInfoCr.difference['o{}'] +
                this.receivedInfoCr.difference['o{gC=1}']
            );

            if (
              this.receivedInfoCr.difference[
                `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
              ] != undefined
            ) {
              this.totalInfo.difference['ordersControl'] =
                this.receivedInfoCr.difference[
                  `o{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
                ];
            } else {
              this.totalInfo.difference['ordersControl'] =
                this.receivedInfoCr.difference['o{gC=1,seenI=1}'];
            }

            if (
              this.receivedInfoCr.difference[
                `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
              ] != undefined
            ) {
              this.totalInfo.difference['ordersIncentive'] =
                this.receivedInfoCr.difference[
                  `o{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
                ];
            } else {
              this.totalInfo.difference['ordersIncentive'] =
                this.receivedInfoCr.difference['o{seenI=1}'];
            }

            if (
              this.receivedInfoCr.difference[
                `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
              ] != undefined
            ) {
              this.totalInfo.difference.visitsIncentive =
                this.receivedInfoCr.difference[
                  `v{d=${this.incentiveBarChart.selectedPlatform},seenI=1}`
                ];
            } else {
              this.totalInfo.difference.visitsIncentive =
                this.receivedInfoCr.difference['v{seenI=1}'];
            }
            if (
              this.receivedInfoCr.difference[
                `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
              ] != undefined
            ) {
              this.totalInfo.difference.visitsControl =
                this.receivedInfoCr.difference[
                  `v{d=${this.incentiveBarChart.selectedPlatform},gC=1,seenI=1}`
                ];
            } else {
              this.totalInfo.difference.visitsControl =
                this.receivedInfoCr.difference['v{gC=1,seenI=1}'];
            }

            this.totalInfo.difference['v'] =
              this.receivedInfoCr.difference['v{}'];
            this.totalInfo.difference['vgc'] =
              this.receivedInfoCr.difference['v{gC=1}'];

            this.totalInfo.difference['o'] =
              this.receivedInfoCr.difference['o{}'];
            this.totalInfo.difference['ogc'] =
              this.receivedInfoCr.difference['o{gC=1}'];
          }

          if (info.incentives == null) {
            info.incentives = 0;
          }
          if (info.control == null) {
            info.control = 0;
          }

          var tmpData = [];

          if (this.isVolvoTestDrive) {
            tmpData.push(
              (this.testDriveData.crIncentive * 100).toFixed(2),
              (this.testDriveData.crControl * 100).toFixed(2)
            );

            this.barChartCr[0].value = (
              this.testDriveData.crIncentive * 100
            ).toFixed(2);
            this.barChartCr[1].value = (
              this.testDriveData.crControl * 100
            ).toFixed(2);

            this.barChartCr[0].diff = increaseDecreaseDiff(
              (this.testDriveData.crIncentive * 100).toFixed(2),
              (this.testDriveData.crControl * 100).toFixed(2),
              true
            );
          } else {
            tmpData.push(info.incentives, info.control);

            this.barChartCr[0].value = info.incentives;
            this.barChartCr[1].value = info.control;

            this.barChartCr[0].tooltip = formatPvalue(info.p);

            if (!this.nudgeLite) {
              this.barChartCr[0].diff = increaseDecreaseDiff(
                info.incentives,
                info.control,
                true
              );
            } else {
              if (info.incentives < 0) {
                this.barChartCr[0].diff = '- ' + info.incentives + '%';
              } else {
                this.barChartCr[0].diff = info.incentives + '%';
              }
            }
          }

          this.barChartCrAxis = this.generateEvenPoints(
            this.barChartCr,
            5
          ).reverse();

          this.barChartCr = this.setProportionalHeights(this.barChartCr, 90);

          if (this.isVolvoTestDrive) {
            this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
              formatNumber(this.testDriveData.matchedIncentive);

            this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
              formatNumber(this.testDriveData.bookedIncentive);

            this.incentiveBarChart.crorderAmount.controlAmount.visits =
              formatNumber(this.testDriveData.matchedControl);

            this.incentiveBarChart.crorderAmount.controlAmount.orders =
              formatNumber(this.testDriveData.bookedControl);
          } else {
            if (info.incentivesInfo.visits != null) {
              this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
                formatNumber(info.incentivesInfo.visits);
            } else {
              this.incentiveBarChart.crorderAmount.incentivesAmount.visits =
                '0';
            }
            if (info.incentivesInfo.orders != null) {
              this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
                formatNumber(info.incentivesInfo.orders);
            } else {
              this.incentiveBarChart.crorderAmount.incentivesAmount.orders =
                '0';
            }
            if (info.controlInfo.visits != null) {
              this.incentiveBarChart.crorderAmount.controlAmount.visits =
                formatNumber(info.controlInfo.visits);
            } else {
              this.incentiveBarChart.crorderAmount.controlAmount.visits = '0';
            }
            if (info.controlInfo.orders != null) {
              this.incentiveBarChart.crorderAmount.controlAmount.orders =
                formatNumber(info.controlInfo.orders);
            } else {
              this.incentiveBarChart.crorderAmount.controlAmount.orders = '0';
            }
          }
        },
        error: (err: any) => {
          this.nudgeApiCalls();
        },
      })
    );
  }
  generateEvenPoints(data: any, numPoints: any) {
    const validValues = data
      .map((item: any) => item.value)
      .filter(
        (value: any) => value !== null && value !== undefined && !isNaN(value)
      );

    if (validValues.length === 0) {
      console.warn('No valid values to calculate points.');
      return [];
    }

    const maxValue = Math.max(...validValues);
    const minValue = Math.min(...validValues);

    const points = [];

    if (maxValue <= 0) {
      const roundedMin = Math.floor(minValue / 2) * 2;
      const interval = Math.abs(roundedMin) / (numPoints - 1);

      for (let i = 0; i < numPoints; i++) {
        points.push((0 - interval * i).toFixed(0));
      }
    } else {
      const roundedMax = Math.ceil(maxValue / 2) * 2;
      const interval = roundedMax / (numPoints - 1);

      for (let i = 0; i < numPoints; i++) {
        points.push((interval * i).toFixed(0));
      }
    }

    return points;
  }
  setProportionalHeights(data: any, maxHeightPercentage: any) {
    const validValues = data
      .map((item: any) => item.value)
      .filter(
        (value: any) => value !== null && value !== undefined && !isNaN(value)
      );

    const allNegative = validValues.every((value: any) => value < 0);

    const maxValue = allNegative
      ? Math.min(...validValues)
      : Math.max(...validValues);

    data.forEach((item: any) => {
      if (item.value === 0 || item.value == 'NaN' || isNaN(item.value)) {
        item.height = 3;
        item.value = 0;
      } else {
        item.height = (item.value / maxValue) * maxHeightPercentage;
        item.height = Math.round(item.height * 100) / 100;
      }
    });

    return data;
  }
  getChartRpv(dateRange: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatPvalue(num: any) {
      if (num == null) {
        num = '';
      } else {
        num = num.toFixed(2);
        if (num <= 0.1) {
          num = num + ' (Significant)';
        } else {
          num = num + ' (Not Significant)';
        }
      }
      return num;
    }

    function increaseDecreaseDiff(
      incentive: any,
      control: any,
      noHTML: boolean = false
    ) {
      var diff = incentive / control - 1;
      diff = diff * 100;

      if (isNaN(diff) || diff === Infinity || diff === -Infinity) {
        return '';
      }

      if (diff < 0) {
        if (noHTML) {
          return '' + diff.toFixed(2).replace('-', '- ') + '%';
        }
        return (
          "<span style='color: #458c30;'>" +
          diff.toFixed(2).replace('-', '- ') +
          '%</span>'
        );
      } else {
        if (noHTML) {
          return '+ ' + diff.toFixed(2) + '%';
        }
        return (
          "<span style='color: #ff9292ed;'>+ " + diff.toFixed(2) + '%</span>'
        );
      }
    }

    function isDifferenceThreeMonthsOrMore(startDateStr: any, endDateStr: any) {
      const startDateFixed = startDateStr.split('@')[0];
      const endDateFixed = endDateStr.split('@')[0];

      const startDate = new Date(startDateFixed);
      const endDate = new Date(endDateFixed);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error('Invalid date format');
      }

      const monthsDifference =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        endDate.getMonth() -
        startDate.getMonth();

      return monthsDifference >= 3;
    }

    if (isDifferenceThreeMonthsOrMore(dateRange.startDate, dateRange.endDate)) {
      dateRange.moreThanThreeMonths = true;

      const startDateString = dateRange.startDate;
      const endDateString = dateRange.endDate;

      const timeZone = this.timezoneService.getTimezone();
      const startDateUTC = new Date(startDateString.split('@')[0]);
      const endDateUTC = new Date(endDateString.split('@')[0]);
      const startDateLocal = convertUTCToLocalDate(startDateUTC, timeZone);
      const endDateLocal = convertUTCToLocalDate(endDateUTC, timeZone);

      let sequences = [];

      let currentDateLocal = new Date(
        startDateLocal.getFullYear(),
        startDateLocal.getMonth(),
        1
      );

      while (currentDateLocal <= endDateLocal) {
        let monthName = currentDateLocal.toLocaleString('default', {
          month: 'long',
        });

        let startOfMonthLocal = new Date(
          currentDateLocal.getFullYear(),
          currentDateLocal.getMonth(),
          1,
          0,
          0
        );
        let endOfMonthLocal = new Date(
          currentDateLocal.getFullYear(),
          currentDateLocal.getMonth() + 1,
          0,
          23,
          59,
          59
        );

        let startDateForMonthLocal =
          currentDateLocal.getMonth() === startDateLocal.getMonth() &&
          currentDateLocal.getFullYear() === startDateLocal.getFullYear()
            ? startDateLocal
            : startOfMonthLocal;

        let endDateForMonthLocal =
          currentDateLocal.getMonth() === endDateLocal.getMonth() &&
          currentDateLocal.getFullYear() === endDateLocal.getFullYear()
            ? endDateLocal
            : endOfMonthLocal;

        let startDateForMonthUTC = convertLocalToUTCDate(
          startDateForMonthLocal,
          timeZone
        );

        let endDateForMonthUTC = convertLocalToUTCDate(
          endDateForMonthLocal,
          timeZone
        );

        const adjustedStartDateForMonthUTC = new Date(
          startDateForMonthUTC.getTime() + 2 * 60 * 60 * 1000
        );
        const adjustedEndDateForMonthUTC = new Date(
          endDateForMonthUTC.getTime() + 2 * 60 * 60 * 1000
        );

        let formattedStartDate =
          adjustedStartDateForMonthUTC.toISOString() + '@' + timeZone;
        let formattedEndDate =
          adjustedEndDateForMonthUTC.toISOString() + '@' + timeZone;

        if (endDateForMonthLocal < startDateLocal) {
          currentDateLocal.setMonth(currentDateLocal.getMonth() + 1);
          continue;
        }

        sequences.push({
          month: monthName,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });

        currentDateLocal.setMonth(currentDateLocal.getMonth() + 1);
      }

      function convertUTCToLocalDate(dateUTC: Date, timeZone: string): Date {
        const dateString = dateUTC.toLocaleString('en-US', { timeZone });
        return new Date(dateString);
      }

      function convertLocalToUTCDate(dateLocal: Date, timeZone: string): Date {
        const dateString = dateLocal.toLocaleString('en-US', {
          timeZone: 'UTC',
        });
        return new Date(dateString);
      }

      dateRange.sequences = JSON.stringify(sequences);
    }

    this.nudgeDataLoading = true;
    this.dashboardSub.add(
      this.performanceService.getBarChartRpv(dateRange).subscribe({
        next: (info) => {
          this.nudgeApiCalls();

          this.receivedInfoRpv = info;

          if (info?.incentives == null && info?.control == null) {
            info.incentives = 0;
            info.control = 0;
          } else {
            info.incentives =
              info.incentivesInfo['revenue'] /
              Number(info.incentivesInfo['visits']);

            info.control =
              info.controlInfo['revenue'] / Number(info.controlInfo['visits']);

            info.incentives = Number(info.incentives.toFixed(2));
            info.control = Number(info.control.toFixed(2));
          }

          var incRev: any;
          var cntRev: any;

          if (this.isVolvoTestDrive) {
            var revenueInc = this.testDriveData.bookedIncentive * 9000;
            var revenueCon = this.testDriveData.bookedControl * 9000;
            incRev = revenueInc / this.testDriveData.matchedIncentive;
            cntRev = revenueCon / this.testDriveData.matchedControl;
            var infVisits = this.testDriveData.matchedIncentive;
          } else {
            incRev = info.incentives;
            cntRev = info.control;
            var infVisits = info.incentivesInfo['visits'];
          }

          if (!this.nudgeLite) {
            this.revUplift = this.calcUplift(
              isNaN(incRev) ? 0 : incRev,
              isNaN(cntRev) ? 0 : cntRev,
              isNaN(infVisits) ? 0 : infVisits
            );
          } else {
            this.revUplift = info.incentivesInfo['revenue'];
          }

          if (info.sumRevenueUpliftCount) {
            this.revUplift = info.sumRevenueUpliftCount;
          }
          this.checkUpliftColor();

          setTimeout(() => {
            if (Object.keys(this.receivedInfoRpv.difference).length) {
              this.totalInfo.total.totalRevenue = formatNumber(
                (
                  this.receivedInfoRpv.difference['rev{}'] +
                  this.receivedInfoRpv.difference['rev{gC=1}']
                ).toFixed(2)
              );

              this.totalInfo.difference.revenueIncentive =
                this.receivedInfoRpv.difference['rev{}'];

              this.totalInfo.difference.revenueControl =
                this.receivedInfoRpv.difference['rev{gC=1}'];

              this.totalInfo.share.revenueShare =
                this.calcUpliftRaw(incRev, cntRev, infVisits) /
                (this.receivedInfoRpv.difference['rev{}'] +
                  this.receivedInfoRpv.difference['rev{gC=1}']);

              this.totalInfo.share.trafficShare =
                (this.totalInfo.difference.visitsIncentive +
                  this.totalInfo.difference.visitsControl) /
                (this.totalInfo.difference.v + this.totalInfo.difference.vgc);

              this.totalInfo.share.ordersShare =
                (this.totalInfo.difference.ordersIncentive +
                  this.totalInfo.difference.ordersControl) /
                (this.totalInfo.difference.o + this.totalInfo.difference.ogc);
            }
          }, 200);

          var tmpData = [];

          if (this.isVolvoTestDrive) {
            var revenueInc = this.testDriveData.bookedIncentive * 9000;
            var revenueCon = this.testDriveData.bookedControl * 9000;

            tmpData.push(
              (revenueInc / this.testDriveData.matchedIncentive).toFixed(2),
              (revenueCon / this.testDriveData.matchedControl).toFixed(2)
            );

            this.barChartRpv[0].value = (
              revenueInc / this.testDriveData.matchedIncentive
            ).toFixed(2);
            this.barChartRpv[1].value = (
              revenueCon / this.testDriveData.matchedControl
            ).toFixed(2);
            this.barChartRpv[0].diff = increaseDecreaseDiff(
              (revenueInc / this.testDriveData.matchedIncentive).toFixed(2),
              (revenueCon / this.testDriveData.matchedControl).toFixed(2),
              true
            );
          } else {
            tmpData.push(info.incentives, info.control);

            this.barChartRpv[0].value = info.incentives;
            this.barChartRpv[1].value = info.control;

            this.barChartRpv[0].tooltip = formatPvalue(info.p);

            if (!this.nudgeLite) {
              this.barChartRpv[0].diff = increaseDecreaseDiff(
                info.incentives,
                info.control,
                true
              );
            } else {
              this.barChartRpv[0].diff = info.incentives;
              // if (info.incentives < 0) {
              //   this.barChartRpv[0].diff =
              //     '- ' + info.incentives + this.displayCurrency();
              // } else {
              //   this.barChartRpv[0].diff =
              //     info.incentives + this.displayCurrency();
              // }
            }
          }

          this.barChartRpvAxis = this.generateEvenPoints(
            this.barChartRpv,
            5
          ).reverse();

          this.barChartRpv = this.setProportionalHeights(this.barChartRpv, 90);
          // this.combinedLoader(1);
        },
        error: (err: any) => {
          this.nudgeApiCalls();
        },
      })
    );
  }
  calcUplift(inc: any, con: any, visits: any) {
    var res = (inc - con) * visits;

    return res;
  }
  formatCurrency(res: any) {
    var currencySymbol = this.displayCurrency();
    var symbolAtEnd = ['€', 'CHF', 'kc'];
    var appendSymbol = symbolAtEnd.includes(currencySymbol);
    var formattedNumber;
    if (res < 100 && res > -100) {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    if (appendSymbol) {
      return formattedNumber + ' ' + currencySymbol;
    } else {
      return currencySymbol + formattedNumber;
    }
  }
  formatCurrency2(res: any) {
    var currencySymbol = this.displayCurrency();
    var symbolAtEnd = ['€', 'CHF', 'kc'];
    var appendSymbol = symbolAtEnd.includes(currencySymbol);
    var formattedNumber;
    if (res < 100 && res > -100) {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      formattedNumber = Math.abs(res).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    var sign = res < 0 ? '- ' : res > 0 ? '+ ' : '';

    if (appendSymbol) {
      return sign + formattedNumber + ' ' + currencySymbol;
    } else {
      return sign + currencySymbol + formattedNumber;
    }
  }
  calcUpliftRaw(inc: any, con: any, visits: any): number {
    var res = (inc - con) * visits;
    return res;
  }
  displayCurrency() {
    var currency = window.localStorage.getItem('currency');

    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'AUD':
        return 'A$';
      case 'CHF':
        return 'CHF';
      case 'GBP':
        return '£';
      case 'CZK':
        return 'kc';
      case 'PLN':
        return 'zl';
      case 'SEK':
        return 'kr';
      case 'JPY':
        return '¥';
      case 'undefined':
        return '€';
    }

    return '$';
  }
  checkUpliftColor() {
    if (this.revUplift < 0) {
      this.revUplift_color = false;
    } else if (this.revUplift > 0) {
      this.revUplift_color = true;
    } else {
      this.revUplift_color = 0;
    }
  }
  formatPercentage(val: any) {
    if (isNaN(val) || val == null || val == undefined) {
      return '0%';
    }
    return (val * 100).toFixed(0).toString() + '%';
  }
  isNaN(value: any): boolean {
    return isNaN(parseFloat(value));
  }
  getFormattedValue(value: any): string {
    return this.isNaN(value) ? '0.00' : parseFloat(value).toFixed(2);
  }
  safeSum(...values: any[]): number {
    return values.reduce(
      (acc, val) => acc + (this.isNaN(val) ? 0 : parseFloat(val)),
      0
    );
  }
  getTrafficShare(): string {
    const trafficShare = this.totalInfo.share.trafficShare;
    return this.getFormattedValue(trafficShare);
  }
  getOrdersShare(): string {
    const ordersShare = this.totalInfo.share.ordersShare;
    return this.getFormattedValue(ordersShare);
  }
  formatCurrencySymbol(value: number) {
    const currency = localStorage.getItem('currency') || 'usd';

    const tmpValue = value ?? 0;

    const currency_uppercase = currency.toLocaleUpperCase();

    if (currency_uppercase === 'AUD') {
      return (
        'A' +
        new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: currency_uppercase,
        })
          .format(tmpValue)
          .split('.')[0]
      );
    }
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: currency_uppercase,
    })
      .format(tmpValue)
      .split('.')[0];
  }
  displayAxisValSymbol(res: any) {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    var currencySymbol = this.displayCurrency();

    var symbolAtEnd = ['€', 'CHF', 'kc'];
    var appendSymbol = symbolAtEnd.includes(currencySymbol);

    if (isNaN(res) || res == 'NaN') res = 0;

    if (appendSymbol) {
      return formatNumber(res) + currencySymbol;
    } else {
      return currencySymbol + formatNumber(res);
    }
  }
  getInfoBugData(obj: any) {
    if (!this.diagnosticsPrivilege) {
      this.diagnosticsApiCalls();
      this.chartDataBugs = this.chartDataBugs_blurred;
      this.chartDataBugs_blurred_show = true;
      this.returnLossTotal = -1460;
      return;
    }
    this.chartDataBugs_blurred_show = false;

    this.diagnosticsDataLoading = true;
    this.dashboardSub.add(
      this.bugTrackingService.getInfoBugData(obj).subscribe({
        next: (dataObj: any) => {
          try {
            this.diagnosticsApiCalls();
            this.allBugData = dataObj['data'];

            this.returnLossTotalPercentage = 0;
            this.returnLossTotal = 0;
            this.returnLossTotalCheckout = 0;
            if (this.allBugData.length > 0) {
              this.allBugData.forEach((el: any) => {
                el['bugData'] = el.date;

                if (el.revenue_total_loss) {
                  this.returnLossTotal += el.revenue_total_loss;
                }
                if (el.total_value_of_checkout) {
                  this.returnLossTotalCheckout += el.total_value_of_checkout;
                }
              });

              this.allBugData = this.allBugData.filter((el: any) => {
                return el.RPVm >= el.RPV;
              });

              this.returnLossTotalNotFormatted = Math.abs(this.returnLossTotal);

              if (
                this.returnLossTotalNotFormatted != 0 &&
                this.returnLossTotalCheckout == 0
              ) {
                this.returnLossTotalPercentage = 100;
              } else {
                this.returnLossTotalPercentage = (
                  (this.returnLossTotalNotFormatted /
                    this.returnLossTotalCheckout) *
                  100
                ).toFixed(2);
              }
              if (this.rate == 'hourly') {
                let date = this.formatDateFull(
                  this.allBugData[this.allBugData.length - 1]?.bugData
                ).substr(
                  this.formatDateFull(
                    this.allBugData[this.allBugData.length - 1]?.bugData
                  ).indexOf(' ') + 1
                );
                this.bugTime = new Date().toString().split(' ')[1] + ' ' + date;
              }
            }
          } catch (e) {
            this.diagnosticsApiCalls();
          }
        },
        error: (error) => {
          this.diagnosticsApiCalls();
          this.returnLossTotalPercentage = 0;
          this.returnLossTotal = 0;
          this.returnLossTotalCheckout = 0;
        },
      })
    );
  }
  formatDateFull(date: any) {
    if (date != undefined) {
      if (date.length > 11) {
        if (date.split(' ')[1].length == 1) {
          date = date.split(' ')[0] + ' 0' + date.split(' ')[1];
        }
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return MM[$2 - 1] + ' ' + $3 + ', ' + $1 + ' - ' + $4 + ampm12;
          }
        );
        return xx;
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3 + ', ' + $1;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }
  }
  redirectErrorDetailed(bugName: string, bugPage: string) {
    if (
      bugName.includes('Premium') ||
      bugName.includes('No bugs found') ||
      this.chartDataBugs_blurred_show
    ) {
      return;
    }

    let tmpBugName = bugName;

    if (bugName == 'Recommendation Image Error') {
      bugName = 'recommendation image error';
    }

    if (bugName == 'img size mean') {
      tmpBugName = 'img_size_mean';
    } else if (
      Object.keys(listOfNames2).find(
        (item: any) => listOfNames2[item].showName == bugName
      ) != null
    ) {
      Object.keys(listOfNames2).find((item: any) => {
        tmpBugName = item;
        return listOfNames2[item].showName == bugName;
      });
    } else if (bugName == 'Images failed to load') {
      tmpBugName = ('image_error' + ' on ' + bugPage).replace(/ /g, '_');
    } else {
      tmpBugName = (bugName + ' on ' + bugPage).replace(/ /g, '_');
    }

    let obj: any = {
      name: tmpBugName,
      rate: this.rate,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    obj['target'] = window.localStorage.getItem('selectedTarget');

    sessionStorage.setItem('tmpBugName', tmpBugName);
    localStorage.setItem('save_last_tab', 'true');
    this.router.navigate(['diagnostic/trace-error']);
  }
  updateBugBarChart() {
    let chartData: any = {};
    let excludeList = Object.keys(listOfNames2);

    if (this.barChartData != undefined) {
      let tmpBugName = '';
      Object.keys(this.barChartData).forEach((el) => {
        if (!el.includes('behamics')) {
          if (el.replace(/_/g, ' ').split(' on ')[0].includes('Premium')) {
            tmpBugName = 'Premium Only';
          } else if (
            excludeList.includes(el.split('_on_')[0]) ||
            this.barChartData[el]?.bug_category === 'Web Performance'
          ) {
            tmpBugName = listOfNames2[el.split('_on_')[0]]?.showName;
          } else {
            if (el.split('_on_')?.length > 2) {
              let tmpLen = el.split('_on_').length;
              for (let i = 0; i < tmpLen; i++) {
                if (i < tmpLen - 1) {
                  tmpBugName += el.split('_on_')[i];
                  if (i < tmpLen - 2) {
                    tmpBugName += '_on_';
                  }
                }
              }
            } else {
              tmpBugName = el.replace(/_/g, ' ').split(' on ')[0];
            }
          }

          if (tmpBugName == 'recommendation image error') {
            tmpBugName = 'Recommendation Image Error';
          }

          chartData[el] = {
            revenueLoss: 0,
            bug_category: this.barChartData[el].bug_category,
            pageName: this.barChartData[el]?.page,
            bugName: tmpBugName,
          };
        }
      });
    }
    this.lowestNumber = 0;

    Object.keys(chartData).forEach((el) => {
      chartData[el].revenueLoss = this.barChartData[el]?.revenue_loss;

      if (this.lowestNumber > chartData[el].revenueLoss) {
        this.lowestNumber = chartData[el].revenueLoss;
      }
    });

    // Add 20% to the lowest number
    this.lowestNumber += this.lowestNumber * 0.2;
    if (this.lowestNumber == 0) {
      this.lowestNumber = 1;
    }

    this.chartDataBugs = [];
    let tmpListOfNames2 = Object.keys(listOfNames2);
    Object.keys(chartData).forEach((el, index) => {
      if (index > 5) return;

      let tmpName = el.includes('Premium Only')
        ? 'Premium Only'
        : el.replace(/_/g, ' ');

      if (chartData[el].revenueLoss != 0) {
        try {
          if (tmpListOfNames2.includes(el)) {
            tmpName = listOfNames2[el]?.showName;
          }
        } catch (err) {
          console.log('err: ', err);
        }

        this.chartDataBugs.push({
          type: tmpName,
          bugName: chartData[el].bugName,
          pageName: chartData[el].pageName,
          revenueLoss: Math.round(chartData[el].revenueLoss),
          revenueLossNotFormatted: chartData[el].revenueLoss,
          percentage: (chartData[el].revenueLoss / this.lowestNumber) * 100,
          neutral: chartData[el].revenueLoss >= -0.01 ? true : false,
          inlineStyle: this.getPagetypeTagsColor(
            tmpName.split(' on ')[1],
            'color'
          ),
          cssClass: this.getPagetypeTagsColor(
            tmpName.split(' on ')[1],
            'cssClass'
          ),
          bug_category: chartData[el].bug_category,
        });
      }
    });

    this.chartDataBugs.sort((a: any, b: any) => {
      if (a.revenueLoss > b.revenueLoss) {
        return 1;
      } else if (a.revenueLoss < b.revenueLoss) {
        return -1;
      }
      return 0;
    });
  }
  getPagetypeTagsColor(page: string, returnType: string) {
    if (returnType == 'color') {
      return getPagetypeTagsColors(page)?.color;
    } else if (returnType == 'cssClass') {
      return getPagetypeTagsColors(page)?.cssClass;
    } else if (returnType == 'chartColor') {
      return getPagetypeTagsColors(page)?.chartColor;
    }

    return getPagetypeTagsColors(page)?.color;
  }
  revenueLossApiCall() {
    if (!this.diagnosticsPrivilege) {
      this.diagnosticsApiCalls();
      this.chartDataBugs = this.chartDataBugs_blurred;
      this.chartDataBugs_blurred_show = true;
      return;
    }
    this.chartDataBugs_blurred_show = false;

    let tmpTarget: any = this.selectedTarget;

    let obj = {
      startDate: this.startDate,
      endDate: this.endDate,
      rate: this.rate,
      selectedRangeValue: this.selectedRangeValueLine,
      target: tmpTarget,
      page: this.activeSelectedPage?.replace(/ /g, '_'),
    };

    this.dashboardSub.add(
      this.bugTrackingService.getTimeRangeBugData(obj).subscribe({
        next: (data) => {
          this.diagnosticsApiCalls();
          this.barChartData = data?.data?.bugs;
          // this.constructBugsByPage(data?.data?.pagesRevenueLoss);
          // this.constructBugsByCategory(data?.data?.categoryRevenueLoss);
          this.updateBugBarChart();
        },
        error: (error) => {
          this.diagnosticsApiCalls();
          this.barChartData = {};
          this.chartDataBugs = [];
        },
      })
    );
  }
  getMerchandiseData() {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    if (!this.merchandisePrivilege) {
      this.merchandiseApiCalls();
      this.merchandiseProducts = this.merchandiseProducts_blurred;
      this.merchandiseProducts_blurred_show = true;
      this.unitSold = formatNumber(35);
      return;
    }
    this.merchandiseProducts_blurred_show = false;

    const [date, timezone] = this.startDate.split('@');

    const startDate2 = new Date(date);
    startDate2.setDate(startDate2.getDate() - 1);
    const startDate2ISO = startDate2.toISOString() + '@' + timezone;

    const endDate2 = new Date(date);
    endDate2.setDate(endDate2.getDate() - 1);
    endDate2.setHours(23, 59, 59);
    const endDate2ISO = endDate2.toISOString() + '@' + timezone;

    const paramObj = {
      target: this.selectedTarget,
      startDate: this.startDate,
      endDate: this.endDate,
      startDate2: startDate2ISO,
      endDate2: endDate2ISO,
      metric: 'revenue',
      hMetric: '',
      page: 1,
      pageSize: 5,
      sort: 'total_revenue',
      order: 'DESC',
      category: '',
      dfilters_w_fields: '',
      dfilters_w_operators: '',
      dfilters_w_values: '',
      withImpact: '',
    };

    this.merchandiseDataLoading = true;
    this.dashboardSub.add(
      this.dashboardService.getMerchandiseData(paramObj).subscribe({
        next: (res) => {
          this.merchandiseApiCalls();
          this.unitSold = formatNumber(res?.unitsSold || 0);

          this.merchandiseProducts = [];
          if (res?.products) {
            res?.products.forEach((product: any, index: number) => {
              if (index + 1 > 3) return;

              this.merchandiseProducts.push({
                ...product,
                truncated_product_id: this.truncateProductID(product.productID),
                sanitized_url: this.sanitizeUrl(product.imgUrl),
              });
            });
          }
        },
        error: (err) => {
          this.merchandiseApiCalls();
          this.merchandiseProducts = [];
          this.unitSold = 0;
          console.log(err);
        },
      })
    );
  }
  truncateProductID(productID: string): string {
    return productID.length > 8 ? productID.slice(0, 8) + '...' : productID;
  }
  sanitizeUrl(url: string): string {
    if (!url) {
      return '../../assets/img/merchandising_icon.svg';
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'https://' + url;
    }
    return url;
  }
  getAutomationData() {
    if (!this.automationsPrivilege) {
      this.automationsPrivilege_blurred_show = true;
      this.automationsRevenue = 6855;
      this.automationsApiCalls();
      return;
    } else {
      this.automationsPrivilege_blurred_show = false;
    }

    if (this.selectedTarget == 'behamicsSample') {
      this.automationsApiCalls();
      this.automationsRevenue = 964.38;
      return;
    }

    const paramObj = {
      target: this.selectedTarget,
      startDate: this.startDate,
      endDate: this.endDate,
    };

    this.automationsDataLoading = true;
    this.dashboardSub.add(
      this.dashboardService.getAutomationData(paramObj).subscribe({
        next: (res) => {
          this.automationsApiCalls();
          this.automationsRevenue = res?.total;
        },
        error: (err) => {
          this.automationsApiCalls();
          this.automationsRevenue = 0;
          console.log(err);
        },
      })
    );
  }
  checkRoleGuard(page: any) {
    if (localStorage.getItem('privileges')) {
      let role = localStorage.getItem('privileges')?.split(',') || [];

      if (role.findIndex((priv) => priv == page) < 0) {
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
  getOrganicData() {
    if (!this.organicPrivilege) {
      this.organicApiCalls();
      this.organicData = this.organicData_blurred;
      this.organicData_blurred_show = true;
      this.organicAllKpis = '0.02';
      return;
    }
    this.organicData_blurred_show = false;

    this.organicDataLoading = true;
    this.dashboardSub.add(
      this.dashboardService.getOrganicData().subscribe({
        next: (res: any) => {
          this.organicApiCalls();

          this.organicAllKpis = res?.variables?.overall_mean?.toFixed(2) || '0';
          this.organicData = [];

          if (res.message == 'no data') {
            return;
          }

          this.formattedKeywordData = Object.keys(res?.variables)
            .filter(
              (key) =>
                key != 'labels' &&
                key != 'domain' &&
                key != 'date' &&
                key != 'category_summary'
            )
            .map((key) => ({
              variable: key,
              variables: Object.entries(res.variables[key]).map(
                ([name, value]) => ({
                  name,
                  value,
                  selected: false,
                })
              ),
            }));

          const dataArray = this.formattedKeywordData.map((data: any) => {
            if (data.variable == 'Technical') {
              const returnData = data.variables.filter(
                (variable: any) =>
                  variable.name == 'performance_score' ||
                  variable.name == 'speedIndex' ||
                  variable.name == 'totalByteWeight' ||
                  variable.name == 'accessibility_score' ||
                  variable.name == 'best_practices_score' ||
                  variable.name == 'Time to Interactive' ||
                  variable.name == 'Largest Contentful Paint'
              );
              return returnData;
            }
            return data.variables;
          });

          dataArray.forEach((data: any) => {
            this.organicData.push(...data);
          });

          this.organicData = this.organicData.map((data: any) => {
            return {
              ...data,
              tmp_renamed_name:
                res?.renamed_variables?.[this.removeCommas(data?.name)]?.name ??
                data.name,
              variable_labels: this.formatNumberWithCommas(
                res?.variable_labels?.[data.name]
              ),
              formatted_value: this.formatValue(data.value),
            };
          });

          this.organicData = this.organicData.sort((a: any, b: any) => {
            if (a['blurElement'] || b['blurElement']) {
              return 0;
            }
            return a.value - b.value;
          });

          // take only the first 7 elements
          this.organicData = this.organicData.slice(0, 7);
        },
        error: (err: any) => {
          this.organicAllKpis = 0;
          this.organicApiCalls();
          this.organicData = [];
          console.log(err);
        },
      })
    );
  }
  sortFunction() {
    this.rankingImpactDirection =
      this.rankingImpactDirection == '1' ? '-1' : '1';

    if (this.rankingImpactDirection == '1') {
      this.organicData = this.organicData.sort((a: any, b: any) => {
        if (a['blurElement'] || b['blurElement']) {
          return 0;
        }
        return b.value - a.value;
      });
    } else {
      this.organicData = this.organicData.sort((a: any, b: any) => {
        if (a['blurElement'] || b['blurElement']) {
          return 0;
        }
        return a.value - b.value;
      });
    }
  }
  removeCommas(value: string) {
    if (!value || !value?.includes('`')) return value;
    return value?.replace(/`/g, '');
  }
  formatNumberWithCommas(number: any) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  formatValue(value: number) {
    if (!value) return;

    if (value === 0) {
      return value.toFixed(0);
    } else if (Math.abs(value) >= 10) {
      return value.toFixed(0);
    } else if (Math.abs(value) < 1) {
      return value.toFixed(2);
    } else if (value % 1 !== 0) {
      return value.toFixed(2);
    } else {
      return value.toFixed(0);
    }
  }
}
